<template>
    <list-search v-bind="{ showsSearch }" label="프로그램 필터" @search="setQuery">
        <v-row align="center">
            <v-col cols="12" sm="6" lg="3">
                <v-text-field label="기간(시작)" v-model="query.dates[0]" type="date" v-bind="inputAttrs" clearable />
            </v-col>
            <v-col cols="12" sm="6" lg="3">
                <v-text-field label="기간(종료)" v-model="query.dates[1]" type="date" v-bind="inputAttrs" clearable />
            </v-col>
            <v-col cols="12" sm="6" lg="3">
                <v-select label="카테고리" v-model="query._category" :items="categories" item-text="name" item-value="_id" placeholder="전체" clearable v-bind="inputAttrs" />
            </v-col>
            <v-col cols="12" sm="6" lg="3">
                <v-select label="키워드" v-model="query._keywords" :items="keywords" item-text="name" item-value="_id" placeholder="전체" clearable v-bind="inputAttrs" />
            </v-col>
        </v-row>
    </list-search>
</template>

<script>
import api from "@/api";
import { inputAttrs } from "@/assets/variables";

import ListSearch from "../dumb/list-search.vue";

let initQuery = () => ({
    dates: [],
    _category: [],
    _keywords: [],
});

export default {
    components: {
        ListSearch,
    },
    props: {
        showsSearch: { type: Boolean, default: false },
    },
    data: () => ({
        query: initQuery(),

        keywords: [],
        categories: [],

        inputAttrs,
    }),
    mounted() {
        this.sync();
        this.init();
    },

    methods: {
        sync() {
            this.query = { ...initQuery(), ...this.$route.query };
        },
        async init() {
            let { categories } = await api.console.programCategories.gets();
            this.categories = categories;

            let { keywords } = await api.console.programKeywords.gets();
            this.keywords = keywords;
        },
        setQuery() {
            let { query: { ...query } = {} } = this;
            try {
                let hasStartDate = !!query?.dates?.[0];
                let hasEndDate = !!query?.dates?.[1];
                if (!hasStartDate && hasEndDate) throw new Error("시작 날짜를 설정해주세요");
                if (hasStartDate && !hasEndDate) throw new Error("종료 날짜를 설정해주세요");
                if (!hasStartDate && !hasEndDate) delete query.dates;

                this.$router.push({ query });
            } catch (error) {
                alert(error.message);
            }
        },
    },
};
</script>

<style></style>
