<template>
    <v-autocomplete v-model="$data._program" :items="programs" :item-text="itemText" item-value="_id" v-bind="$attrs" />
</template>

<script>
import api from "@/api";

export default {
    props: {
        value: { type: String, default: null },
    },
    data: () => ({
        _program: null,
        programs: [],
    }),
    mounted() {
        this.sync();
        this.init();
    },
    watch: {
        value() {
            this.sync();
        },
        "$data._program"(_program) {
            this.$emit("input", _program);
        },
    },
    methods: {
        sync() {
            this.$data._program = this.value;
        },
        async init() {
            this.programs = (await api.console.programs.gets())?.programs || [];
        },
        itemText({ category, name }) {
            return `[${category?.name}] ${name}`;
        },
    },
};
</script>

<style></style>
