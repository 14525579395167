var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('statistics-graph-card', {
    attrs: {
      "label": "참여인원",
      "showsNoItem": !_vm.items.length
    }
  }, [_c('v-chart', _vm._b({}, 'v-chart', {
    initOptions: _vm.initOptions,
    options: _vm.options
  }, false))], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }