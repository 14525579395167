var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', _vm._b({
    attrs: {
      "min-height": "200",
      "outlined": ""
    }
  }, 'v-card', {
    loading: _vm.loading
  }, false), [_c('v-card-title', {
    staticClass: "subtitle-2 font-weight-bold"
  }, [_vm._v(_vm._s(_vm.title))]), _c('v-divider'), _c('v-card-text', {
    staticStyle: {
      "overflow-x": "auto",
      "max-height": "calc(100vh - 48px - 12px - 56px - 12px - 54px - 52px - 12px - 12px)"
    }
  }, [_vm.banner.slides.length < 1 ? _c('v-responsive', {
    attrs: {
      "width": "100%",
      "height": "100%"
    }
  }, [_c('div', [_vm._v(_vm._s(_vm.title) + " 이미지가 없습니다.")])]) : _vm._e(), _c('draggable', {
    model: {
      value: _vm.banner.slides,
      callback: function ($$v) {
        _vm.$set(_vm.banner, "slides", $$v);
      },
      expression: "banner.slides"
    }
  }, _vm._l(_vm.banner.slides, function (slide, index) {
    return _c('v-hover', {
      key: `slide-${index}`,
      scopedSlots: _vm._u([{
        key: "default",
        fn: function (_ref) {
          var hover = _ref.hover;
          return [_c('v-img', {
            attrs: {
              "src": _vm.srcs[index],
              "aspect-ratio": 1920 / 714,
              "max-width": "90vw"
            }
          }, [_c('v-fade-transition', [_c('v-overlay', {
            directives: [{
              name: "show",
              rawName: "v-show",
              value: hover,
              expression: "hover"
            }],
            attrs: {
              "absolute": "",
              "z-index": "0"
            }
          }, [_c('v-menu', {
            attrs: {
              "offset-y": "",
              "nudge-left": "222",
              "close-on-content-click": false
            },
            scopedSlots: _vm._u([{
              key: "activator",
              fn: function (_ref2) {
                var attrs = _ref2.attrs,
                  on = _ref2.on;
                return _c('v-icon', _vm._g(_vm._b({
                  attrs: {
                    "large": ""
                  }
                }, 'v-icon', attrs, false), on), [_vm._v(" mdi-link-variant ")]);
              }
            }], null, true),
            model: {
              value: slide.showsModal_url,
              callback: function ($$v) {
                _vm.$set(slide, "showsModal_url", $$v);
              },
              expression: "slide.showsModal_url"
            }
          }, [_c('v-card', {
            staticClass: "pa-3",
            attrs: {
              "width": "480"
            }
          }, [_c('v-text-field', {
            attrs: {
              "label": "URL",
              "autofocus": "",
              "persistent-placeholder": "",
              "hide-details": "",
              "dense": "",
              "outlined": ""
            },
            on: {
              "keydown": function ($event) {
                if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
                slide.showsModal_url = false;
              }
            },
            model: {
              value: slide.url,
              callback: function ($$v) {
                _vm.$set(slide, "url", $$v);
              },
              expression: "slide.url"
            }
          })], 1)], 1), _c('v-icon', {
            staticClass: "ml-3",
            attrs: {
              "large": ""
            },
            on: {
              "click": function ($event) {
                return _vm.banner.slides.splice(index, 1);
              }
            }
          }, [_vm._v(" mdi-delete ")])], 1)], 1)], 1)];
        }
      }], null, true)
    });
  }), 1)], 1), _c('v-divider'), _c('v-card-actions', {
    staticClass: "pt-0"
  }, [_c('v-row', {
    attrs: {
      "no-gutters": "",
      "justify-sm": "space-between"
    }
  }, [_c('v-col', {
    staticClass: "mt-2",
    attrs: {
      "cols": "",
      "sm": "auto"
    }
  }, [_c('v-file-btn', _vm._b({
    attrs: {
      "outlined": "",
      "color": "primary",
      "accept": "image/*"
    },
    on: {
      "change": _vm.upload
    }
  }, 'v-file-btn', {
    loading: _vm.loading,
    block: _vm.$vuetify.breakpoint.xsOnly
  }, false), [_c('v-icon', {
    staticClass: "mr-2"
  }, [_vm._v("mdi-cloud-upload")]), _c('span', [_vm._v(_vm._s(_vm.title) + " 이미지 업로드")])], 1)], 1), _c('v-col', {
    staticClass: "mt-2",
    attrs: {
      "cols": "",
      "sm": "auto"
    }
  }, [_c('v-btn', _vm._b({
    attrs: {
      "color": "primary"
    },
    on: {
      "click": _vm.save
    }
  }, 'v-btn', {
    loading: _vm.loading,
    block: _vm.$vuetify.breakpoint.xsOnly
  }, false), [_c('v-icon', {
    staticClass: "mr-2"
  }, [_vm._v("mdi-content-save")]), _c('span', [_vm._v("저장하기")])], 1)], 1)], 1)], 1), _c('v-fade-transition', [_c('v-overlay', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.loading,
      expression: "loading"
    }],
    attrs: {
      "absolute": ""
    }
  }, [_c('v-progress-circular', {
    attrs: {
      "indeterminate": "",
      "size": "100",
      "width": "5"
    }
  })], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }