var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-dialog', {
    attrs: {
      "max-width": "480",
      "persistent": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var attrs = _ref.attrs,
          on = _ref.on;
        return [_vm._t("activator", null, null, {
          attrs,
          on
        })];
      }
    }], null, true),
    model: {
      value: _vm.shows,
      callback: function ($$v) {
        _vm.shows = $$v;
      },
      expression: "shows"
    }
  }, [_vm.isLoaded ? _c('v-card', {
    attrs: {
      "height": "100%"
    }
  }, [_c('v-card-title', [_vm.isCreate ? [_vm._v("프로그램 키워드 생성")] : [_vm._v("프로그램 키워드 상세")], _c('v-spacer'), _c('v-btn', {
    attrs: {
      "absolute": "",
      "right": "",
      "text": "",
      "icon": ""
    },
    on: {
      "click": function ($event) {
        _vm.shows = false;
      }
    }
  }, [_c('v-icon', [_vm._v("mdi-close")])], 1)], 2), _c('v-divider'), _c('v-card-text', {
    staticClass: "py-5",
    staticStyle: {
      "max-height": "calc(90vh - 16px - 32px - 10px - 1px - 1px - 60px)",
      "overflow-y": "auto"
    }
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "label": "키워드명"
    },
    model: {
      value: _vm.inputs.name,
      callback: function ($$v) {
        _vm.$set(_vm.inputs, "name", $$v);
      },
      expression: "inputs.name"
    }
  }, 'v-text-field', _vm.inputAttrs, false))], 1), _c('v-divider'), _c('v-card-actions', {
    staticClass: "px-6 grey lighten-5"
  }, [_c('v-switch', _vm._b({
    attrs: {
      "label": "추천사용"
    },
    model: {
      value: _vm.inputs.isOnRecommendation,
      callback: function ($$v) {
        _vm.$set(_vm.inputs, "isOnRecommendation", $$v);
      },
      expression: "inputs.isOnRecommendation"
    }
  }, 'v-switch', _vm.switchAttrs, false)), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "large": "",
      "color": "primary"
    },
    on: {
      "click": _vm.save
    }
  }, [_vm._v(" 저장하기 ")])], 1)], 1) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }