var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('list-search', _vm._b({
    attrs: {
      "label": "프로그램 필터"
    },
    on: {
      "search": _vm.setQuery
    }
  }, 'list-search', {
    showsSearch: _vm.showsSearch
  }, false), [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6",
      "lg": "3"
    }
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "label": "기간(시작)",
      "type": "date",
      "clearable": ""
    },
    model: {
      value: _vm.query.dates[0],
      callback: function ($$v) {
        _vm.$set(_vm.query.dates, 0, $$v);
      },
      expression: "query.dates[0]"
    }
  }, 'v-text-field', _vm.inputAttrs, false))], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6",
      "lg": "3"
    }
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "label": "기간(종료)",
      "type": "date",
      "clearable": ""
    },
    model: {
      value: _vm.query.dates[1],
      callback: function ($$v) {
        _vm.$set(_vm.query.dates, 1, $$v);
      },
      expression: "query.dates[1]"
    }
  }, 'v-text-field', _vm.inputAttrs, false))], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6",
      "lg": "3"
    }
  }, [_c('v-select', _vm._b({
    attrs: {
      "label": "카테고리",
      "items": _vm.categories,
      "item-text": "name",
      "item-value": "_id",
      "placeholder": "전체",
      "clearable": ""
    },
    model: {
      value: _vm.query._category,
      callback: function ($$v) {
        _vm.$set(_vm.query, "_category", $$v);
      },
      expression: "query._category"
    }
  }, 'v-select', _vm.inputAttrs, false))], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6",
      "lg": "3"
    }
  }, [_c('v-select', _vm._b({
    attrs: {
      "label": "키워드",
      "items": _vm.keywords,
      "item-text": "name",
      "item-value": "_id",
      "placeholder": "전체",
      "clearable": ""
    },
    model: {
      value: _vm.query._keywords,
      callback: function ($$v) {
        _vm.$set(_vm.query, "_keywords", $$v);
      },
      expression: "query._keywords"
    }
  }, 'v-select', _vm.inputAttrs, false))], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }