var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-data-table', _vm._b({
    staticClass: "v-sheet--outlined",
    attrs: {
      "disable-filtering": "",
      "disable-pagination": "",
      "disable-sort": "",
      "items-per-page": -1,
      "hide-default-footer": ""
    },
    scopedSlots: _vm._u([_vm._l(_vm.headers.filter(function (header) {
      return header.hasOwnProperty('formatter');
    }), function (header) {
      return {
        key: `item.${header.value}`,
        fn: function (_ref) {
          var value = _ref.value;
          return [_vm._v(" " + _vm._s(header.formatter(value)) + " ")];
        }
      };
    }), {
      key: `item.attendances`,
      fn: function (_ref2) {
        var item = _ref2.item,
          value = _ref2.value;
        return [_vm._v(" " + _vm._s(value.length) + " / " + _vm._s(item.units.length) + " ")];
      }
    }, {
      key: `item.actions`,
      fn: function (_ref3) {
        var item = _ref3.item;
        return [_c('program-attendee-attendances', _vm._b({
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function (_ref4) {
              var attrs = _ref4.attrs,
                on = _ref4.on;
              return [_c('v-btn', _vm._g(_vm._b({
                attrs: {
                  "text": "",
                  "icon": "",
                  "tile": ""
                }
              }, 'v-btn', attrs, false), on), [_c('v-icon', [_vm._v("mdi-eye")])], 1)];
            }
          }], null, true)
        }, 'program-attendee-attendances', Object.assign({}, item, {
          units: item.units
        }), false))];
      }
    }, {
      key: "footer",
      fn: function () {
        return [_c('v-divider'), _c('v-pagination', {
          staticClass: "mx-4",
          attrs: {
            "value": _vm.page,
            "length": _vm.pageCount,
            "total-visible": 11
          },
          on: {
            "input": function (page) {
              return _vm.$router.push({
                query: Object.assign({}, _vm.$route.query, {
                  page
                })
              });
            }
          }
        })];
      },
      proxy: true
    }], null, true)
  }, 'v-data-table', {
    items: _vm.items,
    headers: _vm.headers
  }, false));

}
var staticRenderFns = []

export { render, staticRenderFns }