var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-dialog', {
    attrs: {
      "max-width": "900",
      "persistent": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var attrs = _ref.attrs,
          on = _ref.on;
        return [_vm._t("activator", null, null, {
          attrs,
          on
        })];
      }
    }], null, true),
    model: {
      value: _vm.shows,
      callback: function ($$v) {
        _vm.shows = $$v;
      },
      expression: "shows"
    }
  }, [_c('v-card', _vm._b({
    attrs: {
      "height": "100%"
    }
  }, 'v-card', {
    loading: _vm.loading
  }, false), [_c('v-card-title', [_vm.isCreate ? [_vm._v("대여공간 생성")] : [_vm._v("대여공간 상세")], _c('v-spacer'), _c('v-btn', {
    attrs: {
      "absolute": "",
      "right": "",
      "text": "",
      "icon": ""
    },
    on: {
      "click": function ($event) {
        _vm.shows = false;
      }
    }
  }, [_c('v-icon', [_vm._v("mdi-close")])], 1)], 2), _c('v-divider'), _c('v-card-text', {
    staticClass: "pa-0",
    staticStyle: {
      "max-height": "calc(90vh - 16px - 32px - 10px - 1px - 1px - 60px)",
      "overflow-y": "auto"
    }
  }, [_c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "4"
    }
  }, [_c('v-card-subtitle', {
    staticClass: "grey lighten-5"
  }, [_vm._v("썸네일")]), _c('v-divider'), _c('v-card-text', [_c('v-preview-input', {
    attrs: {
      "aspect-ratio": 1 / 1
    },
    model: {
      value: _vm.inputs.thumb,
      callback: function ($$v) {
        _vm.$set(_vm.inputs, "thumb", $$v);
      },
      expression: "inputs.thumb"
    }
  })], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "auto"
    }
  }, [_c('v-divider', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.isMobile,
      expression: "!isMobile"
    }],
    attrs: {
      "vertical": ""
    }
  }), _c('v-divider', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isMobile,
      expression: "isMobile"
    }]
  })], 1), _c('v-col', [_c('v-card-subtitle', {
    staticClass: "grey lighten-5"
  }, [_vm._v("기본 정보")]), _c('v-divider'), _c('v-card-text', [_c('v-row', {
    staticClass: "ma-n2",
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "label": "공간명 (*센터가 다르더라도 중복된 공간명은 가질 수 없습니다.)"
    },
    model: {
      value: _vm.inputs.name,
      callback: function ($$v) {
        _vm.$set(_vm.inputs, "name", $$v);
      },
      expression: "inputs.name"
    }
  }, 'v-text-field', _vm.inputAttrs, false))], 1), _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-select', _vm._b({
    attrs: {
      "label": "지역",
      "items": _vm.branches
    },
    model: {
      value: _vm.inputs.branch,
      callback: function ($$v) {
        _vm.$set(_vm.inputs, "branch", $$v);
      },
      expression: "inputs.branch"
    }
  }, 'v-select', _vm.inputAttrs, false))], 1), _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "3"
    }
  }, [_c('v-currency-field', _vm._b({
    attrs: {
      "label": "가로",
      "suffix": "m"
    },
    model: {
      value: _vm.inputs.area.width,
      callback: function ($$v) {
        _vm.$set(_vm.inputs.area, "width", $$v);
      },
      expression: "inputs.area.width"
    }
  }, 'v-currency-field', _vm.inputAttrs, false))], 1), _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "3"
    }
  }, [_c('v-currency-field', _vm._b({
    attrs: {
      "label": "세로",
      "suffix": "m"
    },
    model: {
      value: _vm.inputs.area.height,
      callback: function ($$v) {
        _vm.$set(_vm.inputs.area, "height", $$v);
      },
      expression: "inputs.area.height"
    }
  }, 'v-currency-field', _vm.inputAttrs, false))], 1), _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "3"
    }
  }, [_c('v-currency-field', _vm._b({
    attrs: {
      "label": "면적",
      "suffix": "㎡",
      "disabled": ""
    },
    model: {
      value: _vm.inputs.area.square,
      callback: function ($$v) {
        _vm.$set(_vm.inputs.area, "square", $$v);
      },
      expression: "inputs.area.square"
    }
  }, 'v-currency-field', _vm.inputAttrs, false))], 1), _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "3"
    }
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "label": "수용규모"
    },
    model: {
      value: _vm.inputs.capacity,
      callback: function ($$v) {
        _vm.$set(_vm.inputs, "capacity", $$v);
      },
      expression: "inputs.capacity"
    }
  }, 'v-text-field', _vm.inputAttrs, false))], 1), _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "label": "대여용도"
    },
    model: {
      value: _vm.inputs.usage,
      callback: function ($$v) {
        _vm.$set(_vm.inputs, "usage", $$v);
      },
      expression: "inputs.usage"
    }
  }, 'v-text-field', _vm.inputAttrs, false))], 1), _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-textarea', _vm._b({
    attrs: {
      "label": "설비",
      "rows": "3"
    },
    model: {
      value: _vm.inputs.facility,
      callback: function ($$v) {
        _vm.$set(_vm.inputs, "facility", $$v);
      },
      expression: "inputs.facility"
    }
  }, 'v-textarea', _vm.inputAttrs, false))], 1)], 1)], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-divider'), _c('form-table-row', {
    attrs: {
      "label": "공간사진",
      "cols": "2"
    }
  }, [_c('images-input', {
    staticClass: "my-3",
    attrs: {
      "cols": "1"
    },
    model: {
      value: _vm.inputs.photos,
      callback: function ($$v) {
        _vm.$set(_vm.inputs, "photos", $$v);
      },
      expression: "inputs.photos"
    }
  })], 1), _c('form-table-row', {
    attrs: {
      "label": "대여가능요일",
      "cols": "2"
    }
  }, [_c('v-row', {
    attrs: {
      "no-gutters": "",
      "align": "stretch"
    }
  }, _vm._l(_vm.businessDays, function (_ref2) {
    var label = _ref2.label,
      value = _ref2.value;
    return _c('v-col', {
      key: value
    }, [_c('v-checkbox', _vm._b({
      staticClass: "mt-0",
      attrs: {
        "dense": "",
        "hide-details": "",
        "multiple": ""
      },
      model: {
        value: _vm.inputs.businessDays,
        callback: function ($$v) {
          _vm.$set(_vm.inputs, "businessDays", $$v);
        },
        expression: "inputs.businessDays"
      }
    }, 'v-checkbox', {
      label,
      value
    }, false))], 1);
  }), 1)], 1), _c('form-table-row', {
    attrs: {
      "label": "대여가능시간",
      "cols": "2"
    }
  }, [_c('v-row', {
    attrs: {
      "no-gutters": "",
      "align": "stretch"
    }
  }, _vm._l(_vm.businessHours, function (_ref3) {
    var label = _ref3.label,
      value = _ref3.value;
    return _c('v-col', {
      key: value
    }, [_c('v-checkbox', _vm._b({
      staticClass: "mt-0",
      attrs: {
        "dense": "",
        "hide-details": "",
        "multiple": ""
      },
      model: {
        value: _vm.inputs.businessHours,
        callback: function ($$v) {
          _vm.$set(_vm.inputs, "businessHours", $$v);
        },
        expression: "inputs.businessHours"
      }
    }, 'v-checkbox', {
      label,
      value
    }, false))], 1);
  }), 1)], 1), _c('form-table-row', {
    attrs: {
      "label": "대여불가일자",
      "cols": "2"
    }
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-date-picker', {
    attrs: {
      "multiple": ""
    },
    model: {
      value: _vm.inputs.holidays,
      callback: function ($$v) {
        _vm.$set(_vm.inputs, "holidays", $$v);
      },
      expression: "inputs.holidays"
    }
  })], 1), _c('v-col', [_c('v-chip-group', {
    attrs: {
      "column": ""
    },
    model: {
      value: _vm.inputs.holidays,
      callback: function ($$v) {
        _vm.$set(_vm.inputs, "holidays", $$v);
      },
      expression: "inputs.holidays"
    }
  }, _vm._l(_vm.inputs.holidays, function (holiday) {
    return _c('v-chip', {
      key: holiday,
      attrs: {
        "close": ""
      },
      on: {
        "click:close": function ($event) {
          _vm.inputs.holidays = _vm.inputs.holidays.filter(function (item) {
            return item != holiday;
          });
        }
      }
    }, [_vm._v(_vm._s(holiday.toDate()))]);
  }), 1)], 1)], 1)], 1)], 1)], 1)], 1), _c('v-divider'), _c('v-card-actions', {
    staticClass: "px-6 grey lighten-5"
  }, [_c('v-switch', {
    staticClass: "ma-0 pa-0",
    attrs: {
      "inset": "",
      "label": "사용자 노출",
      "hide-details": ""
    },
    model: {
      value: _vm.inputs.shows,
      callback: function ($$v) {
        _vm.$set(_vm.inputs, "shows", $$v);
      },
      expression: "inputs.shows"
    }
  }), _c('v-spacer'), _c('v-btn', _vm._b({
    attrs: {
      "large": "",
      "color": "primary"
    },
    on: {
      "click": _vm.save
    }
  }, 'v-btn', {
    loading: _vm.loading
  }, false), [_vm._v(" 저장하기 ")])], 1), _c('v-fade-transition', [_c('v-overlay', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.loading,
      expression: "loading"
    }],
    attrs: {
      "absolute": "",
      "z-index": "0",
      "dark": false,
      "color": "white"
    }
  }, [_c('v-progress-circular', {
    attrs: {
      "indeterminate": "",
      "width": "5",
      "size": "100",
      "color": "black"
    }
  })], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }