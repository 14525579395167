var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-row', {
    attrs: {
      "justify": "center",
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "headline text-start",
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v(" " + _vm._s(_vm.title) + " "), _vm._t("add-button")], 2), _c('v-spacer'), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._t("buttons"), _vm.showsFilterButton ? _c('v-btn', {
    staticClass: "ml-3",
    attrs: {
      "color": "secondary",
      "rounded": ""
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('input', !_vm.value);
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "small": ""
    }
  }, [_vm._v("mdi-filter-variant")]), _vm._v("검색필터")], 1) : _vm._e()], 2)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }