<template>
    <v-autocomplete v-model="$data._space" :items="spaces" :item-text="itemText" item-value="_id" v-bind="$attrs" />
</template>

<script>
import api from "@/api";

export default {
    props: {
        value: { type: String, default: null },
    },
    data: () => ({
        _space: null,
        spaces: [],
    }),
    mounted() {
        this.sync();
        this.init();
    },
    watch: {
        value() {
            this.sync();
        },
        "$data._space"(_space) {
            this.$emit("input", _space);
        },
    },
    methods: {
        sync() {
            this.$data._space = this.value;
        },
        async init() {
            this.spaces = (await api.console.rentalSpaces.gets())?.spaces || [];
        },
        itemText({ branch, name }) {
            return `[${branch}] ${name}`;
        },
    },
};
</script>

<style></style>
