var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-snackbar', _vm._b({
    scopedSlots: _vm._u([{
      key: "action",
      fn: function (_ref) {
        var attrs = _ref.attrs;
        return [_c('v-btn', _vm._b({
          attrs: {
            "color": "pink",
            "text": ""
          },
          on: {
            "click": function ($event) {
              _vm.shows = false;
            }
          }
        }, 'v-btn', attrs, false), [_vm._v("닫기")])];
      }
    }]),
    model: {
      value: _vm.shows,
      callback: function ($$v) {
        _vm.shows = $$v;
      },
      expression: "shows"
    }
  }, 'v-snackbar', _vm.$attrs, false), [_c('span', {
    staticStyle: {
      "white-space": "pre-line"
    }
  }, [_vm._v(" " + _vm._s(_vm.message) + " ")])]);

}
var staticRenderFns = []

export { render, staticRenderFns }