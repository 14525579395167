var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-expand-transition', [_c('v-card', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.showsSearch,
      expression: "showsSearch"
    }],
    attrs: {
      "outlined": ""
    }
  }, [_c('v-card-title', {
    staticClass: "subtitle-2 font-weight-bold"
  }, [_vm._v("프로그램 선택")]), _c('v-card-text', [_vm.programs.length ? _c('v-chip-group', {
    attrs: {
      "multiple": "",
      "column": "",
      "color": "primary"
    },
    on: {
      "change": _vm.emit
    },
    model: {
      value: _vm.selected,
      callback: function ($$v) {
        _vm.selected = $$v;
      },
      expression: "selected"
    }
  }, _vm._l(_vm.programs, function (program) {
    var _program$category;
    return _c('v-chip', {
      key: program._id,
      attrs: {
        "value": program._id,
        "outlined": "",
        "label": ""
      }
    }, [_vm._v(" [" + _vm._s(program === null || program === void 0 ? void 0 : (_program$category = program.category) === null || _program$category === void 0 ? void 0 : _program$category.name) + "] " + _vm._s(program === null || program === void 0 ? void 0 : program.name) + " ")]);
  }), 1) : [_vm._v(" 프로그램이 없습니다 ")]], 2)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }