var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-sheet', [!_vm.space ? _c('span', {
    staticClass: "caption"
  }, [_vm._v(" 회원 / 공간을 설정해주세요. ")]) : _vm.item.isOnHoliday ? _c('span', [_vm._v(" 운영하지 않는 날짜입니다 ")]) : !_vm.item.isOnBusiness ? _c('span', [_vm._v(" 운영하지 않는 요일입니다 ")]) : _c('v-row', {
    staticClass: "py-2",
    attrs: {
      "no-gutters": ""
    }
  }, _vm._l(_vm.businessHours, function (time) {
    return _c('v-col', {
      key: time,
      attrs: {
        "lg": "4"
      }
    }, [_c('rental-schedule-checkbox', _vm._g(_vm._b({}, 'rental-schedule-checkbox', {
      loading: _vm.loading,
      schedules: _vm.schedules,
      item: _vm.item,
      date: _vm.date,
      time,
      _user: _vm._user,
      schedulesOfUser: _vm.schedulesOfUser,
      limitationMet: _vm.limitationMet
    }, false), {
      add: _vm.add,
      del: function (schedule) {
        return _vm.$emit('del', schedule);
      }
    }))], 1);
  }), 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }