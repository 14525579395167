<template>
    <v-layout justify-center>
        <v-responsive max-width="1600" width="100%" height="100%" class="mx-n3 px-3">
            <list-heading title="대여예약 목록">
                <template #add-button>
                    <rental-schedule-view>
                        <template #activator="{ attrs, on }">
                            <v-btn text icon tile color="primary" title="대여예약 생성" v-bind="attrs" v-on="on"> <v-icon>mdi-plus</v-icon> </v-btn>
                        </template>
                    </rental-schedule-view>
                </template>
            </list-heading>

            <schedule-list-filter />

            <v-row>
                <v-spacer></v-spacer>
                <v-col cols="auto">
                    <v-card outlined>
                        <v-btn color="white" class="green--text px-2" @click="excel"> <v-icon>mdi-file-excel</v-icon> 엑셀다운로드 </v-btn>
                    </v-card>
                </v-col>
            </v-row>

            <v-card outlined style="overflow: hidden">
                <v-data-table v-bind="{ headers, items }" disable-sort disable-pagination hide-default-footer :items-per-page="-1">
                    <template v-for="header in headers.filter((header) => header.hasOwnProperty('formatter'))" v-slot:[`item.${header.value}`]="{ value }"> {{ header.formatter(value) }} </template>
                    <template #[`item.state`]="{ item, value }">
                        <v-edit-dialog :return-value.sync="item.state" large cancel-text="취소" save-text="변경" @open="state = value" @save="putStateOf(item)">
                            {{ RENTAL_SCHEDULE_STATES[value]?.text }}
                            <template #input> <v-select v-model="state" :items="Object.values(RENTAL_SCHEDULE_STATES)" v-bind="inputAttrs" class="mt-3" /> </template>
                        </v-edit-dialog>
                    </template>
                </v-data-table>
            </v-card>
            <v-pagination :value="page" :length="pageCount" :total-visible="11" class="mt-4 mx-4" @input="(page) => $router.push({ query: { ...$route.query, page } })"></v-pagination>
        </v-responsive>
    </v-layout>
</template>

<script>
import api from "@/api";
import XLSX from "xlsx";

import ListHeading from "@/components/console/dumb/list-heading.vue";
import RentalScheduleView from "@/components/console/rental/rental-schedule-view.vue";
import ScheduleListFilter from "@/components/console/rental/schedule-list-filter.vue";
import { inputAttrs, RENTAL_SCHEDULE_STATES } from "@/assets/variables";
import dayjs from "dayjs";

const defaultFormatter = (value) => value || "-";
const headers = [
    { width: +80, align: "right", text: "번호", value: "index" },
    { width: 170, align: "center", text: "신청일", value: "createdAt", formatter: (value) => value?.toDateTime?.() },
    { width: +80, align: "center", text: "지점", value: "space.branch" },
    { width: 140, align: "center", text: "공간명", value: "space.name" },
    { width: 110, align: "center", text: "예약일", value: "date", formatter: (value) => value?.toDate?.() },
    { width: +90, align: "center", text: "예약시간", value: "time", formatter: (value) => `${value}시` },
    { width: 160, align: "left", text: "목적", value: "usage" },
    { width: 100, align: "left", text: "회원번호", value: "user.number" },
    { width: 120, align: "center", text: "아이디", value: "user.username" },
    { width: 120, align: "center", text: "이름", value: "user.name" },
    { width: 140, align: "left", text: "전화번호", value: "user.phone" },
    { text: "상태", value: "state" },
].map((item) => ({ ...item, formatter: item?.formatter || defaultFormatter }));

export default {
    components: {
        ListHeading,
        RentalScheduleView,
        ScheduleListFilter,
    },
    data() {
        return {
            schedules: [],
            state: RENTAL_SCHEDULE_STATES.APPLIED.value,

            limit: 10,
            summary: { totalCount: 0 },

            headers,
            inputAttrs,
            RENTAL_SCHEDULE_STATES,
        };
    },
    computed: {
        items() {
            return this.schedules.map((item, index) => ({ ...item, index: this.summary.totalCount - (this.page - 1) * this.limit - index }));
        },
        page() {
            return +(this.$route.query.page || "1");
        },
        skip() {
            return (this.page - 1) * this.limit;
        },
        pageCount() {
            return Math.ceil(this.summary.totalCount / this.limit) || 1;
        },
        params() {
            let { query } = this.$route;
            return { ...query };
        },
    },
    mounted() {
        this.init();
    },
    watch: {
        params() {
            this.search();
        },
    },
    methods: {
        async init() {
            try {
                this.search();
            } catch (error) {
                console.error(error);
            }
        },

        async search() {
            try {
                let { skip, limit, params } = this;
                var { summary, schedules } = await api.console.rentalSchedules.gets({
                    headers: { skip, limit },
                    params,
                });

                this.schedules = schedules;
                this.summary = summary;
            } catch (error) {
                console.error(error);
                if (error.response) alert(error.response.data.email);
                else alert(error.email.toString());
            }
        },

        async putStateOf({ _id }) {
            let go = confirm("해당 예약의 상태를 변경하시겠습니까?");
            if (go) {
                let { state } = this;
                let { schedule } = await api.console.rentalSchedules.put({ _id, state });
                this.update(schedule);
            }
        },

        async update(item) {
            const index = this.schedules.findIndex(({ _id }) => _id == item._id);
            this.schedules.splice(index, 1, item);
            if (index == -1) this.search();
            else this.schedules.splice(index, 1, item);
        },

        async save(item) {
            const { schedule } = await api.console.rentalSchedules.put(item);
            this.update(schedule);
        },

        async excel() {
            try {
                let { params } = this;
                var { schedules } = await api.console.rentalSchedules.gets({
                    params,
                });

                var rows = schedules.map((item) => ({
                    신청일: item?.createdAt || "",
                    지점: item?.space?.branch || "",
                    공간명: item?.space?.name || "",
                    예약일: item?.date || "",
                    예약시간: item?.time || "",
                    목적: item?.usage || "",
                    회원번호: item?.user?.number || "",
                    아이디: item?.user?.username || "",
                    이름: item?.user?.name || "",
                    전화번호: item?.user?.phone || "",
                    상태: RENTAL_SCHEDULE_STATES[item?.state]?.text || "",
                }));

                var workbook = new XLSX.utils.book_new();
                var worksheet = XLSX.utils.json_to_sheet(rows);

                XLSX.utils.book_append_sheet(workbook, worksheet, "new");
                XLSX.writeFile(workbook, `대여예약목록-${dayjs().format("YYYYMMDDHHmmss")}.xlsx`);
            } catch (error) {
                alert(error.message);
            }
        },
    },
};
</script>
