<template>
    <qrcode-stream v-bind="{ camera, track }" v-on="{ init, decode }">
        <v-fade-transition>
            <v-overlay v-show="errorMessage" absolute z-index="0"> {{ errorMessage }} </v-overlay>
        </v-fade-transition>
        <v-layout column style="position:absolute; top: 12px; right:12px">
            <slot name="button" />
            <v-btn fab elevation="4" @click="nextCamera"> <v-icon large>mdi-camera-flip</v-icon> </v-btn>
        </v-layout>
        <slot />
    </qrcode-stream>
</template>

<script>
import { QrcodeStream } from "vue-qrcode-reader";
let CAMERAS = {
    auto: "auto",
    front: "front",
    rear: "rear",
};
let cameras = Object.values(CAMERAS);
let messages = {
    [CAMERAS.auto]: "카메라를 찾을 수 없습니다",
    [CAMERAS.rear]: "후면 카메라가 없습니다",
    [CAMERAS.front]: "전면 카메라가 없습니다",
};

export default {
    components: {
        QrcodeStream,
    },
    props: {
        value: { type: String, default: null },
        propCamera: { type: String, default: "auto" },
    },
    data: () => ({
        camera: "auto",
        errorMessage: null,
    }),
    mounted() {
        this.camera = this.propCamera;
    },
    watch: {
        camera(camera) {
            this.errorMessage = null;
            this.$emit("changeCamera", camera);
        },
        propCamera(propCamera) {
            this.camera = propCamera;
        },
    },
    methods: {
        init(promise) {
            promise.catch((error) => {
                const cameraMissingError = error.name === "OverconstrainedError";
                if (cameraMissingError) {
                    this.errorMessage = messages[this.camera];
                }
            });
        },
        decode(decoded) {
            if (this.value != decoded) this.$emit("input", decoded);

            let { camera } = this;
            this.camera = "off";
            setTimeout(() => {
                this.camera = camera;
            }, 500);
        },
        track(detectedCodes, ctx) {
            for (const detectedCode of detectedCodes) {
                const [firstPoint, ...otherPoints] = detectedCode.cornerPoints;

                ctx.strokeStyle = "green";

                ctx.beginPath();
                ctx.moveTo(firstPoint.x, firstPoint.y);
                for (const { x, y } of otherPoints) {
                    ctx.lineTo(x, y);
                }
                ctx.lineTo(firstPoint.x, firstPoint.y);
                ctx.closePath();
                ctx.stroke();
            }
        },
        nextCamera() {
            let index = cameras.findIndex((item) => item == this.camera);
            if (-1 < index) {
                if (index < cameras.length - 1) this.camera = cameras[index + 1];
                else this.camera = cameras[0];
            } else this.camera = CAMERAS.auto;
        },
    },
};
</script>

<style></style>
