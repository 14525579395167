<template>
    <v-dialog v-model="shows" max-width="480" persistent>
        <template v-slot:activator="{ attrs, on }">
            <slot name="activator" v-bind="{ attrs, on }" />
        </template>
        <v-card v-if="isLoaded" height="100%">
            <v-card-title>
                <template v-if="isCreate">프로그램 키워드 생성</template>
                <template v-else>프로그램 키워드 상세</template>
                <v-spacer />
                <v-btn absolute right text icon @click="shows = false"><v-icon>mdi-close</v-icon></v-btn>
            </v-card-title>
            <v-divider />
            <v-card-text class="py-5" style="max-height: calc(90vh - 16px - 32px - 10px - 1px - 1px - 60px); overflow-y: auto">
                <v-text-field label="키워드명" v-model="inputs.name" v-bind="inputAttrs" />
            </v-card-text>
            <v-divider />
            <v-card-actions class="px-6 grey lighten-5">
                <v-switch v-model="inputs.isOnRecommendation" label="추천사용" v-bind="switchAttrs" />
                <v-spacer />
                <v-btn large color="primary" @click="save"> 저장하기 </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import api from "@/api";
import detect from "@/plugins/detect";
import { inputAttrs, switchAttrs } from "@/assets/variables";

const initProgramKeyword = () => ({
    name: null,
    isOnRecommendation: true,
});

export default {
    props: {
        value: { type: Object },
    },
    data: () => ({
        shows: false,
        isLoaded: false,

        inputs: {},

        inputAttrs,
        switchAttrs,
        isMobile: detect == "mobile",
    }),
    computed: {
        _keyword() {
            return this.value?._id;
        },
        isCreate() {
            return !this._keyword;
        },
    },
    watch: {
        shows() {
            if (this.shows) this.init();
        },
        "inputs.area.width"() {
            const { width = 0, height = 0 } = this.inputs.area;
            this.inputs.area.square = width * height || 0;
        },
        "inputs.area.height"() {
            const { width = 0, height = 0 } = this.inputs.area;
            this.inputs.area.square = width * height || 0;
        },
    },
    methods: {
        async init() {
            this.isLoaded = false;
            try {
                if (this.isCreate) {
                    this.inputs = { ...initProgramKeyword() };
                } else {
                    const { keyword } = await api.console.programKeywords.get({ _id: this._keyword });
                    this.inputs = keyword;
                }
            } catch (error) {
                this.$handleError(error);
            } finally {
                this.isLoaded = true;
            }
        },

        validate() {},

        async save() {
            try {
                this.validate();

                // Document POST / PUT
                let keyword;
                if (this.isCreate) keyword = (await api.console.programKeywords.post(this.inputs))?.keyword;
                else keyword = (await api.console.programKeywords.put(this.inputs))?.keyword;

                // Files POST / PUT

                alert("저장되었습니다");
                if (this.isCreate) this.$emit("created");
                else this.$emit("input", keyword);
                this.shows = false;
            } catch (error) {
                this.$handleError(error);
            }
        },
    },
};
</script>
