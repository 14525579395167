<template>
    <v-layout justify-center>
        <v-responsive max-width="480" width="100%" height="100%" class="mx-n3 px-3">
            <list-heading title="프로그램 카테고리 목록">
                <template #add-button>
                    <program-category-view @created="search()">
                        <template #activator="{ attrs, on }">
                            <v-btn text icon tile color="primary" title="프로그램 카테고리 생성" v-bind="attrs" v-on="on"> <v-icon>mdi-plus</v-icon> </v-btn>
                        </template>
                    </program-category-view>
                </template>
            </list-heading>

            <v-card outlined style="overflow:hidden">
                <v-data-table v-bind="{ headers, items }" disable-sort disable-pagination hide-default-footer :items-per-page="-1">
                    <template v-for="header in headers.filter((header) => header.hasOwnProperty('formatter'))" v-slot:[`item.${header.value}`]="{ value }"> {{ header.formatter(value) }} </template>
                    <template #[`item.shows`]="{ item, value }">
                        <v-row justify="center" align="center">
                            <v-col cols="auto" class="pa-0">
                                <v-switch v-model="item.shows" readonly inset @click="save({ _id: item._id, shows: !value })" class="ma-0 ml-1 mr-n3 pa-0" hide-details />
                            </v-col>
                        </v-row>
                    </template>
                    <template v-slot:[`item.actions`]="{ item }">
                        <program-category-view :value="item" @input="(item) => update(item)">
                            <template #activator="{ attrs, on }">
                                <v-icon small v-bind="attrs" v-on="on"> mdi-pencil </v-icon>
                            </template>
                        </program-category-view>
                        <v-icon small @click="remove(item)"> mdi-delete </v-icon>
                    </template>
                </v-data-table>
            </v-card>
            <v-pagination v-model="page" :length="pageCount" :total-visible="11" class="mt-4 mx-4" @input="search()"></v-pagination>
        </v-responsive>
    </v-layout>
</template>

<script>
import api from "@/api";
import ListSearch from "@/components/console/dumb/list-search.vue";
import ListHeading from "@/components/console/dumb/list-heading.vue";
import ProgramCategoryView from "@/components/console/program/program-category-view.vue";
const defaultFormatter = (value) => value || "-";
const headers = [
    { text: "번호", value: "index" },
    { text: "카테고리명", value: "name" },
    // { text: "추천사용", value: "isOnRecommendation", width: 100, align: "center" },
    { text: "", value: "actions", align: "right", width: 64 },
].map((item) => ({ ...item, formatter: item?.formatter || defaultFormatter }));
export default {
    components: {
        ListSearch,
        ListHeading,
        ProgramCategoryView,
    },
    data() {
        return {
            filter: {
                shows: JSON.parse(this.$route.query.shows || "null"),
            },

            page: +this.$route.query.page || 1,
            limit: 10,

            headers,
            categories: [],
            summary: { totalCount: 0 },
        };
    },
    computed: {
        items() {
            return this.categories.map((item, index) => ({ ...item, index: this.summary.totalCount - (this.page - 1) * this.limit - index }));
        },
        pageCount() {
            return Math.ceil(this.summary.totalCount / this.limit) || 1;
        },
    },
    mounted() {
        this.init();
    },
    watch: {
        "$route.query.shows"(shows = "null") {
            this.filter.shows = JSON.parse(shows);
            this.search();
        },
    },
    methods: {
        async init() {
            try {
                this.search();
            } catch (error) {
                console.error(error);
            }
        },

        async search({ page = this.page } = {}) {
            try {
                var { summary, categories } = await api.console.programCategories.gets({
                    headers: {
                        skip: (page - 1) * this.limit,
                        limit: this.limit,
                    },
                    params: this.filter,
                });

                this.categories = categories;
                this.summary = summary;
            } catch (error) {
                console.error(error);
                if (error.response) alert(error.response.data.email);
                else alert(error.email.toString());
            }
        },

        async update(item) {
            const index = this.categories.findIndex(({ _id }) => _id == item._id);
            this.categories.splice(index, 1, item);
        },

        async save(item) {
            const { space } = await api.console.programCategories.put(item);
            this.update(space);
        },
        async remove(item) {
            const go = confirm(`다음 카테고리를 삭제하시겠습니까?\n\n카테고리명: ${item?.name}`);
            if (!go) return;
            await api.console.programCategories.delete(item);
            this.search();
        },
    },
};
</script>
