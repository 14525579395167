<template>
    <v-card outlined>
        <v-data-table v-bind="{ items, headers }" disable-filtering disable-pagination disable-sort hide-default-footer :items-per-page="-1" sort-by="startsAt">
            <template v-for="header in headers.filter((header) => header.hasOwnProperty('formatter'))" #[`item.${header.value}`]="{ value }"> {{ header.formatter(value) }} </template>
            <template #[`item.program.thumb`]="{ value }">
                <v-img v-if="value" v-bind="value" :aspect-ratio="1 / 1" width="40" class="elevation-24 rounded-lg mx-auto" />
                <template v-else> - </template>
            </template>
            <template #[`item.attendances`]="{item, value}"> {{ value?.length }} / {{ item?.attendees?.length }} </template>
            <template #[`item.qrscan`]="{item}">
                <program-unit-qrscan v-bind="{ ...item, unit: item, program: item.program, users: item.attendees }" v-on="{ postAttendance }">
                    <template #activator="{ attrs, on }">
                        <v-btn text icon tile v-bind="attrs" v-on="on"> <v-icon>mdi-qrcode-scan</v-icon> </v-btn>
                    </template>
                </program-unit-qrscan>
            </template>
        </v-data-table>
    </v-card>
</template>

<script>
import api from "@/api";
import dayjs from "dayjs";
import programUnitQrscan from "../program/program-unit-qrscan.vue";
let headers = [
    { align: "center", text: "이미지", value: "program.thumb" },
    { align: "center", text: "제목", value: "program.name" },
    { align: "center", text: "회차", value: "number", formatter: (value) => `${value}회차` },
    { align: "center", text: "시간", value: "time" },
    { align: "center", text: "출석", value: "attendances" },
    { align: "center", text: "QR스캔", value: "qrscan" },
];

export default {
    components: { programUnitQrscan },
    data: () => ({
        units: [],
        headers,
    }),
    computed: {
        items() {
            return this.units;
        },
    },
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            let today = dayjs();
            this.units = (await api.console.programs.units.gets({ _program: undefined, params: { dates: [today.startOf("day").toDate(), today.endOf("day").toDate()] } }))?.units;
        },
        async postAttendance(item) {
            let { attendance } = await api.console.programs.attendances.post(item);
            let unit = this.units.find(({ _id }) => _id == attendance._unit);
            unit.attendances = [attendance, ...unit.attendances];
        },
    },
};
</script>

<style></style>
