<template>
    <statistics-graph-card label="출석률" :showsNoItem="!items.length">
        <v-chart v-bind="{ initOptions, options }" />
    </statistics-graph-card>
</template>

<script>
import StatisticsGraphCard from "./statistics-graph-card.vue";
export default {
    components: {
        StatisticsGraphCard,
    },
    props: {
        items: { type: Array, default: () => [] },
    },
    computed: {
        initOptions() {
            return {
                height: (this.items.length || 1) * 50 + 110,
            };
        },
        options() {
            return {
                xAxis: {
                    type: "value",
                    boundaryGap: [0, 1],
                },
                yAxis: {
                    type: "category",
                    data: this.items.map((program) => program.name),
                },
                grid: {
                    containLabel: true,
                },
                series: [
                    {
                        type: "bar",
                        data: this.items.map(({ summary }) => {
                            return summary ? (summary.attendancesVal / summary.attendancesMax) * 100 : 0;
                        }),
                    },
                ],
                height: (this.items.length || 1) * 50,
            };
        },
    },
};
</script>

<style lang="scss" scoped>
::v-deep {
    .echarts {
        width: 100%;
        height: 100%;
    }
}
</style>
