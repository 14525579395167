var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.setting ? _c('v-layout', {
    attrs: {
      "justify-center": ""
    }
  }, [_c('v-responsive', {
    staticClass: "mx-n3 px-3",
    attrs: {
      "max-width": "680",
      "width": "100%"
    }
  }, [_c('list-heading', {
    attrs: {
      "title": "SMTP 변경"
    },
    model: {
      value: _vm.showsSearch,
      callback: function ($$v) {
        _vm.showsSearch = $$v;
      },
      expression: "showsSearch"
    }
  }), _c('v-card', {
    attrs: {
      "outlined": ""
    }
  }, [_c('v-card-text', {
    staticClass: "py-3"
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "발신자 아이디 (네이버)",
      "dense": "",
      "outlined": "",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.setting.smtp.username,
      callback: function ($$v) {
        _vm.$set(_vm.setting.smtp, "username", $$v);
      },
      expression: "setting.smtp.username"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "type": "password",
      "label": "비밀번호",
      "dense": "",
      "outlined": "",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.setting.smtp.password,
      callback: function ($$v) {
        _vm.$set(_vm.setting.smtp, "password", $$v);
      },
      expression: "setting.smtp.password"
    }
  })], 1)], 1)], 1), _c('v-divider'), _c('v-card-actions', {
    staticClass: "justify-end"
  }, [_c('v-btn', {
    attrs: {
      "text": "",
      "color": "primary"
    },
    on: {
      "click": _vm.save
    }
  }, [_vm._v(" 변경하기 ")])], 1)], 1), _c('v-col', {
    staticClass: "primary--text caption"
  }, [_vm._v(" ※ \"Naver 메일 -> 환경설정 -> POP3/IMAP 설정 -> IMAP/SMTP 설정 -> IMAP/SMTP 사용\" 을 사용함으로 변경 ")])], 1)], 1) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }