<template>
    <v-layout justify-center>
        <v-responsive max-width="720" width="100%" class="mx-n3 px-3">
            <list-heading title="비밀번호 변경" />

            <v-card outlined>
                <v-card-text class="py-3">
                    <v-row>
                        <v-col cols="12" sm="6"> <v-text-field v-model="admin.password" type="password" label="비밀번호" persistent-placeholder outlined dense hide-details /> </v-col>
                        <v-col cols="12" sm="6"> <v-text-field v-model="admin.passwordRepeat" type="password" label="비밀번호 확인" persistent-placeholder outlined dense hide-details /> </v-col>
                    </v-row>
                </v-card-text>
                <v-divider />
                <v-card-actions>
                    <v-spacer />
                    <v-btn text color="primary" @click="saveAdmin">저장</v-btn>
                </v-card-actions>
            </v-card>
        </v-responsive>
    </v-layout>
</template>

<script>
import api from "@/api";
import CryptoAES from "@/plugins/crypto-aes";

import ListHeading from "@/components/console/dumb/list-heading.vue";

export default {
    components: {
        ListHeading,
    },
    data() {
        return {
            admin: {
                password: "",
                passwordRepeat: "",
            },
        };
    },
    methods: {
        async saveAdmin() {
            let { password, passwordRepeat } = this.admin;
            try {
                if (password !== passwordRepeat) throw new Error("비밀번호가 일치하지 않습니다.");

                password = CryptoAES.encrypt(password);
                await api.console.users.put({ _id: this.$store.state.payload._user, password });

                alert("비밀번호가 변경되었습니다.");
            } catch (error) {
                alert(error.message);
            }
        },
    },
};
</script>
