<template>
    <v-snackbar v-model="shows" v-bind="$attrs">
        <span style="white-space:pre-line"> {{ message }} </span>
        <template #action="{attrs}">
            <v-btn color="pink" text v-bind="attrs" @click="shows = false">닫기</v-btn>
        </template>
    </v-snackbar>
</template>

<script>
export default {
    props: {
        message: { type: String, default: null },
    },
    data: () => ({
        shows: false,
    }),
    watch: {
        message() {
            if (this.message) this.shows = true;
            else this.shows = false;
        },
    },
};
</script>

<style></style>
