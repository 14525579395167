<template>
    <v-data-table v-bind="{ items, headers, itemClass }" disable-filtering disable-pagination disable-sort hide-default-footer>
        <template #no-data> 선택한 스케줄이 없습니다 </template>
        <template v-for="header in headers.filter((header) => header.hasOwnProperty('formatter'))" v-slot:[`item.${header.value}`]="{ value }"> {{ header.formatter(value) }} </template>
        <template #[`item.actions`]="{item}">
            <v-btn dense text icon class="pa-0" @click="$emit('del', item)">
                <v-icon>mdi-minus</v-icon>
            </v-btn>
        </template>
        <template #footer>
            <v-divider />
            <!-- <v-row no-gutters>
                <v-spacer />
                <v-col cols="auto" class="px-4">
                    <v-btn dense text icon class="pa-0" @click="resetSchedules">
                        <v-icon>mdi-minus</v-icon>
                    </v-btn>
                </v-col>
            </v-row> -->
        </template>
    </v-data-table>
</template>

<script>
import dayjs from "dayjs";
let headers = [
    { text: "사용자", value: "user", formatter: ({ number, name }) => `${number} / ${name}` },
    { text: "공간", value: "space", formatter: (value) => `[${value.branch}] ${value.name}` },
    { text: "사용시간", value: "datetime" },
    { text: "", value: "actions", width: "auto", align: "right" },
];
export default {
    props: {
        value: { type: Array, default: () => [] },
        users: { type: Array, default: () => [] },
        spaces: { type: Array, default: () => [] },
    },
    data: () => ({
        headers,
        schedules: [],
    }),
    computed: {
        items() {
            return this.schedules.map((schedule) => {
                let space = this.spaces.find(({ _id }) => _id == schedule._space) || {};
                let user = this.users.find(({ _id }) => _id == schedule._user) || {};
                let datetime = dayjs(schedule.date)
                    .set("hour", schedule.time)
                    .format("YYYY년 M월 D일 HH시");
                return { ...schedule, space, user, datetime };
            });
        },
    },
    mounted() {
        this.sync();
    },
    watch: {
        "value"() {
            this.sync();
        },
        "value.length"() {
            this.sync();
        },
    },
    methods: {
        sync() {
            this.schedules = [...this.value];
        },
        itemClass(item) {
            console.log({ item });
            if (item?.success == true) return "success lighten-5";
            if (item?.success == false) return "error lighten-4";
        },
        // resetSchedules() {
        //     this.$emit("input", []);
        // },
    },
};
</script>
