<template>
    <v-layout justify-center>
        <v-responsive max-width="1024px" width="100%" height="100%" class="mx-n3 px-3">
            <list-heading v-bind="{ title }" />
            <user-view-info v-bind="{ _user }" />
            <user-view-programs v-bind="{ _user }" class="mt-4" />
        </v-responsive>
    </v-layout>
</template>

<script>
import ListHeading from "@/components/console/dumb/list-heading.vue";
import UserViewInfo from "@/components/console/user/user-view-info.vue";
import UserViewPrograms from '@/components/console/user/user-view-programs.vue';

export default {
    components: {
        ListHeading,
        UserViewInfo,
        UserViewPrograms,
    },
    computed: {
        _user() {
            return this.$route.params._user;
        },

        isCreate() {
            return !this._user;
        },
        title() {
            return this.isCreate ? "회원 생성" : "회원 상세";
        },
    },
};
</script>
