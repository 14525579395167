<template>
    <v-layout justify-center>
        <v-responsive max-width="1080" width="100%" height="100%" class="mx-n3 px-3 pb-3">
            <list-heading title="대시보드" />
            <dashboard-counts />
            <dashboard-calendar />
            <dashboard-units class="mt-3" />
        </v-responsive>
    </v-layout>
</template>

<script>
import ListHeading from "@/components/console/dumb/list-heading.vue";
import DashboardUnits from "@/components/console/dashboard/dashboard-units.vue";
import DashboardCounts from "@/components/console/dashboard/dashboard-counts.vue";
import DashboardCalendar from "@/components/console/dashboard/dashboard-calendar.vue";

export default {
    components: {
        ListHeading,
        DashboardUnits,
        DashboardCounts,
        DashboardCalendar,
    },
    data() {
        return {};
    },
};
</script>
