var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-dialog', {
    attrs: {
      "max-width": "900",
      "persistent": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var attrs = _ref.attrs,
          on = _ref.on;
        return [_vm._t("activator", null, null, {
          attrs,
          on
        })];
      }
    }], null, true),
    model: {
      value: _vm.shows,
      callback: function ($$v) {
        _vm.shows = $$v;
      },
      expression: "shows"
    }
  }, [_c('v-card', _vm._b({
    attrs: {
      "height": "100%"
    }
  }, 'v-card', {
    loading: _vm.loading
  }, false), [_c('v-card-title', [_vm.isCreate ? [_vm._v("프로그램 생성")] : [_vm._v("프로그램 상세")], _c('v-spacer'), _c('v-btn', {
    attrs: {
      "absolute": "",
      "right": "",
      "text": "",
      "icon": ""
    },
    on: {
      "click": function ($event) {
        _vm.shows = false;
      }
    }
  }, [_c('v-icon', [_vm._v("mdi-close")])], 1)], 2), _c('v-divider'), _c('v-card-text', {
    staticClass: "pa-0",
    staticStyle: {
      "max-height": "calc(90vh - 16px - 32px - 10px - 1px - 1px - 60px)",
      "overflow-y": "auto"
    }
  }, [_c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "4"
    }
  }, [_c('v-card-subtitle', {
    staticClass: "grey lighten-5"
  }, [_vm._v("썸네일")]), _c('v-divider'), _c('v-card-text', [_c('v-preview-input', {
    attrs: {
      "aspect-ratio": 1 / 1
    },
    model: {
      value: _vm.inputs.thumb,
      callback: function ($$v) {
        _vm.$set(_vm.inputs, "thumb", $$v);
      },
      expression: "inputs.thumb"
    }
  })], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "auto"
    }
  }, [_c('v-divider', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.isMobile,
      expression: "!isMobile"
    }],
    attrs: {
      "vertical": ""
    }
  }), _c('v-divider', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isMobile,
      expression: "isMobile"
    }]
  })], 1), _c('v-col', [_c('v-card-subtitle', {
    staticClass: "grey lighten-5"
  }, [_vm._v("기본 정보")]), _c('v-divider'), _c('v-card-text', [_c('v-row', {
    staticClass: "ma-n2",
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "label": "프로그램명"
    },
    model: {
      value: _vm.inputs.name,
      callback: function ($$v) {
        _vm.$set(_vm.inputs, "name", $$v);
      },
      expression: "inputs.name"
    }
  }, 'v-text-field', _vm.inputAttrs, false))], 1), _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "6"
    }
  }, [_c('select-program-categories', _vm._b({
    attrs: {
      "label": "카테고리"
    },
    model: {
      value: _vm.inputs._category,
      callback: function ($$v) {
        _vm.$set(_vm.inputs, "_category", $$v);
      },
      expression: "inputs._category"
    }
  }, 'select-program-categories', _vm.inputAttrs, false))], 1), _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "label": "주제·내용"
    },
    model: {
      value: _vm.inputs.theme,
      callback: function ($$v) {
        _vm.$set(_vm.inputs, "theme", $$v);
      },
      expression: "inputs.theme"
    }
  }, 'v-text-field', _vm.inputAttrs, false))], 1), _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "6"
    }
  }, [_c('v-currency-field', _vm._b({
    attrs: {
      "label": "모집인원(카운트)",
      "suffix": "명"
    },
    model: {
      value: _vm.inputs.capacity,
      callback: function ($$v) {
        _vm.$set(_vm.inputs, "capacity", $$v);
      },
      expression: "inputs.capacity"
    }
  }, 'v-currency-field', _vm.inputAttrs, false))], 1), _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "label": "모집인원(표기)"
    },
    model: {
      value: _vm.inputs.capacityText,
      callback: function ($$v) {
        _vm.$set(_vm.inputs, "capacityText", $$v);
      },
      expression: "inputs.capacityText"
    }
  }, 'v-text-field', _vm.inputAttrs, false))], 1), _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "label": "수업요일"
    },
    model: {
      value: _vm.inputs.days,
      callback: function ($$v) {
        _vm.$set(_vm.inputs, "days", $$v);
      },
      expression: "inputs.days"
    }
  }, 'v-text-field', _vm.inputAttrs, false))], 1), _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "label": "대상"
    },
    model: {
      value: _vm.inputs.target,
      callback: function ($$v) {
        _vm.$set(_vm.inputs, "target", $$v);
      },
      expression: "inputs.target"
    }
  }, 'v-text-field', _vm.inputAttrs, false))], 1), _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "label": "접수방법"
    },
    model: {
      value: _vm.inputs.applicationMethod,
      callback: function ($$v) {
        _vm.$set(_vm.inputs, "applicationMethod", $$v);
      },
      expression: "inputs.applicationMethod"
    }
  }, 'v-text-field', _vm.inputAttrs, false))], 1), _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "label": "담당자"
    },
    model: {
      value: _vm.inputs.worker,
      callback: function ($$v) {
        _vm.$set(_vm.inputs, "worker", $$v);
      },
      expression: "inputs.worker"
    }
  }, 'v-text-field', _vm.inputAttrs, false))], 1), _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "label": "접수기간(시작)",
      "type": "date"
    },
    model: {
      value: _vm.inputs.applicationStartsAt,
      callback: function ($$v) {
        _vm.$set(_vm.inputs, "applicationStartsAt", $$v);
      },
      expression: "inputs.applicationStartsAt"
    }
  }, 'v-text-field', _vm.inputAttrs, false))], 1), _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "label": "접수기간(종료)",
      "type": "date"
    },
    model: {
      value: _vm.inputs.applicationEndsAt,
      callback: function ($$v) {
        _vm.$set(_vm.inputs, "applicationEndsAt", $$v);
      },
      expression: "inputs.applicationEndsAt"
    }
  }, 'v-text-field', _vm.inputAttrs, false))], 1), _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "label": "진행기간(시작)",
      "type": "date"
    },
    model: {
      value: _vm.inputs.startsAt,
      callback: function ($$v) {
        _vm.$set(_vm.inputs, "startsAt", $$v);
      },
      expression: "inputs.startsAt"
    }
  }, 'v-text-field', _vm.inputAttrs, false))], 1), _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "label": "진행기간(종료)",
      "type": "date"
    },
    model: {
      value: _vm.inputs.endsAt,
      callback: function ($$v) {
        _vm.$set(_vm.inputs, "endsAt", $$v);
      },
      expression: "inputs.endsAt"
    }
  }, 'v-text-field', _vm.inputAttrs, false))], 1)], 1)], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-divider'), _c('form-table-row', {
    attrs: {
      "label": "비용",
      "cols": "2"
    }
  }, [_c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-radio-group', {
    staticClass: "mt-0",
    attrs: {
      "row": "",
      "dense": "",
      "hide-details": ""
    },
    model: {
      value: _vm.inputs.isFree,
      callback: function ($$v) {
        _vm.$set(_vm.inputs, "isFree", $$v);
      },
      expression: "inputs.isFree"
    }
  }, [_c('v-radio', {
    attrs: {
      "label": "무료",
      "value": true
    }
  }), _vm._v(" "), _c('v-radio', {
    attrs: {
      "label": "유료",
      "value": false
    }
  })], 1)], 1), _c('v-col', {
    staticClass: "mt-n1 mb-n2",
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-fade-transition', [_c('v-currency-field', _vm._b({
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.inputs.isFree,
      expression: "!inputs.isFree"
    }],
    attrs: {
      "label": "금액",
      "suffix": "원"
    },
    model: {
      value: _vm.inputs.charge,
      callback: function ($$v) {
        _vm.$set(_vm.inputs, "charge", $$v);
      },
      expression: "inputs.charge"
    }
  }, 'v-currency-field', _vm.inputAttrs, false))], 1)], 1)], 1)], 1), _c('form-table-row', {
    attrs: {
      "label": "회차",
      "cols": "2",
      "contentClass": "px-0"
    }
  }, [_c('program-view-units', {
    ref: "program-view-units",
    model: {
      value: _vm.inputs.units,
      callback: function ($$v) {
        _vm.$set(_vm.inputs, "units", $$v);
      },
      expression: "inputs.units"
    }
  })], 1), _c('form-table-row', {
    attrs: {
      "label": "키워드",
      "cols": "2"
    }
  }, [_c('autocomplete-program-keywords', _vm._b({
    attrs: {
      "outlined": false
    },
    model: {
      value: _vm.inputs._keywords,
      callback: function ($$v) {
        _vm.$set(_vm.inputs, "_keywords", $$v);
      },
      expression: "inputs._keywords"
    }
  }, 'autocomplete-program-keywords', _vm.inputAttrs, false))], 1), _c('v-card-subtitle', {
    staticClass: "grey lighten-5"
  }, [_vm._v("상세 내용")]), _c('v-divider'), _c('naver-smarteditor', {
    staticClass: "ma-3",
    model: {
      value: _vm.inputs.detail,
      callback: function ($$v) {
        _vm.$set(_vm.inputs, "detail", $$v);
      },
      expression: "inputs.detail"
    }
  })], 1)], 1)], 1), _c('v-divider'), _c('v-card-actions', {
    staticClass: "px-6 grey lighten-5"
  }, [_c('v-spacer'), _c('v-btn', _vm._b({
    attrs: {
      "large": "",
      "color": "primary"
    },
    on: {
      "click": _vm.save
    }
  }, 'v-btn', {
    loading: _vm.loading
  }, false), [_vm._v(" 저장하기 ")])], 1), _c('v-fade-transition', [_c('v-overlay', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.loading,
      expression: "loading"
    }],
    attrs: {
      "absolute": ""
    }
  }, [_c('v-progress-circular', {
    attrs: {
      "indeterminate": "",
      "size": "100",
      "width": "5"
    }
  })], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }