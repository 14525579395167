var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-row', [_c('v-col', {
    staticClass: "pt-0",
    attrs: {
      "cols": "12",
      "md": ""
    }
  }, [_c('dashboard-applications-dialog', {
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var attrs = _ref.attrs,
          on = _ref.on;
        return [_c('v-card', _vm._g(_vm._b({
          staticClass: "px-12",
          attrs: {
            "outlined": ""
          }
        }, 'v-card', attrs, false), on), [_c('v-row', {
          staticClass: "mx-0",
          attrs: {
            "align": "center",
            "justify": "center",
            "justify-lg": "space-around"
          }
        }, [_c('v-col', {
          staticStyle: {
            "white-space": "pre-line"
          },
          attrs: {
            "cols": "auto",
            "lg": ""
          }
        }, [_vm._v(" " + _vm._s("프로그램\n승인대기") + " ")]), _c('v-col', {
          attrs: {
            "cols": "auto"
          }
        }, [_c('v-avatar', {
          staticClass: "text-h4 font-weight-light"
        }, [_vm._v(_vm._s(_vm.programApplicationCount) + " ")])], 1)], 1)], 1)];
      }
    }])
  })], 1), _vm._l(_vm.items, function (_ref2) {
    var to = _ref2.to,
      text = _ref2.text,
      count = _ref2.count;
    return _c('v-col', {
      key: to,
      staticClass: "pt-0",
      attrs: {
        "cols": "12",
        "md": ""
      }
    }, [_c('v-card', _vm._b({
      staticClass: "px-12",
      attrs: {
        "outlined": ""
      }
    }, 'v-card', {
      to
    }, false), [_c('v-row', {
      staticClass: "mx-0",
      attrs: {
        "align": "center",
        "justify": "center",
        "justify-lg": "space-around"
      }
    }, [_c('v-col', {
      staticStyle: {
        "white-space": "pre-line"
      },
      attrs: {
        "cols": "auto",
        "lg": ""
      }
    }, [_vm._v(" " + _vm._s(text))]), _c('v-col', {
      attrs: {
        "cols": "auto"
      }
    }, [_c('v-avatar', {
      staticClass: "text-h4 font-weight-light"
    }, [_vm._v(" " + _vm._s(count) + " ")])], 1)], 1)], 1)], 1);
  })], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }