<template>
    <v-data-table v-bind="{ items, headers }" disable-filtering disable-pagination disable-sort :items-per-page="-1" hide-default-footer class="v-sheet--outlined">
        <template v-for="header in headers.filter((header) => header.hasOwnProperty('formatter'))" #[`item.${header.value}`]="{ value }"> {{ header.formatter(value) }} </template>

        <template #[`item.attendances`]="{ item, value }"> {{ value.length }} / {{ item.units.length }} </template>

        <template #[`item.actions`]="{ item }">
            <program-attendee-attendances v-bind="{ ...item, units: item.units }">
                <template #activator="{ attrs, on }">
                    <v-btn text icon tile v-bind="attrs" v-on="on"> <v-icon>mdi-eye</v-icon> </v-btn>
                </template>
            </program-attendee-attendances>
        </template>

        <template #footer>
            <v-divider />
            <v-pagination :value="page" :length="pageCount" :total-visible="11" class="mx-4" @input="(page) => $router.push({ query: { ...$route.query, page } })"></v-pagination>
        </template>
    </v-data-table>
</template>

<script>
import api from "@/api";
import ProgramAttendeeAttendances from "../program/program-attendee-attendances.vue";

let headers = [
    { text: "프로그램명", value: "name" },
    { text: "기간", value: "period" },
    { text: "출석률", value: "attendances" },
    { text: "출석현황", value: "actions" },
];
export default {
    components: {
        ProgramAttendeeAttendances,
    },
    props: {
        _user: { type: String, defatul: undefined },
    },
    data: () => ({
        attendances: [],
        programs: [],
        limit: 10,
        summary: { totalCount: 0 },
        headers,
    }),
    computed: {
        items() {
            return this.programs.map((item, index) => ({ ...item, index: this.summary.totalCount - (this.page - 1) * this.limit - index, attendances: this.attendances.filter(({ _program }) => _program == item?._id) || [] }));
        },
        page() {
            return +(this.$route.query.page || "1");
        },
        skip() {
            return (this.page - 1) * this.limit;
        },
        pageCount() {
            return Math.ceil(this.summary.totalCount / this.limit) || 1;
        },
        params() {
            let { query } = this.$route;
            return { ...query, _attendees: this._user };
        },
    },
    mounted() {
        this.init();
    },
    watch: {
        params() {
            this.search();
        },
    },
    methods: {
        async init() {
            try {
                this.search();
            } catch (error) {
                console.error(error);
            }
        },

        async search() {
            try {
                let { skip, limit, params } = this;
                var { summary, programs } = await api.console.programs.gets({
                    headers: { skip, limit },
                    params,
                });

                this.summary = summary;
                this.programs = programs;

                let _program = programs.map(({ _id }) => _id);
                
                if (_program.length) {
                    for (let i = 0; i < _program.length; i++) {
                        let attendance = (await api.console.programs.attendances.gets({ _program: _program[i] }))?.attendances[0]
                        if (attendance) {
                            this.attendances.push(attendance)
                        }
                    }
                }
            } catch (error) {
                console.error(error);
                if (error.response) alert(error.response.data.email);
                else alert(error.email.toString());
            }
        },
    },
};
</script>

<style></style>
