<template>
    <v-dialog v-model="shows" max-width="1080" persistent :fullscreen="$vuetify.breakpoint.smAndDown" content-class="rounded-sm-0">
        <template v-slot:activator="{ attrs, on }">
            <slot name="activator" v-bind="{ attrs, on }" />
        </template>
        <v-card v-bind="{ loading }" height="100%" rounded="0">
            <v-card-title>
                <template>대여예약 상세</template>
                <v-spacer />
                <v-btn absolute right text icon @click="shows = false"><v-icon>mdi-close</v-icon></v-btn>
            </v-card-title>
            <v-divider />
            <v-sheet class="pa-0 pb-1 mb-n1" style="max-height: calc(90vh - 16px - 32px - 10px - 1px - 1px - 60px); overflow-y: auto">
                <v-row no-gutters>
                    <v-col cols="12" lg="5">
                        <form-table-row label="회원">
                            <autocomplete-user v-model="$data._user" hide-details dense v-bind="{ loading, disabled, outlined: false, readonly: !!schedules.length, readonlyMessage: '동시에 회원 한 명의 스케줄만 등록할 수 있습니다.\n\n다른 회원의 대여예약을 생성하기 위해서는 선택한 스케줄을 모두 제거하셔야 합니다.' }" ref="autocomplete-user" />
                        </form-table-row>
                        <form-table-row label="공간">
                            <autocomplete-rental-space v-model="$data._space" hide-details dense v-bind="{ loading, disabled, outlined: false }" ref="autocomplete-rental-space" />
                        </form-table-row>
                        <form-table-row label="사용목적">
                            <v-text-field v-model="usage" hide-details dense v-bind="{ loading, disabled }" />
                        </form-table-row>
                        <template v-if="$vuetify.breakpoint.xsOnly">
                            <div class="pa-3 grey lighten-5 subtitle-2 font-weight-bold">예약일</div>
                            <v-divider />
                            <v-layout justify-center>
                                <rental-schedule-date-picker v-model="date" v-bind="{ loading, disabled }" />
                            </v-layout>
                            <v-divider />
                        </template>
                        <template v-else>
                            <form-table-row label="예약일" contentClass="d-flex justify-center">
                                <rental-schedule-date-picker v-model="date" v-bind="{ loading, disabled }" />
                            </form-table-row>
                        </template>
                        <form-table-row label="예약시간">
                            <rental-schedule-time-picker v-model="schedules" v-bind="{ loading, _user: $data._user, space, date, schedulesOfUser, schedulesOnCloud, limitationMet, limitationMetOnBranches }" v-on="{ del }" />
                        </form-table-row>
                    </v-col>
                    <v-col cols="12" lg="auto">
                        <v-divider class="d-none d-lg-block" vertical />
                        <v-divider class="d-block d-lg-none" />
                    </v-col>
                    <v-col cols="12" lg="">
                        <div class="pa-3 grey lighten-5 subtitle-2 font-weight-bold">선택한 스케줄</div>
                        <v-divider />
                        <rental-schedule-selected v-model="schedules" v-bind="{ loading, users, spaces }" v-on="{ del }" />
                    </v-col>
                </v-row>
            </v-sheet>
            <v-divider />
            <v-card-actions class="px-6 grey lighten-5">
                <v-btn large outlined v-bind="{ loading }" @click="init">초기화</v-btn>
                <template v-if="disabled"> 대여예약을 계속하시려면 선택한 스케줄을 먼저 초기화 해주세요 </template>
                <v-spacer />
                <v-btn large color="primary" v-bind="{ loading, disabled }" @click="save"> 저장하기 </v-btn>
            </v-card-actions>
        </v-card>
        <rental-limitation-per-week v-model="limitationPerWeekMet" v-bind="{ _user: $data._user, date, limitations, schedules }" />
        <rental-limitation-per-week-on-branch v-model="limitationPerWeekMetOnBranches" v-bind="{ _user: $data._user, date, limitations, schedules }" />
    </v-dialog>
</template>

<script>
import api from "@/api";
import { RENTAL_SCHEDULE_STATES } from "@/assets/variables";

import FormTableRow from "../dumb/form-table-row.vue";
import AutocompleteUser from "@/components/dumb/autocomplete-user.vue";
import AutocompleteRentalSpace from "@/components/dumb/autocomplete-rental-space.vue";
import RentalScheduleSelected from "./rental-schedule-selected.vue";
import RentalScheduleDatePicker from "@/components/dumb/rental-schedule-date-picker.vue";
import RentalScheduleTimePicker from "@/components/dumb/rental-schedule-time-picker.vue";
import RentalLimitationPerWeek from "./rental-limitation-per-week.vue";
import RentalLimitationPerWeekOnBranch from "./rental-limitation-per-week-on-branch.vue";

export default {
    components: {
        FormTableRow,
        AutocompleteUser,
        AutocompleteRentalSpace,
        RentalScheduleSelected,
        RentalScheduleDatePicker,
        RentalScheduleTimePicker,
        RentalLimitationPerWeek,
        RentalLimitationPerWeekOnBranch,
    },
    data: () => ({
        shows: false,
        loading: false,

        _user: null,
        _space: null,
        date: Date.now().toDate(),
        usage: null,

        users: [],
        spaces: [],

        schedulesOfUser: [],
        schedulesOnCloud: [],

        limitations: [],
        limitationPerWeekMet: false,
        limitationPerWeekMetOnBranches: [],

        schedules: [],
    }),
    computed: {
        space() {
            return this.spaces.find(({ _id }) => _id == this.$data._space);
        },
        disabled() {
            return this.schedules.some(({ _id }) => _id);
        },
        limitationMet() {
            return this.limitationPerWeekMet;
        },
        limitationMetOnBranches() {
            return this.limitationPerWeekMetOnBranches;
        },
    },
    mounted() {
        this.init();
    },
    watch: {
        shows() {
            if (this.shows) this.getSchedules();
            else this.init();
        },
        "$data._user"() {
            if (this.$data._user) {
                let user = this.$refs["autocomplete-user"]?.$data.users.find(({ _id }) => _id == this.$data._user);
                let index = this.users.findIndex(({ _id }) => _id == user._id);
                if (index > -1) {
                    this.users.splice(index, 1, user);
                } else {
                    this.users.push(user);
                }
            }
            this.getSchedules();
        },
        "$data._space"() {
            if (this.$data._space) {
                let space = this.$refs["autocomplete-rental-space"]?.$data.spaces.find(({ _id }) => _id == this.$data._space);
                let index = this.spaces.findIndex(({ _id }) => _id == space._id);
                if (index > -1) {
                    this.spaces.splice(index, 1, space);
                } else {
                    this.spaces.push(space);
                }
            }
            this.getSchedules();
        },
        date() {
            this.getSchedules();
        },
    },
    methods: {
        async init() {
            this.loading = true;
            try {
                this.schedules = [];
                this.$data._user = null;
                this.$data._space = null;
                this.date = Date.now().toDate();
                this.usage = null;
                this.users = [];
                this.spaces = [];
                this.limitations = [];
                this.limitationPerWeekMet = false;
                this.schedulesOnCloud = [];
                this.schedulesOfUser = [];
                this.schedules = [];

                let { spaces } = await api.v1.rentalSpaces.gets({ params: { shows: true } });
                this.spaces = spaces;

                let { limitations } = await api.v1.rentalLimitations.gets({ params: { uses: true } });
                this.limitations = limitations;
            } catch (error) {
                console.error(error);
            } finally {
                this.loading = false;
            }
        },

        async getSchedules() {
            this.loading = true;
            try {
                if (this.shows && this.space?._id && this.$data._user) {
                    let { _user, _space, date } = this.$data;
                    let { APPLIED, APPROVED } = RENTAL_SCHEDULE_STATES;
                    let { schedules: schedulesOnCloud } = await api.console.rentalSchedules.gets({
                        params: { _space, date, state: [APPLIED.value, APPROVED.value] },
                    });
                    this.schedulesOnCloud = schedulesOnCloud;

                    let { schedules: schedulesOfUser } = await api.console.rentalSchedules.gets({
                        params: { _user, date, state: [APPLIED.value, APPROVED.value] },
                    });
                    console.log({ schedulesOfUser, _user });
                    this.schedulesOfUser = schedulesOfUser;
                }
            } finally {
                this.loading = false;
            }
        },

        async save() {
            this.loading = true;
            let { usage } = this;
            this.schedules = [
                ...(await Promise.all(
                    this.schedules.map(async (item) => {
                        try {
                            return { ...(await api.console.rentalSchedules.post({ ...item, usage }))?.schedule, success: true };
                        } catch (error) {
                            console.error(error);
                            return { ...item, success: false };
                        }
                    })
                )),
            ];
            let failedItemsLength = this.schedules.filter(({ success }) => !success)?.length;
            if (failedItemsLength) {
                alert(`총 ${this.schedules.length} 건 중 ${failedItemsLength} 건의 대여예약 생성에 실패하였습니다.`);
            } else {
                alert("모든 대여예약 생성에 성공하였습니다.");
            }
            this.loading = false;
        },

        del(schedule) {
            let { _space, date, time } = schedule;
            let index = this.schedules.findIndex((item) => item._space == _space && item.date == date && item.time == time);
            if (index > -1) this.schedules.splice(index, 1);
        },
    },
};
</script>
