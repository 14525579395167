<template>
    <v-dialog v-model="shows" max-width="900" persistent>
        <template v-slot:activator="{ attrs, on }">
            <slot name="activator" v-bind="{ attrs, on }" />
        </template>
        <v-card v-bind="{ loading }" height="100%">
            <v-card-title>
                <template v-if="isCreate">프로그램 생성</template>
                <template v-else>프로그램 상세</template>
                <v-spacer />
                <v-btn absolute right text icon @click="shows = false"><v-icon>mdi-close</v-icon></v-btn>
            </v-card-title>
            <v-divider />
            <v-card-text class="pa-0" style="max-height:calc(90vh - 16px - 32px - 10px - 1px - 1px - 60px); overflow-y:auto">
                <v-row no-gutters>
                    <v-col cols="12" sm="4">
                        <v-card-subtitle class="grey lighten-5">썸네일</v-card-subtitle>
                        <v-divider />
                        <v-card-text>
                            <v-preview-input v-model="inputs.thumb" :aspect-ratio="1 / 1" />
                        </v-card-text>
                    </v-col>
                    <v-col cols="12" sm="auto">
                        <v-divider v-show="!isMobile" vertical />
                        <v-divider v-show="isMobile" />
                    </v-col>
                    <v-col>
                        <v-card-subtitle class="grey lighten-5">기본 정보</v-card-subtitle>
                        <v-divider />
                        <v-card-text>
                            <v-row no-gutters class="ma-n2">
                                <v-col class="pa-2" cols="6"> <v-text-field label="프로그램명" v-model="inputs.name" v-bind="inputAttrs" /> </v-col>
                                <v-col class="pa-2" cols="6"> <select-program-categories label="카테고리" v-model="inputs._category" v-bind="inputAttrs" /> </v-col>
                                <v-col class="pa-2" cols="12"> <v-text-field label="주제·내용" v-model="inputs.theme" v-bind="inputAttrs" /> </v-col>
                                <v-col class="pa-2" cols="6"> <v-currency-field label="모집인원(카운트)" suffix="명" v-model="inputs.capacity" v-bind="inputAttrs" /> </v-col>
                                <v-col class="pa-2" cols="6"> <v-text-field label="모집인원(표기)" v-model="inputs.capacityText" v-bind="inputAttrs" /> </v-col>
                                <v-col class="pa-2" cols="6"> <v-text-field label="수업요일" v-model="inputs.days" v-bind="inputAttrs" /> </v-col>
                                <v-col class="pa-2" cols="6"> <v-text-field label="대상" v-model="inputs.target" v-bind="inputAttrs" /> </v-col>
                                <v-col class="pa-2" cols="6"> <v-text-field label="접수방법" v-model="inputs.applicationMethod" v-bind="inputAttrs" /> </v-col>
                                <v-col class="pa-2" cols="6"> <v-text-field label="담당자" v-model="inputs.worker" v-bind="inputAttrs" /> </v-col>
                                <v-col class="pa-2" cols="6"> <v-text-field label="접수기간(시작)" v-model="inputs.applicationStartsAt" type="date" v-bind="inputAttrs" /> </v-col>
                                <v-col class="pa-2" cols="6"> <v-text-field label="접수기간(종료)" v-model="inputs.applicationEndsAt" type="date" v-bind="inputAttrs" /> </v-col>
                                <v-col class="pa-2" cols="6"> <v-text-field label="진행기간(시작)" v-model="inputs.startsAt" type="date" v-bind="inputAttrs" /> </v-col>
                                <v-col class="pa-2" cols="6"> <v-text-field label="진행기간(종료)" v-model="inputs.endsAt" type="date" v-bind="inputAttrs" /> </v-col>
                            </v-row>
                        </v-card-text>
                    </v-col>
                    <v-col cols="12">
                        <v-divider />
                        <form-table-row label="비용" cols="2">
                            <v-row no-gutters>
                                <v-col cols="auto">
                                    <v-radio-group row v-model="inputs.isFree" dense hide-details class="mt-0"> <v-radio label="무료" :value="true" /> <v-radio label="유료" :value="false" /> </v-radio-group>
                                </v-col>
                                <v-col cols="auto" class="mt-n1 mb-n2">
                                    <v-fade-transition> <v-currency-field v-show="!inputs.isFree" label="금액" suffix="원" v-model="inputs.charge" v-bind="inputAttrs" /> </v-fade-transition>
                                </v-col>
                            </v-row>
                        </form-table-row>
                        <form-table-row label="회차" cols="2" contentClass="px-0"> <program-view-units v-model="inputs.units" ref="program-view-units" /> </form-table-row>
                        <form-table-row label="키워드" cols="2"> <autocomplete-program-keywords v-model="inputs._keywords" v-bind="inputAttrs" :outlined="false" /> </form-table-row>
                        <v-card-subtitle class="grey lighten-5">상세 내용</v-card-subtitle>
                        <v-divider />
                        <naver-smarteditor v-model="inputs.detail" class="ma-3" />
                    </v-col>
                </v-row>
            </v-card-text>
            <v-divider />
            <v-card-actions class="px-6 grey lighten-5">
                <v-spacer />
                <v-btn large color="primary" v-bind="{ loading }" @click="save">
                    저장하기
                </v-btn>
            </v-card-actions>
            <v-fade-transition>
                <v-overlay v-show="loading" absolute>
                    <v-progress-circular indeterminate size="100" width="5" />
                </v-overlay>
            </v-fade-transition>
        </v-card>
    </v-dialog>
</template>

<script>
import api from "@/api";
import detect from "@/plugins/detect";
import { inputAttrs } from "@/assets/variables";
import ImagesInput from "../dumb/images-input.vue";
import FormTableRow from "../dumb/form-table-row.vue";
import VPreviewInput from "@/components/plugins/vuetify/v-preview-input.vue";
import ProgramViewUnits from "./program-view-units.vue";
import NaverSmarteditor from "@/components/plugins/naver/naver-smarteditor.vue";
import SelectProgramCategories from "./select-program-categories.vue";
import AutocompleteProgramKeywords from "../../dumb/autocomplete-program-keywords.vue";

const initProgram = () => ({
    thumb: null,
    _thumb: null,
    units: [],
    _units: [],
    _category: null,
    _keywords: [],

    name: null,
    days: null,
    theme: null,
    detail: null,
    isFree: true,
    charge: 0,
    target: null,
    worker: null,
    endsAt: null,
    startsAt: null,
    capacity: 0,
    capacityText: null,

    applicationMethod: null,
    applicationEndsAt: null,
    applicationStartsAt: null,
});

export default {
    components: {
        ImagesInput,
        FormTableRow,
        VPreviewInput,
        ProgramViewUnits,
        NaverSmarteditor,
        SelectProgramCategories,
        AutocompleteProgramKeywords,
    },
    props: {
        value: { type: Object },
    },
    data: () => ({
        shows: false,
        loading: initProgram(),

        inputs: {},
        keywords: [],
        categories: [],

        inputAttrs,
        isMobile: detect == "mobile",
    }),
    computed: {
        _program() {
            return this.value?._id;
        },
        isCreate() {
            return !this._program;
        },
    },
    watch: {
        shows(shows) {
            if (shows) this.init();
        },
        "inputs.area.width"() {
            const { width = 0, height = 0 } = this.inputs.area;
            this.inputs.area.square = width * height || 0;
        },
        "inputs.area.height"() {
            const { width = 0, height = 0 } = this.inputs.area;
            this.inputs.area.square = width * height || 0;
        },
    },
    methods: {
        async init() {
            this.loading = true;
            try {
                if (this.isCreate) {
                    this.inputs = { ...initProgram() };
                } else {
                    let { program } = await api.console.programs.get({ _id: this._program });
                    program.startsAt = program.startsAt?.toDate?.() || null;
                    program.endsAt = program.endsAt?.toDate?.() || null;
                    program.applicationStartsAt = program.applicationStartsAt?.toDate?.() || null;
                    program.applicationEndsAt = program.applicationEndsAt?.toDate?.() || null;

                    program.thumb = program.thumb?.url ? await api.getResource(program?.thumb.url, true) : null;
                    this.inputs = program;
                }
            } catch (error) {
                this.$handleError(error);
            } finally {
                this.loading = false;
            }
        },

        validate() {},

        async save() {
            if (this.loading) return;
            this.loading = true;
            try {
                this.validate();

                let { _thumb, thumb, ...inputs } = this.inputs;

                // POST/PUT program
                let { post, put } = api.console.programs;
                let { program } = await (this.isCreate ? post(inputs) : put(inputs));
                let _program = program?._id;

                // DELETE/POST/PUT units
                let { _units } = await this.$refs["program-view-units"]?.save?.({ _program });

                // DELETE/POST files
                if (_thumb) await api.console.programs.thumb.delete({ _id: _thumb, _program });
                if (thumb) _thumb = (await api.console.programs.thumb.post({ _program }, thumb))?.thumb?._id;
                else _thumb = null;

                // PUT _units @program
                program = (await put({ _id: _program, _thumb, _units }))?.program;

                alert("저장되었습니다");
                if (this.isCreate) this.$emit("created");
                else this.$emit("input", program);
                this.shows = false;
            } catch (error) {
                this.$handleError(error);
            } finally {
                this.loading = false;
            }
        },
    },
};
</script>
