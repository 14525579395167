<template>
    <v-row>
        <v-col cols="12" md="" class="pt-0">
            <dashboard-applications-dialog>
                <template #activator="{ attrs, on }">
                    <v-card outlined class="px-12" v-bind="attrs" v-on="on">
                        <v-row align="center" justify="center" justify-lg="space-around" class="mx-0">
                            <v-col cols="auto" lg="" style="white-space: pre-line"> {{"프로그램\n승인대기"}} </v-col>
                            <v-col cols="auto">
                                <v-avatar class="text-h4 font-weight-light">{{ programApplicationCount }} </v-avatar>
                            </v-col>
                        </v-row>
                    </v-card>
                </template>
            </dashboard-applications-dialog>
        </v-col>
        <v-col v-for="{ to, text, count } in items" :key="to" cols="12" md="" class="pt-0">
            <v-card v-bind="{ to }" outlined class="px-12">
                <v-row align="center" justify="center" justify-lg="space-around" class="mx-0">
                    <v-col cols="auto" lg="" style="white-space: pre-line"> {{ text }}</v-col>
                    <v-col cols="auto">
                        <v-avatar class="text-h4 font-weight-light"> {{ count }} </v-avatar>
                    </v-col>
                </v-row>
            </v-card>
        </v-col>
    </v-row>
</template>

<script>
import api from "@/api";
import { PROGRAM_APPLICATION_STATES, RENTAL_SCHEDULE_STATES } from "@/assets/variables";

import DashboardApplicationsDialog from "./dashboard-applications-dialog.vue";

export default {
    components: {
        DashboardApplicationsDialog,
    },
    data: () => ({
        programApplicationCount: 0,
        rentalScheduleCount: 0,
        questionCount: 0,
    }),
    computed: {
        items() {
            return [
                // { text: "프로그램\n승인대기", count: this.programApplicationCount, to: "/console/programs?status=접수중" },
                { text: "대여예약\n승인대기", count: this.rentalScheduleCount, to: `/console/rental-schedules?state=${RENTAL_SCHEDULE_STATES.APPLIED.value}` },
                { text: "1:1문의\n답변대기", count: this.questionCount, to: "/console/center/questions" },
            ];
        },
    },
    mounted() {
        this.search();
    },
    methods: {
        async search() {
            this.programApplicationCount = (await api.console.programs.applications.gets({ _program: undefined, params: { state: PROGRAM_APPLICATION_STATES.APPLIED.value, onlyCount: true } }))?.summary?.totalCount || 0;

            this.rentalScheduleCount = (await api.console.rentalSchedules.gets({ params: { state: PROGRAM_APPLICATION_STATES.APPLIED.value, onlyCount: true } }))?.summary?.totalCount || 0;

            this.questionCount = (await api.console.center.questions.gets({ params: { reply: "unAnswered", onlyCount: true } }))?.summary?.totalCount || 0;
        },
    },
};
</script>

<style></style>
