<template>
    <v-dialog v-model="shows" max-width="900" persistent>
        <template v-slot:activator="{ attrs, on }">
            <slot name="activator" v-bind="{ attrs, on }" />
        </template>
        <v-card v-bind="{ loading }" height="100%">
            <v-card-title>
                <template v-if="isCreate">대여공간 생성</template>
                <template v-else>대여공간 상세</template>
                <v-spacer />
                <v-btn absolute right text icon @click="shows = false"><v-icon>mdi-close</v-icon></v-btn>
            </v-card-title>
            <v-divider />
            <v-card-text class="pa-0" style="max-height:calc(90vh - 16px - 32px - 10px - 1px - 1px - 60px); overflow-y:auto">
                <v-row no-gutters>
                    <v-col cols="12" sm="4">
                        <v-card-subtitle class="grey lighten-5">썸네일</v-card-subtitle>
                        <v-divider />
                        <v-card-text>
                            <v-preview-input v-model="inputs.thumb" :aspect-ratio="1 / 1" />
                        </v-card-text>
                    </v-col>
                    <v-col cols="12" sm="auto">
                        <v-divider v-show="!isMobile" vertical />
                        <v-divider v-show="isMobile" />
                    </v-col>
                    <v-col>
                        <v-card-subtitle class="grey lighten-5">기본 정보</v-card-subtitle>
                        <v-divider />
                        <v-card-text>
                            <v-row no-gutters class="ma-n2">
                                <v-col class="pa-2" cols="12"> <v-text-field label="공간명 (*센터가 다르더라도 중복된 공간명은 가질 수 없습니다.)" v-model="inputs.name" v-bind="inputAttrs" /> </v-col>
                                <v-col class="pa-2" cols="12"> <v-select label="지역" :items="branches" v-model="inputs.branch" v-bind="inputAttrs" /> </v-col>
                                <v-col class="pa-2" cols="3"> <v-currency-field label="가로" suffix="m" v-model="inputs.area.width" v-bind="inputAttrs" /> </v-col>
                                <v-col class="pa-2" cols="3"> <v-currency-field label="세로" suffix="m" v-model="inputs.area.height" v-bind="inputAttrs" /> </v-col>
                                <v-col class="pa-2" cols="3"> <v-currency-field label="면적" suffix="㎡" v-model="inputs.area.square" v-bind="inputAttrs" disabled /> </v-col>
                                <v-col class="pa-2" cols="3"> <v-text-field label="수용규모" v-model="inputs.capacity" v-bind="inputAttrs" /> </v-col>
                                <v-col class="pa-2" cols="12"> <v-text-field label="대여용도" v-model="inputs.usage" v-bind="inputAttrs" /> </v-col>
                                <v-col class="pa-2" cols="12"> <v-textarea label="설비" v-model="inputs.facility" v-bind="inputAttrs" rows="3" /> </v-col>
                            </v-row>
                        </v-card-text>
                    </v-col>
                    <v-col cols="12">
                        <v-divider />
                        <form-table-row label="공간사진" cols="2">
                            <images-input v-model="inputs.photos" cols="1" class="my-3" />
                        </form-table-row>
                        <form-table-row label="대여가능요일" cols="2">
                            <v-row no-gutters align="stretch">
                                <v-col v-for="{ label, value } in businessDays" :key="value">
                                    <v-checkbox v-model="inputs.businessDays" v-bind="{ label, value }" dense hide-details multiple class="mt-0" />
                                </v-col>
                            </v-row>
                        </form-table-row>
                        <form-table-row label="대여가능시간" cols="2">
                            <v-row no-gutters align="stretch">
                                <v-col v-for="{ label, value } in businessHours" :key="value">
                                    <v-checkbox v-model="inputs.businessHours" v-bind="{ label, value }" dense hide-details multiple class="mt-0" />
                                </v-col>
                            </v-row>
                        </form-table-row>
                        <form-table-row label="대여불가일자" cols="2">
                            <v-row>
                                <v-col cols="auto">
                                    <v-date-picker v-model="inputs.holidays" multiple />
                                </v-col>
                                <v-col>
                                    <v-chip-group v-model="inputs.holidays" column>
                                        <v-chip v-for="holiday in inputs.holidays" :key="holiday" close @click:close="inputs.holidays = inputs.holidays.filter((item) => item != holiday)">{{ holiday.toDate() }}</v-chip>
                                    </v-chip-group>
                                </v-col>
                            </v-row>
                        </form-table-row>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-divider />
            <v-card-actions class="px-6 grey lighten-5">
                <v-switch v-model="inputs.shows" inset label="사용자 노출" class="ma-0 pa-0" hide-details />
                <v-spacer />
                <v-btn large color="primary" v-bind="{ loading }" @click="save">
                    저장하기
                </v-btn>
            </v-card-actions>
            <v-fade-transition>
                <v-overlay v-show="loading" absolute z-index="0" :dark="false" color="white">
                    <v-progress-circular indeterminate width="5" size="100" color="black" />
                </v-overlay>
            </v-fade-transition>
        </v-card>
    </v-dialog>
</template>

<script>
import api from "@/api";
import detect from "@/plugins/detect";
import { branches, inputAttrs } from "@/assets/variables";

import ImagesInput from "../dumb/images-input.vue";
import FormTableRow from "../dumb/form-table-row.vue";
import VPreviewInput from "@/components/plugins/vuetify/v-preview-input.vue";

const inintRentalSpace = () => ({
    _thumb: null,
    _photos: [],

    thumb: null,
    photos: [],

    branch: null,
    name: null,
    area: {
        width: 0,
        height: 0,
    },
    capacity: 0,
    usage: null,
    facility: null,
    holidays: [],
    businessDays: [1, 2, 3, 4, 5],
    businessHours: [10, 11, 12, 13, 14, 15, 16, 17, 18],
    shows: true,
});

const businessDays = [
    { label: "일", value: 0 },
    { label: "월", value: 1 },
    { label: "화", value: 2 },
    { label: "수", value: 3 },
    { label: "목", value: 4 },
    { label: "금", value: 5 },
    { label: "토", value: 6 },
];
const businessHours = [10, 11, 12, 13, 14, 15, 16, 17, 18].map((value) => ({ label: value + "시", value }));

export default {
    components: {
        ImagesInput,
        FormTableRow,
        VPreviewInput,
    },
    props: {
        value: { type: Object },
    },
    data: () => ({
        shows: false,
        loading: false,

        inputs: inintRentalSpace(),

        branches,
        inputAttrs,
        businessDays,
        businessHours,
        isMobile: detect == "mobile",
    }),
    computed: {
        _space() {
            return this.value?._id;
        },
        isCreate() {
            return !this._space;
        },
    },
    watch: {
        shows() {
            if (this.shows) this.init();
        },
        "inputs.area.width"() {
            const { width = 0, height = 0 } = this.inputs.area;
            this.inputs.area.square = width * height || 0;
        },
        "inputs.area.height"() {
            const { width = 0, height = 0 } = this.inputs.area;
            this.inputs.area.square = width * height || 0;
        },
    },
    methods: {
        async init() {
            this.loading = true;
            try {
                if (this.isCreate) {
                    this.inputs = { ...inintRentalSpace() };
                } else {
                    let { space } = await api.console.rentalSpaces.get({ _id: this._space });

                    space.thumb = space.thumb?.url ? await api.getResource(space?.thumb.url, true) : null;
                    space.photos = space.photos?.length ? await Promise.all(space.photos.map(async ({ url }) => await api.getResource(url, true))) : [];
                    space.holidays = space.holidays.map((date) => date.toDate());

                    this.inputs = space;
                }
            } catch (error) {
                this.$handleError(error);
            } finally {
                this.loading = false;
            }
        },

        validate() {},

        async save() {
            if (this.loading) return;

            this.loading = true;
            try {
                this.validate();

                let { _thumb, thumb, _photos = [], photos = [], ...inputs } = this.inputs;

                // POST/PUT space
                let { post, put } = api.console.rentalSpaces;
                let { space } = await (this.isCreate ? post(inputs) : put(inputs));
                let _space = space?._id;

                // DELETE/POST files

                // thumb
                if (_thumb) await api.console.rentalSpaces.thumb.delete({ _id: _thumb, _space });
                if (thumb) _thumb = (await api.console.rentalSpaces.thumb.post({ _space }, thumb))?.thumb?._id;
                else _thumb = null;

                // photos
                for (let _id of _photos) {
                    await api.console.rentalSpaces.photos.delete({ _id, _space });
                }
                for (let [index, photo] of photos.entries()) {
                    photos[index] = (await api.console.rentalSpaces.photos.post({ _space, index }, photo))?.photo;
                }
                if (photos.length) _photos = photos.map(({ _id }) => _id);
                else _photos = [];

                // PUT _units @space
                space = (await put({ _id: _space, _thumb, _photos }))?.space;

                alert("저장되었습니다");
                if (this.isCreate) this.$emit("created");
                else this.$emit("input", space);
                this.shows = false;
            } catch (error) {
                this.$handleError(error);
            } finally {
                this.loading = false;
            }
        },
    },
};
</script>
