<template>
    <v-select v-bind="{ ...$attrs, value, items }" @input="(shows) => $router.push({ query: { ...$route.query, page: undefined, shows: shows == null ? undefined : shows } })" />
</template>

<script>
const items = [
    { text: "전체", value: null },
    { text: "사용자 노출", value: true },
    { text: "사용자 미노출", value: false },
];
export default {
    props: { value: [Boolean] },
    data: () => ({ items }),
};
</script>

<style></style>
