<template>
    <v-data-table v-bind="{ headers, items }" disable-sort disable-filtering hide-default-footer :items-per-page="-1">
        <template v-for="header in headers.filter((header) => header.hasOwnProperty('formatter'))" v-slot:[`item.${header.value}`]="{ value }"> {{ header.formatter(value) }} </template>
        <template #[`item.date`]="{ item }">
            <v-edit-dialog :return-value.sync="item.date" large cancel-text="취소" save-text="저장" @save="update(item)">
                {{ item.date }}
                <template #input>
                    <div class="mx-n4"><v-date-picker v-model="item.date" show-adjacent-months :day-format="(day) => dayjs(day).date()" /> <v-divider /></div>
                </template>
            </v-edit-dialog>
        </template>
        <template #[`item.startsAt`]="{ item }">
            <v-edit-dialog :return-value.sync="item.startsAt" large cancel-text="취소" save-text="저장" @save="update(item)">
                {{ item.startsAt }}
                <template #input>
                    <div class="mx-n4"><v-time-picker v-model="item.startsAt" ampm-in-title /> <v-divider /></div>
                </template>
            </v-edit-dialog>
        </template>
        <template #[`item.endsAt`]="{ item }">
            <v-edit-dialog :return-value.sync="item.endsAt" large cancel-text="취소" save-text="저장" @save="update(item)">
                {{ item.endsAt }}
                <template #input>
                    <div class="mx-n4"><v-time-picker v-model="item.endsAt" ampm-in-title /> <v-divider /></div>
                </template>
            </v-edit-dialog>
        </template>
        <template #[`item.actions`]="{ item }">
            <v-btn text icon tile color="secondary" :disabled="item.isUpDisabled" @click="up(item)"><v-icon>mdi-chevron-up</v-icon></v-btn>
            <v-btn text icon tile color="secondary" :disabled="item.isDownDisabled" @click="down(item)"><v-icon>mdi-chevron-down</v-icon></v-btn>
            <v-btn text icon tile color="red" @click="pull(item)"><v-icon>mdi-minus</v-icon></v-btn>
        </template>
        <template #footer>
            <v-divider />
            <v-row class="mx-0">
                <v-col cols="auto" class="px-4 caption">* 진행된 회차를 삭제할 시 데이터가 유실될 수 있으며, 한 번 유실된 데이터는 복구할 수 없습니다.</v-col> <v-spacer />
                <v-col cols="auto" class="px-4 py-0" align-self="center">
                    <v-btn text icon tile color="primary" @click="push"><v-icon>mdi-plus</v-icon></v-btn>
                </v-col>
            </v-row>
        </template>
    </v-data-table>
</template>

<script>
import dayjs from "dayjs";
import api from "@/api";
const days = ["일", "월", "화", "수", "목", "금", "토"];
const headers = [
    { width: +70, text: "#", value: "index", formatter: (value) => `${value}회차` },
    { width: 110, text: "날짜", value: "date" },
    { width: +60, text: "요일", value: "day", align: "center", formatter: (key) => days[key] },
    { width: +90, text: "시작시간", value: "startsAt", align: "center" },
    { width: +90, text: "종료시간", value: "endsAt", align: "center" },
    { text: "", value: "actions", align: "right" },
];
export default {
    props: {
        value: { type: Array, default: () => [] }, // units
    },
    data: () => ({
        units: [],
        headers,
        dayjs,
    }),
    computed: {
        items() {
            return this.units.map((item, index) => ({
                ...item,
                index: index + 1,
                day: dayjs(item?.date).day(),
                isUpDisabled: index == 0,
                isDownDisabled: index == this.units.length - 1,
            }));
        },
        itemsToDelete() {
            return (this.value || []).filter(({ _id }) => !(this.items || []).some((item) => item?._id == _id));
        },
        itemsToUpdate() {
            return this.items.map(({ _id, _program, date, endsAt, startsAt }) => {
                const original = (this.value || []).find((item) => _id == item._id);
                const dateNotSame = !dayjs(date).isSame(original?.date, "date");
                const endsAtNotSame = endsAt != dayjs(original?.endsAt).format("HH:mm");
                const startsAtNotSame = startsAt != dayjs(original?.startsAt).format("HH:mm");

                const toPost = !_id;
                const toPut = !toPost && !!original && (dateNotSame || endsAtNotSame || startsAtNotSame);

                return {
                    _id,
                    _program,

                    date,
                    endsAt: dayjs(date)
                        .set("hour", endsAt.split(":")[0])
                        .set("minute", endsAt.split(":")[1])
                        .toDate(),
                    startsAt: dayjs(date)
                        .set("hour", startsAt.split(":")[0])
                        .set("minute", startsAt.split(":")[1])
                        .toDate(),

                    toPost,
                    toPut,
                };
            });
        },
    },
    mounted() {
        this.sync();
    },
    watch: {
        value() {
            this.sync();
        },
    },
    methods: {
        sync() {
            this.units = (this.value ? JSON.parse(JSON.stringify(this.value)) : []).map((item) => ({
                ...item,
                tempId: Math.random(),
                date: item.date.toDate(),
                endsAt: dayjs(item.endsAt).format("HH:mm"),
                startsAt: dayjs(item.startsAt).format("HH:mm"),
            }));
        },
        push() {
            let { date, startsAt, endsAt } = [...this.units].pop() || {
                date: Date.now().toDate(),
                startsAt: dayjs()
                    .startOf("hour")
                    .format("HH:mm"),
                endsAt: dayjs()
                    .startOf("hour")
                    .add(1, "hour")
                    .format("HH:mm"),
            };

            date = dayjs(date)
                .add(1, "day")
                .format("YYYY-MM-DD");

            this.units.push({ tempId: Math.random(), date, startsAt, endsAt });
        },
        pull(item) {
            const index = this.units.findIndex(({ tempId }) => tempId == item.tempId);
            this.units.splice(index, 1);
        },
        update(item) {
            const index = this.units.findIndex(({ tempId }) => tempId == item.tempId);
            this.units.splice(index, 1, item);
        },
        up(item) {
            const index = this.units.findIndex(({ tempId }) => tempId == item.tempId);
            this.units.splice(index - 1, 2, this.units[index], this.units[index - 1]);
        },
        down(item) {
            const index = this.units.findIndex(({ tempId }) => tempId == item.tempId);
            this.units.splice(index, 2, this.units[index + 1], this.units[index]);
        },
        async save({ _program }) {
            const { post, put, delete: remove } = api.console.programs.units;

            if (this.itemsToDelete.length > 0) await Promise.all(this.itemsToDelete.map(async ({ _id, _program }) => await remove({ _id, _program })));

            const units = await Promise.all(this.itemsToUpdate.map(async (item) => (item.toPost ? (await post({ ...item, _program }))?.unit || {} : item.toPut ? (await put(item))?.unit || {} : item)));
            const _units = units.map(({ _id }) => _id);

            return { _units, units };
        },
    },
};
</script>
