<template>
    <v-dialog v-model="shows" width="100%" max-width="640">
        <template #activator="{ attrs, on }">
            <slot name="activator" v-bind="{ attrs, on }" />
        </template>
        <v-card>
            <v-card-title>프로그램 승인대기 목록 <v-spacer /> <v-icon @click="shows = false">mdi-close</v-icon></v-card-title>
            <v-divider />
            <v-data-table v-bind="{ items, headers }" hide-default-footer disable-filtering disable-pagination disable-sort :items-per-page="-1">
                <template v-for="header in headers.filter((header) => header.hasOwnProperty('formatter'))" v-slot:[`item.${header.value}`]="{ value }"> {{ header.formatter(value) }} </template>
                <template #[`item.actions`]="{ item }">
                    <v-btn text icon :to="`/console/programs/${item._program}/applications/${item._id}`">
                        <v-icon>mdi-view-list</v-icon>
                    </v-btn>
                </template>
            </v-data-table>
            <v-divider />
            <v-pagination v-model="page" :length="pageCount" :total-visible="11" @input="search" />
        </v-card>
    </v-dialog>
</template>

<script>
import api from "@/api";
import { PROGRAM_APPLICATION_STATES } from "@/assets/variables";

const headers = [
    { width: 170, text: "신청일", value: "appliedAt", formatter: (value) => value?.toDateTime?.() || value },
    { width: 100, text: "회원번호", value: "user.number" },
    { width: 140, text: "아이디", value: "user.username" },
    { width: 140, text: "이름", value: "user.name" },
    { width: +90, text: "", value: "actions" },
].map((item, index, array) => ({ ...item, formatter: item.formatter ?? ((value) => value ?? "-"), divider: index != array.length - 1 }));

export default {
    data: () => ({
        applications: [],
        summary: { totalCount: 0 },

        page: 1,
        limit: 10,
        filter: { state: PROGRAM_APPLICATION_STATES.APPLIED.value },

        headers,

        shows: false,
        loading: false,
    }),
    computed: {
        items() {
            return [...this.applications];
        },
        skip() {
            return (this.page - 1) * this.limit;
        },
        pageCount() {
            return Math.ceil(this.summary.totalCount / this.limit) || 1;
        },
        params() {
            let { ...filter } = this.filter;
            return { ...filter };
        },
    },
    mounted() {
        this.search();
    },
    watch: {
        page() {
            this.search();
        },
    },
    methods: {
        async search() {
            if (this.loading) return;
            else this.loading = true;
            try {
                let { skip, limit, params } = this;
                let { summary, applications } = await api.console.programs.applications.gets({ headers: { skip, limit }, _program: undefined, params });
                this.summary = summary;
                this.applications = applications;
            } finally {
                this.loading = false;
            }
        },
    },
};
</script>

<style>
</style>