<template>
    <v-dialog v-model="shows" :fullscreen="$vuetify.breakpoint.xsOnly" :max-width="breakpoint ? '480' : '664'" transition="fade-transition">
        <template #activator="props">
            <slot name="activator" v-bind="props" />
        </template>
        <v-sheet color="content" height="100%" style="position:relative">
            <v-card-title class="dark secondary white--text">
                {{ program.name }} - {{ number }}회차 출석체크 <v-spacer />
                <v-btn dark text icon @click="shows = !shows"> <v-icon>mdi-close</v-icon> </v-btn>
            </v-card-title>
            <v-row class="ma-0" justify="center">
                <v-col cols="12" lg="auto">
                    <v-card flat rounded="xl" class="fill-height" style="min-width:320px; min-height:240px; overflow: hidden">
                        <v-responsive :aspect-ratio="1 / 1" :max-width="breakpoint ? undefined : 320" :max-height="breakpoint ? undefined : 320">
                            <qrcode-stream-reader v-if="!isFullscreenMode" v-model="decodedNumber" v-bind="{ propCamera }" @changeCamera="(camera) => (propCamera = camera)">
                                <message-snackbar v-bind="{ message }" absolute bottom tile @close="message = null" />
                                <v-btn slot="button" fab elevation="4" class="mb-3" @click="isFullscreenMode = !isFullscreenMode"> <v-icon large>mdi-fullscreen</v-icon> </v-btn>
                            </qrcode-stream-reader>
                        </v-responsive>
                    </v-card>
                </v-col>
                <v-col cols="12" lg="auto" style="min-width:320px;min-height:240px">
                    <v-data-table v-bind="{ items, headers }" disable-filtering disable-pagination disable-sort hide-default-footer :items-per-page="-1" class="fill-height rounded-xl">
                        <template v-for="header in headers.filter((header) => header.hasOwnProperty('formatter'))" #[`item.${header.value}`]="{ value }"> {{ header.formatter(value) }} </template>
                        <template #no-data>출석기록이 없습니다</template>
                    </v-data-table>
                </v-col>
            </v-row>
            <v-fade-transition>
                <v-overlay v-show="isFullscreenMode" :dark="false" color="white" opacity="1">
                    <v-responsive height="100vh" width="100vw">
                        <qrcode-stream-reader v-if="isFullscreenMode" v-model="decodedNumber" v-bind="{ propCamera }" @changeCamera="(camera) => (propCamera = camera)">
                            <message-snackbar v-bind="{ message }" absolute bottom tile @close="message = null" />
                            <v-btn slot="button" fab elevation="4" class="mb-3" @click="isFullscreenMode = !isFullscreenMode"> <v-icon large>mdi-fullscreen-exit</v-icon> </v-btn>
                        </qrcode-stream-reader>
                    </v-responsive>
                </v-overlay>
            </v-fade-transition>
        </v-sheet>
    </v-dialog>
</template>

<script>
import MessageSnackbar from "@/components/dumb/message-snackbar.vue";
import QrcodeStreamReader from "@/components/dumb/qrcode-stream-reader.vue";

let headers = [
    { text: "회원번호", value: "user.number" },
    { text: "출석시간", value: "attendedAt", align: "right", formatter: (value) => value.toDateTime().split(" ")[1] },
];

export default {
    components: {
        MessageSnackbar,
        QrcodeStreamReader,
    },
    props: {
        number: { type: Number, default: null },
        program: { type: Object, default: () => ({}) },
        unit: { type: Object, default: () => ({}) },
        users: { type: Array, default: () => [] },
        attendances: { type: Array, default: () => [] },
    },
    data: () => ({
        shows: false,
        headers,
        message: null,
        propCamera: "auto",
        decodedNumber: null,
        isFullscreenMode: false,
    }),
    computed: {
        items() {
            return this.attendances;
        },
        breakpoint() {
            return this.$vuetify.breakpoint.mdAndDown;
        },
    },
    watch: {
        decodedNumber(number) {
            if (!number) return;
            setTimeout(() => {
                console.log(number, this.decodedNumber);
                if (number == this.decodedNumber) {
                    this.message = null;
                    this.decodedNumber = null;
                }
            }, 3000);
            let { _id: _user } = this.users.find((user) => user.number == number) || {};
            if (_user) {
                let attendnace = this.attendances.find((attendance) => attendance._user == _user);
                if (attendnace) {
                    this.message = `이미 출석하셨습니다.\n회원번호: ${number}`;
                    return;
                }
                let {
                    unit: { _id: _unit },
                    program: { _id: _program },
                } = this;
                this.$emit("postAttendance", { _program, _unit, _user });
            } else {
                this.message = `수강중인 프로그램이 아닙니다.\n회원번호: ${number}`;
            }
        },
        "attendances.length"() {
            if (this.shows) this.message = `${this.attendances[0].user.name}님\n출석 처리되었습니다.`;
        },
    },
};
</script>

<style></style>
