<template></template>

<script>
import api from "@/api";
import dayjs from "dayjs";
import { RENTAL_LIMITATION_TYPES, RENTAL_SCHEDULE_STATES } from "@/assets/variables";

export default {
    props: {
        date: { type: String },
        _user: { type: String },
        schedules: { type: Array, default: () => [] },
        limitations: { type: Array, default: () => [] },
    },
    data: () => ({
        scheduleCountOnCloud: 0,
    }),
    computed: {
        limitation() {
            return this.limitations.find(({ type }) => type == RENTAL_LIMITATION_TYPES.PER_WEEK.value) || null;
        },
        startDate() {
            if (this.limitation) {
                let { weekStartDay } = this.limitation;
                let startDate = dayjs(this.date).toISOString();
                while (dayjs(startDate).day() != weekStartDay) {
                    startDate = dayjs(startDate)
                        .subtract(1, "day")
                        .toISOString();
                }
                return startDate;
            } else return null;
        },
        endDate() {
            if (this.startDate) {
                return dayjs(this.startDate)
                    .add(6, "days")
                    .toISOString();
            } else return null;
        },
        scheduleCount() {
            if (this.limitation) {
                return this.schedules.reduce((count, { date }) => {
                    let isAfterStart = !dayjs(date).isBefore(this.startDate, "date");
                    let isBeforeEnd = !dayjs(date).isAfter(this.endDate, "date");
                    if (isAfterStart && isBeforeEnd) count += 1;
                    return count;
                }, 0);
            } else return 0;
        },
        limitationMet() {
            if (this.limitation) {
                let { hours } = this.limitation;
                if (hours <= this.scheduleCount + this.scheduleCountOnCloud) return true;
            }
            return false;
        },
    },
    mounted() {
        this.getUserScheduleCount().then(this.emit);
    },
    watch: {
        date() {
            this.getUserScheduleCount();
        },
        _user() {
            this.getUserScheduleCount();
        },
        limitation() {
            this.getUserScheduleCount();
        },
        limitationMet() {
            this.emit();
        },
    },
    methods: {
        async getUserScheduleCount() {
            let { _user } = this;
            if (this.limitation && _user) {
                let dates = [this.startDate, this.endDate];
                let { APPLIED, APPROVED } = RENTAL_SCHEDULE_STATES;
                let { summary } = await api.console.rentalSchedules.gets({
                    params: { _user, dates, state: [APPLIED.value, APPROVED.value], onlyCount: true },
                });
                this.scheduleCountOnCloud = summary?.totalCount || 0;
            }
        },
        emit() {
            if (this.value != this.limitationMet) this.$emit("input", this.limitationMet);
        },
    },
};
</script>

<style></style>
