<template>
    <v-layout v-if="setting" justify-center>
        <v-responsive max-width="680" width="100%" class="mx-n3 px-3">
            <list-heading v-model="showsSearch" title="SMTP 변경" />

            <v-card outlined>
                <v-card-text class="py-3">
                    <v-row>
                        <v-col cols="12" sm="6"> <v-text-field v-model="setting.smtp.username" label="발신자 아이디 (네이버)" dense outlined persistent-placeholder hide-details /> </v-col>
                        <v-col cols="12" sm="6"> <v-text-field v-model="setting.smtp.password" type="password" label="비밀번호" dense outlined persistent-placeholder hide-details /> </v-col>
                    </v-row>
                </v-card-text>
                <v-divider />
                <v-card-actions class="justify-end"> <v-btn text color="primary" @click="save"> 변경하기 </v-btn> </v-card-actions>
            </v-card>
            <v-col class="primary--text caption"> ※ "Naver 메일 -> 환경설정 -> POP3/IMAP 설정 -> IMAP/SMTP 설정 -> IMAP/SMTP 사용" 을 사용함으로 변경 </v-col>
        </v-responsive>
    </v-layout>
</template>

<script>
import api from "@/api";
import CryptoAes from "@/plugins/crypto-aes";

import ListHeading from "@/components/console/dumb/list-heading.vue";
import VPasswordField from "@/components/plugins/vuetify/v-password-field.vue";

export default {
    components: {
        ListHeading,
        VPasswordField,
    },
    created() {
        this.init();
    },
    data() {
        return {
            setting: null,
        };
    },
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            await this.search();
        },

        async search() {
            var { setting } = await api.console.setting.get();
            this.setting = setting;
        },

        async save() {
            const setting = JSON.parse(JSON.stringify(this.setting));
            if (setting?.smtp?.password) setting.smtp.password = CryptoAes.encrypt(setting.smtp.password);
            await api.console.setting.put(setting);

            alert("저장되었습니다.");
        },
    },
};
</script>
