<template>
    <v-layout justify-center>
        <v-responsive max-width="1440" width="100%" height="100%" class="mx-n3 px-3">
            <list-heading title="프로그램 목록">
                <template #add-button>
                    <program-view @created="search">
                        <template #activator="{ attrs, on }">
                            <v-btn text icon tile color="primary" title="프로그램 생성" v-bind="attrs" v-on="on">
                                <v-icon>mdi-plus</v-icon>
                            </v-btn>
                        </template>
                    </program-view>
                </template>
            </list-heading>

            <program-list-filter />

            <v-card outlined class="mt-4" style="overflow:hidden">
                <v-data-table v-bind="{ headers, items }" disable-sort disable-pagination hide-default-footer :items-per-page="-1">
                    <template v-for="header in headers.filter((header) => header.hasOwnProperty('formatter'))" #[`item.${header.value}`]="{ value }"> {{ header.formatter(value) }} </template>
                    <template #[`item.thumb`]="{ value }">
                        <v-img v-if="value" v-bind="value" :aspect-ratio="1 / 1" width="40" class="rounded-lg v-sheet--outlined" />
                        <template v-else> - </template>
                    </template>
                    <template #[`item.hidden`]="{ item }">
                        <v-row no-gutters justify="center">
                        <v-col cols="auto">
                            <v-switch v-model="item.hidden" v-bind="switchAttrs" class="ml-1 mr-n1" style="width: 48px" @change="save(item)" />
                        </v-col>
                    </v-row>
                    </template>
                    <template #[`item.actions`]="{ item }">
                        <v-btn :to="`/console/programs/${item._id}`" class="pa-0" text icon width="24">
                            <v-icon> mdi-eye </v-icon>
                        </v-btn>
                        <program-view :value="item" @input="(item) => update(item)">
                            <template #activator="{ attrs, on }">
                                <v-icon v-bind="attrs" v-on="on"> mdi-pencil </v-icon>
                            </template>
                        </program-view>
                        <v-icon @click="remove(item)"> mdi-delete </v-icon>
                    </template>
                </v-data-table>
            </v-card>
            <v-pagination :value="page" :length="pageCount" :total-visible="11" class="mt-4 mx-4" @input="(page) => $router.push({ query: { ...$route.query, page } })"></v-pagination>
        </v-responsive>
    </v-layout>
</template>

<script>
import api from "@/api";

import { switchAttrs } from "@/assets/variables";
import ListHeading from "@/components/console/dumb/list-heading.vue";
import ProgramView from "@/components/console/program/program-view.vue";
import ProgramListFilter from "@/components/console/program/program-list-filter.vue";

const defaultFormatter = (value) => value || "-";
const headers = [
    { text: "번호", value: "index" },
    { text: "카테고리", value: "category.name" },
    { text: "이미지", value: "thumb" },
    { text: "제목", value: "name" },
    { text: "상태", value: "status" },
    { text: "인원", value: "capacity" },
    { text: "신청자", value: "_applications.length", formatter: (value) => value || 0 },
    { text: "참여자", value: "_attendees.length", formatter: (value) => value || 0 },
    { text: "접수기간", value: "applicationPeriod" },
    { text: "진행기간", value: "period" },
    { text: "미노출", value: "hidden" },
    { text: "", value: "actions", align: "right", width: 104 },
].map((item) => ({ ...item, formatter: item?.formatter || defaultFormatter }));

export default {
    components: {
        ListHeading,
        ProgramView,
        ProgramListFilter,
    },
    data() {
        return {
            switchAttrs,
            programs: [],

            limit: 10,
            summary: { totalCount: 0 },

            headers,
        };
    },
    computed: {
        items() {
            return this.programs.map((item, index) => ({ ...item, index: this.summary.totalCount - (this.page - 1) * this.limit - index }));
        },
        page() {
            return +(this.$route.query.page || "1");
        },
        skip() {
            return (this.page - 1) * this.limit;
        },
        pageCount() {
            return Math.ceil(this.summary.totalCount / this.limit) || 1;
        },
        params() {
            let { query } = this.$route;
            return { ...query };
        },
    },
    mounted() {
        this.init();
    },
    watch: {
        params() {
            this.search();
        },
    },
    methods: {
        async init() {
            try {
                this.search();
            } catch (error) {
                console.error(error);
            }
        },

        async search() {
            try {
                let { skip, limit, params } = this;
                var { summary, programs } = await api.console.programs.gets({
                    headers: { skip, limit },
                    params,
                });

                this.programs = programs;
                this.summary = summary;
            } catch (error) {
                console.error(error);
                if (error.response) alert(error.response.data.email);
                else alert(error.email.toString());
            }
        },

        async update(item) {
            console.log(item);
            const index = this.programs.findIndex(({ _id }) => _id == item._id);
            if (index == -1) this.search();
            else this.programs.splice(index, 1, item);
        },

        async save(item) {
            const { space } = await api.console.programs.put(item);
            this.update(space);
        },

        async remove(item) {
            const go = confirm(`다음 프로그램을 삭제하시겠습니까?\n\n프로그램명: ${item?.name}`);
            if (!go) return;
            await api.console.programs.delete(item);
            this.search();
        },
    },
};
</script>
