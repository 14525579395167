var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('statistics-graph-card', {
    attrs: {
      "label": "성비"
    }
  }, [_c('div', {
    staticClass: "d-flex mb-n2",
    staticStyle: {
      "width": "100%",
      "height": "268px",
      "overflow-x": "auto"
    }
  }, _vm._l(_vm.items, function (program) {
    return _c('program-user-genders-item', _vm._b({
      key: program._id
    }, 'program-user-genders-item', {
      program,
      summary: program.summary
    }, false));
  }), 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }