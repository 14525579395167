<template>
    <v-data-table v-bind="{ items, headers }" hide-default-footer disable-filtering disable-sort disable-pagination :items-per-page="-1">
        <template v-for="header in headers.filter((header) => header.hasOwnProperty('formatter'))" #[`item.${header.value}`]="{ value }"> {{ header.formatter(value) }} </template>

        <template #[`item.attendances`]="{ value }"> {{ value.length }} / {{ units.length }} </template>

        <template #[`item.actions`]="{ item }">
            <program-attendee-attendances v-bind="{ ...item, units }">
                <template #activator="{ attrs, on }">
                    <v-btn text icon tile v-bind="attrs" v-on="on"> <v-icon>mdi-eye</v-icon> </v-btn>
                </template>
            </program-attendee-attendances>
        </template>
    </v-data-table>
</template>

<script>
import api from "@/api";

import ProgramAttendeeAttendances from "./program-attendee-attendances.vue";

let headers = [
    { width: 100, text: "#", value: "user.number", align: "right" },
    { width: 140, text: "아이디", value: "user.username", align: "center" },
    { width: 140, text: "이름", value: "user.name", align: "center" },
    { width: +80, text: "성별", value: "user.gender", align: "center" },
    { width: +80, text: "나이", value: "user.age", align: "center" },
    { width: 140, text: "전화번호", value: "user.phone", align: "center" },
    { width: 100, text: "출석현황", value: "attendances", align: "center" },
    { width: +80, text: "", value: "actions", align: "right" },
];

export default {
    components: {
        ProgramAttendeeAttendances,
    },
    props: {
        program: { type: Object, default: () => ({}) },
    },
    data: () => ({
        users: [],
        attendances: [],

        headers,
    }),
    computed: {
        items() {
            return this.users.map((user) => ({ user, attendances: this.attendances.filter(({ _user }) => _user == user?._id) || [] }));
        },
        units() {
            return this.program?.units || [];
        },
    },
    mounted() {
        this.init();
    },
    watch: {
        "program._attendees"() {
            this.init();
        },
    },
    methods: {
        async init() {
            let { _attendees: _user, _id: _program } = this.program || {};
            if (_user.length) {
                let { users } = await api.console.users.gets({ params: { _user } });
                this.users = users;
            }
            if (_program) {
                let { attendances } = await api.console.programs.attendances.gets({ _program });
                this.attendances = attendances;
            }
        },
    },
};
</script>
