var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-dialog', {
    attrs: {
      "width": "100%",
      "max-width": "640"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var attrs = _ref.attrs,
          on = _ref.on;
        return [_vm._t("activator", null, null, {
          attrs,
          on
        })];
      }
    }], null, true),
    model: {
      value: _vm.shows,
      callback: function ($$v) {
        _vm.shows = $$v;
      },
      expression: "shows"
    }
  }, [_c('v-card', [_c('v-card-title', [_vm._v("프로그램 승인대기 목록 "), _c('v-spacer'), _vm._v(" "), _c('v-icon', {
    on: {
      "click": function ($event) {
        _vm.shows = false;
      }
    }
  }, [_vm._v("mdi-close")])], 1), _c('v-divider'), _c('v-data-table', _vm._b({
    attrs: {
      "hide-default-footer": "",
      "disable-filtering": "",
      "disable-pagination": "",
      "disable-sort": "",
      "items-per-page": -1
    },
    scopedSlots: _vm._u([_vm._l(_vm.headers.filter(function (header) {
      return header.hasOwnProperty('formatter');
    }), function (header) {
      return {
        key: `item.${header.value}`,
        fn: function (_ref2) {
          var value = _ref2.value;
          return [_vm._v(" " + _vm._s(header.formatter(value)) + " ")];
        }
      };
    }), {
      key: `item.actions`,
      fn: function (_ref3) {
        var item = _ref3.item;
        return [_c('v-btn', {
          attrs: {
            "text": "",
            "icon": "",
            "to": `/console/programs/${item._program}/applications/${item._id}`
          }
        }, [_c('v-icon', [_vm._v("mdi-view-list")])], 1)];
      }
    }], null, true)
  }, 'v-data-table', {
    items: _vm.items,
    headers: _vm.headers
  }, false)), _c('v-divider'), _c('v-pagination', {
    attrs: {
      "length": _vm.pageCount,
      "total-visible": 11
    },
    on: {
      "input": _vm.search
    },
    model: {
      value: _vm.page,
      callback: function ($$v) {
        _vm.page = $$v;
      },
      expression: "page"
    }
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }