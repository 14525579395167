var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-layout', {
    attrs: {
      "justify-center": ""
    }
  }, [_c('v-responsive', {
    staticClass: "mx-n3 px-3",
    attrs: {
      "max-width": "480",
      "width": "100%",
      "height": "100%"
    }
  }, [_c('list-heading', {
    attrs: {
      "title": "프로그램 키워드 목록"
    },
    scopedSlots: _vm._u([{
      key: "add-button",
      fn: function () {
        return [_c('program-keyword-view', {
          on: {
            "created": function ($event) {
              return _vm.search();
            }
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function (_ref) {
              var attrs = _ref.attrs,
                on = _ref.on;
              return [_c('v-btn', _vm._g(_vm._b({
                attrs: {
                  "text": "",
                  "icon": "",
                  "tile": "",
                  "color": "primary",
                  "title": "프로그램 키워드 생성"
                }
              }, 'v-btn', attrs, false), on), [_c('v-icon', [_vm._v("mdi-plus")])], 1)];
            }
          }])
        })];
      },
      proxy: true
    }])
  }), _c('v-card', {
    staticStyle: {
      "overflow": "hidden"
    },
    attrs: {
      "outlined": ""
    }
  }, [_c('v-data-table', _vm._b({
    attrs: {
      "disable-sort": "",
      "disable-pagination": "",
      "hide-default-footer": "",
      "items-per-page": -1
    },
    scopedSlots: _vm._u([_vm._l(_vm.headers.filter(function (header) {
      return header.hasOwnProperty('formatter');
    }), function (header) {
      return {
        key: `item.${header.value}`,
        fn: function (_ref2) {
          var value = _ref2.value;
          return [_vm._v(" " + _vm._s(header.formatter(value)) + " ")];
        }
      };
    }), {
      key: `item.shows`,
      fn: function (_ref3) {
        var item = _ref3.item,
          value = _ref3.value;
        return [_c('v-row', {
          attrs: {
            "justify": "center",
            "align": "center"
          }
        }, [_c('v-col', {
          staticClass: "pa-0",
          attrs: {
            "cols": "auto"
          }
        }, [_c('v-switch', {
          staticClass: "ma-0 ml-1 mr-n3 pa-0",
          attrs: {
            "readonly": "",
            "inset": "",
            "hide-details": ""
          },
          on: {
            "click": function ($event) {
              return _vm.save({
                _id: item._id,
                shows: !value
              });
            }
          },
          model: {
            value: item.shows,
            callback: function ($$v) {
              _vm.$set(item, "shows", $$v);
            },
            expression: "item.shows"
          }
        })], 1)], 1)];
      }
    }, {
      key: `item.actions`,
      fn: function (_ref4) {
        var item = _ref4.item;
        return [_c('program-keyword-view', {
          attrs: {
            "value": item
          },
          on: {
            "input": function (item) {
              return _vm.update(item);
            }
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function (_ref5) {
              var attrs = _ref5.attrs,
                on = _ref5.on;
              return [_c('v-icon', _vm._g(_vm._b({
                attrs: {
                  "small": ""
                }
              }, 'v-icon', attrs, false), on), [_vm._v(" mdi-pencil ")])];
            }
          }], null, true)
        }), _c('v-icon', {
          attrs: {
            "small": ""
          },
          on: {
            "click": function ($event) {
              return _vm.remove(item);
            }
          }
        }, [_vm._v(" mdi-delete ")])];
      }
    }], null, true)
  }, 'v-data-table', {
    headers: _vm.headers,
    items: _vm.items
  }, false))], 1), _c('v-pagination', {
    staticClass: "mt-4 mx-4",
    attrs: {
      "length": _vm.pageCount,
      "total-visible": 11
    },
    on: {
      "input": function ($event) {
        return _vm.search();
      }
    },
    model: {
      value: _vm.page,
      callback: function ($$v) {
        _vm.page = $$v;
      },
      expression: "page"
    }
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }