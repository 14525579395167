var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-layout', {
    attrs: {
      "justify-center": ""
    }
  }, [_c('v-responsive', {
    staticClass: "mx-n3 px-3",
    attrs: {
      "max-width": "1024px",
      "width": "100%",
      "height": "100%"
    }
  }, [_c('list-heading', _vm._b({}, 'list-heading', {
    title: _vm.title
  }, false)), _c('user-view-info', _vm._b({}, 'user-view-info', {
    _user: _vm._user
  }, false)), _c('user-view-programs', _vm._b({
    staticClass: "mt-4"
  }, 'user-view-programs', {
    _user: _vm._user
  }, false))], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }