<template>
    <v-sheet v-bind="$attrs">
        <v-row justify="space-between" align="center">
            <v-col cols="3">
                <v-btn small text color="grey" @click="$emit('close')" height="32"> <v-icon>mdi-chevron-left</v-icon> 뒤로가기 </v-btn>
            </v-col>
            <v-col cols="auto">{{ title }} </v-col>
            <v-col cols="3" />
        </v-row>
        <div>
            <v-calendar v-model="date" type="day" v-bind="{ events }" first-interval="8" interval-count="13" hide-header @click:event="({ event: { to } }) => (to ? $router.push(to) : null)" />
        </div>
    </v-sheet>
</template>

<script>
import dayjs from "dayjs";

export default {
    props: {
        date: { type: String, default: Date.now().toDate() },
        events: { type: Array, default: () => [] },
    },
    computed: {
        title() {
            return `${dayjs(this.date).month() + 1}월 ${dayjs(this.date).date()}일`;
        },
    },
};
</script>

<style lang="scss" scoped>
::v-deep {
    .v-calendar-daily__day-container {
        margin-top: -12px;
    }
    .v-calendar-daily__scroll-area {
        overflow-y: auto;
    }
    .v-calendar-daily__interval:last-of-type {
        border-bottom: 1px solid #e0e0e0;
    }
}
</style>
