<template>
    <v-layout justify-center>
        <v-responsive max-width="1280" width="100%">
            <list-heading title="1:1문의" v-model="showsSearch" showsFilterButton />

            <v-expand-transition>
                <v-card v-show="showsSearch" outlined class="mx-auto">
                    <v-card-text>
                        <v-row class="mt-3">
                            <v-col cols="12" sm="6" class="py-0">
                                <v-menu ref="createdAts0" :close-on-content-click="false" :return-value.sync="filter.createdAts[0]" transition="scale-transition" offset-y min-width="290px">
                                    <template v-slot:activator="{ on }">
                                        <v-text-field v-model="filter.createdAts[0]" label="작성일자(시작)" persistent-placeholder dense hide-details append-icon="event" readonly v-on="on"></v-text-field>
                                    </template>
                                    <v-date-picker v-model="filter.createdAts[0]" no-title scrollable @input="$refs.createdAts0.save(filter.createdAts[0])"></v-date-picker>
                                </v-menu>
                            </v-col>

                            <v-col cols="12" sm="6" class="py-0">
                                <v-menu ref="createdAts1" :close-on-content-click="false" :return-value.sync="filter.createdAts[1]" transition="scale-transition" offset-y min-width="290px">
                                    <template v-slot:activator="{ on }">
                                        <v-text-field v-model="filter.createdAts[1]" label="작성일자(종료)" persistent-placeholder dense hide-details append-icon="event" readonly v-on="on"></v-text-field>
                                    </template>
                                    <v-date-picker v-model="filter.createdAts[1]" no-title scrollable @input="$refs.createdAts1.save(filter.createdAts[1])"></v-date-picker>
                                </v-menu>
                            </v-col>
                        </v-row>
                        <v-row class="mt-6">
                            <v-col class="pt-0 pb-0" cols="12" sm="6" md="3">
                                <v-select v-model="filter.reply" :items="filterItems" label="답변상태" persistent-placeholder dense hide-details item-text="text" item-valuie="value"></v-select>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" rounded text @click="search" dense><v-icon class="mr-2">mdi-magnify</v-icon>검색</v-btn>
                    </v-card-actions>
                </v-card>
            </v-expand-transition>

            <v-card outlined class="mt-4" style="overflow: hidden">
                <v-data-table :headers="questionsHeaders" :items="questions" disable-sort disable-pagination hide-default-footer>
                    <template #[`item.center`]="{ item }">
                        <div>{{ item.center || "전체" }}</div>
                    </template>
                    <template #[`item.user`]="{ item }">
                        <div v-if="item.user && item.user.companyName">{{ item.user.companyName.ko }}</div>
                    </template>

                    <template #[`item.createdAt`]="{ item }">
                        {{ item.createdAt.toDate() }}
                    </template>

                    <template #[`item.reply`]="{ item }">
                        <span v-if="item.reply">답변완료</span>
                        <span v-else>미답변</span>
                    </template>

                    <template #[`item.actions`]="{ item }">
                        <v-icon small @click="edit(item)">mdi-pencil</v-icon>
                    </template>
                </v-data-table>
            </v-card>
            <v-pagination v-model="page" :length="pageCount" :total-visible="11" class="mt-4 mb-12"></v-pagination>

            <v-dialog v-model="questionDialog" max-width="880">
                <v-card v-if="questionDialog">
                    <v-card-title> <span class="subtitle-1">문의상세</span> </v-card-title>
                    <v-divider />
                    <v-card-text class="px-6 py-3">
                        <v-row>
                            <v-col cols="6" md="3">
                                <v-text-field v-model="editItem.user.name" label="작성자" persistent-placeholder readonly dense hide-details outlined>{{ editItem }}</v-text-field>
                            </v-col>
                            <v-col cols="6" md="3"> <v-text-field label="작성일" persistent-placeholder readonly dense hide-details outlined :value="editItem.createdAt.toDate()"></v-text-field> </v-col>
                            <v-col cols="6" md="3">
                                <v-text-field v-model="editItem.user.phone" label="연락처" persistent-placeholder readonly dense hide-details outlined>{{ editItem }}</v-text-field>
                            </v-col>
                            <v-col cols="6" md="3">
                                <v-text-field v-model="editItem.user.email" label="이메일" persistent-placeholder readonly dense hide-details outlined>{{ editItem }}</v-text-field>
                            </v-col>
                            <v-col cols="12" md="3"> <v-text-field v-model="editItem.center" label="센터" placeholder="전체" persistent-placeholder readonly dense hide-details outlined></v-text-field> </v-col>
                            <v-col cols="12" md="9"> <v-text-field v-model="editItem.subject" label="문의 제목" persistent-placeholder readonly dense hide-details outlined></v-text-field> </v-col>
                            <v-col cols="12"> <v-textarea v-model="editItem.content" label="문의 내용" persistent-placeholder readonly dense hide-details outlined></v-textarea> </v-col>
                            <v-col cols="12" v-if="editItem?.files?.[0]">
                                첨부파일 : <v-chip label download :href="editItem?.files?.[0]?.path" outlined> <v-icon small class="mr-2">mdi-file</v-icon> {{ editItem?.files?.[0]?.name }}</v-chip>
                            </v-col>
                            <v-col cols="12"> <v-textarea v-model="editItem.reply" label="답변내용" persistent-placeholder dense hide-details outlined /> </v-col>
                        </v-row>
                    </v-card-text>
                    <v-divider />
                    <v-card-actions>
                        <v-col>
                            답변상태 :
                            <span v-if="editItem.reply">답변완료</span>
                            <span v-else>미답변</span>
                        </v-col>
                        <v-spacer></v-spacer>
                        <v-btn text @click="close">취소</v-btn>
                        <v-btn color="primary" text @click="save">저장</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-responsive>
    </v-layout>
</template>

<script>
import api from "@/api";
import ListHeading from "@/components/console/dumb/list-heading.vue";
export default {
    components: {
        ListHeading,
    },
    data: () => {
        return {
            ///////////////////////////////////////////////
            // 검색필터
            ///////////////////////////////////////////////
            showsSearch: true,

            filter: {
                createdAts: ["", ""],
                reply: "",
            },

            filterItems: [
                { text: "전체", value: "" },
                { text: "미답변", value: "unAnswered" },
                { text: "답변완료", value: "answered" },
            ],

            ///////////////////////////////////////////////
            // 상품문의 테이블 정보
            ///////////////////////////////////////////////
            page: 1,
            pageCount: 0,
            limit: 20,

            questions: [],
            questionsHeaders: [
                {
                    text: "센터",
                    align: "center",
                    value: "center",
                },
                {
                    text: "문의유형",
                    align: "center",
                    value: "type",
                },
                {
                    text: "회원아이디",
                    align: "center",
                    value: "user.username",
                },
                {
                    text: "문의제목",
                    align: "center",
                    value: "subject",
                },
                {
                    text: "작성일자",
                    align: "center",
                    width: 170,
                    value: "createdAt",
                },
                {
                    text: "답변상태",
                    align: "center",
                    width: 170,
                    value: "reply",
                },
                {
                    text: "Actions",
                    align: "center",
                    value: "actions",
                },
            ],

            ///////////////////////////////////////////////////
            //
            ///////////////////////////////////////////////////
            questionDialog: false,
            editItem: {},
        };
    },
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            try {
                await this.search();
            } catch (error) {
                console.error(error);
                alert(error.response ? error.response.data.message : error.message);
            }
        },

        async search() {
            try {
                var { summary, questions } = await api.console.center.questions.gets({
                    headers: {
                        skip: (this.page - 1) * this.limit,
                        limit: this.limit,
                    },
                    params: this.filter,
                });

                this.questions = questions;
                this.pageCount = Math.ceil(summary.totalCount / this.limit);
            } catch (error) {
                console.error(error);
                if (error.response) alert(error.response.data.message);
                else alert(error.message);
            }
        },

        edit(question) {
            this.editItem = Object.assign({}, question);
            this.questionDialog = true;
        },

        close() {
            this.questionDialog = false;
        },

        async save() {
            try {
                await api.console.center.questions.put(this.editItem);
                await this.search();
                alert("저장되었습니다");
                this.questionDialog = false;
            } catch (error) {
                console.error(error);
                if (error.response) alert(error.response.data.message);
                else alert(error.message);
            }
        },
    },
};
</script>
