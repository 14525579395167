var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-row', {
    staticClass: "mx-0 form-table-row",
    attrs: {
      "align": "stretch"
    }
  }, [_c('v-col', _vm._b({
    class: `subtitle-2 font-weight-bold ${_vm.color}`
  }, 'v-col', {
    cols: _vm.cols
  }, false), [_vm._v(_vm._s(_vm.label))]), _c('v-col', {
    staticClass: "pa-0",
    attrs: {
      "cols": "auto",
      "align-self": "stretch"
    }
  }, [_c('v-divider', {
    attrs: {
      "vertical": ""
    }
  })], 1), _c('v-col', _vm._b({
    staticClass: "py-0",
    attrs: {
      "align-self": "center"
    }
  }, 'v-col', {
    class: _vm.$attrs.contentClass
  }, false), [_vm._t("default")], 2)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }