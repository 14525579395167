<template>
    <v-card min-height="200" v-bind="{ loading }" outlined>
        <v-card-title class="subtitle-2 font-weight-bold">{{ title }}</v-card-title>
        <v-divider />
        <v-card-text style="overflow-x:auto;max-height:calc(100vh - 48px - 12px - 56px - 12px - 54px - 52px - 12px - 12px);">
            <v-responsive v-if="banner.slides.length < 1" width="100%" height="100%">
                <div>{{ title }} 이미지가 없습니다.</div>
            </v-responsive>
            <draggable v-model="banner.slides">
                <v-hover v-for="(slide, index) in banner.slides" :key="`slide-${index}`" v-slot="{ hover }">
                    <v-img :src="srcs[index]" :aspect-ratio="1920 / 714" max-width="90vw">
                        <v-fade-transition>
                            <v-overlay v-show="hover" absolute z-index="0">
                                <v-menu v-model="slide.showsModal_url" offset-y nudge-left="222" :close-on-content-click="false">
                                    <v-icon slot="activator" slot-scope="{ attrs, on }" v-bind="attrs" v-on="on" large> mdi-link-variant </v-icon>
                                    <v-card width="480" class="pa-3">
                                        <v-text-field v-model="slide.url" label="URL" autofocus persistent-placeholder hide-details dense outlined @keydown.enter="slide.showsModal_url = false" />
                                    </v-card>
                                </v-menu>
                                <v-icon large class="ml-3" @click="banner.slides.splice(index, 1)">
                                    mdi-delete
                                </v-icon>
                            </v-overlay>
                        </v-fade-transition>
                    </v-img>
                </v-hover>
            </draggable>
        </v-card-text>
        <v-divider />
        <v-card-actions class="pt-0">
            <v-row no-gutters justify-sm="space-between">
                <v-col cols="" sm="auto" class="mt-2">
                    <v-file-btn outlined color="primary" accept="image/*" v-bind="{ loading, block: $vuetify.breakpoint.xsOnly }" @change="upload">
                        <v-icon class="mr-2">mdi-cloud-upload</v-icon>
                        <span>{{ title }} 이미지 업로드</span>
                    </v-file-btn>
                </v-col>
                <v-col cols="" sm="auto" class="mt-2">
                    <v-btn color="primary" v-bind="{ loading, block: $vuetify.breakpoint.xsOnly }" @click="save">
                        <v-icon class="mr-2">mdi-content-save</v-icon>
                        <span>저장하기</span>
                    </v-btn>
                </v-col>
            </v-row>
        </v-card-actions>
        <v-fade-transition>
            <v-overlay v-show="loading" absolute>
                <v-progress-circular indeterminate size="100" width="5" />
            </v-overlay>
        </v-fade-transition>
    </v-card>
</template>

<script>
import api from "@/api";
import draggable from "vuedraggable";

import VPreviewInput from "@/components/plugins/vuetify/v-preview-input.vue";
import VFileBtn from "@/components/plugins/vuetify/v-file-btn.vue";

import NaverSmarteditor from "@/components/plugins/naver/naver-smarteditor.vue";
import VDateField from "@/components/plugins/vuetify/v-date-field.vue";
import ListHeading from "@/components/console/dumb/list-heading.vue";

export default {
    components: {
        draggable,

        VPreviewInput,
        VFileBtn,

        NaverSmarteditor,
        VDateField,
        ListHeading,
    },
    props: {
        title: String,
        code: String,
        name: String,
    },
    data() {
        return {
            images: null,
            banner: {
                _id: null,
                slides: [],
            },
            loading: true,
        };
    },
    computed: {
        srcs() {
            return (this.banner.slides || []).map(({ image }) => URL.createObjectURL(image));
        },
    },
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            this.loading = true;
            const { code } = this;
            var { banner } = await api.v1.banners.get({ code });

            for (let index in banner?.slides || []) {
                if (banner?.slides?.[index]?.image) banner.slides[index].image = await api.getResource(banner.slides[index].image);
            }

            this.banner = { ...this.banner, ...(banner || {}) };
            if (!this.banner?.slides?.length) this.banner.slides = [...this.banner.slides];

            this.loading = false;
        },

        async save() {
            this.loading = true;
            try {
                const { code, name } = this;
                let { banner } = this.banner._id ? await api.console.banners.put(this.banner) : await api.console.banners.post({ ...this.banner, code, name });

                for (let index in this.banner.slides) {
                    if (this.banner.slides[index]) await api.console.banners.images.post(banner._id, index, this.banner.slides[index].image);
                }

                alert("저장되었습니다");
            } finally {
                this.loading = false;
            }
        },

        upload(file) {
            let files = [file];
            this.banner.slides = [...files.map((file) => ({ image: file, url: null })), ...this.banner.slides];
        },
    },
};
</script>

<style>
.editor.hidden {
    position: absolute;
    width: 0;
    height: 0;
    overflow: hidden;
}
</style>
