<template>
    <v-dialog v-model="shows" width="320">
        <template v-slot:activator="{ attrs, on }">
            <slot name="activator" v-bind="{ attrs, on }" />
        </template>
        <v-card light>
            <v-card-title> {{ title }} <v-spacer /> <v-icon @click="shows = false">mdi-close</v-icon> </v-card-title>
            <v-divider />
            <v-card-text>
                <v-text-field v-model="inputs.username" label="아이디" v-bind="inputAttrs" class="mt-3" />
                <v-text-field v-model="inputs.password" label="비밀번호" type="password" v-bind="inputAttrs" class="mt-3" />
                <v-text-field v-model="inputs.name" label="이름" v-bind="inputAttrs" class="mt-3" />
            </v-card-text>
            <v-divider />
            <v-card-actions>
                <v-spacer />
                <v-btn color="primary" @click="save">저장</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import api from "@/api";
import CryptoAES from "@/plugins/crypto-aes";
import { USER_TYPES, inputAttrs } from "@/assets/variables";

const initInputs = () => ({
    username: null,
    password: null,
    name: null,
    type: USER_TYPES.ADMIN.value,
});

export default {
    props: {
        value: { type: Object, default: () => ({}) },
    },
    data: () => ({
        inputAttrs,

        shows: false,
        inputs: initInputs(),
    }),
    computed: {
        isNew() {
            return !this.value?._id;
        },
        title() {
            return this.isNew ? "관리자 생성" : "관리자 상세";
        },
    },
    mounted() {
        this.init();
    },
    watch: {
        shows(shows) {
            if (shows) this.init();
        },
        value() {
            this.init();
        },
    },
    methods: {
        async init() {
            if (this.isNew) this.inputs = initInputs();
            else {
                const { user = {} } = (await api.console.users.get({ _id: this.value?._id })) || {};
                const { _id, username, password = null, name, type } = user;
                this.inputs = { _id, username, password, name, type };
            }
        },
        validates() {
            try {
                const { username, password, name } = this.inputs;
                if (!username) throw new Error("아이디를 입력해주세요");
                if (!password) throw new Error("비밀번호를 입력해주세요");
                if (!name) throw new Error("이름을 입력해주세요");
            } catch (error) {
                this.$handleError(error);
                return false;
            }

            return true;
        },
        async save() {
            if (!this.validates()) return;

            const { post, put } = api.console.users;

            let { password, ...user } = this.inputs;
            password = CryptoAES.encrypt(password);
            user = (await (this.isNew ? post : put)({ ...user, password }))?.user;

            alert("저장되었습니다");
            this.$emit("input", user);
            this.shows = false;
        },
    },
};
</script>
