<template>
    <v-card outlined v-bind="$attrs">
        <v-card-title class="subtitle-2 font-weight-bold">{{ label }}</v-card-title>
        <v-card-text>
            <template v-if="showsNoItem"> 선택된 프로그램이 없습니다 </template>
            <slot v-else />
        </v-card-text>
    </v-card>
</template>

<script>
export default {
    props: {
        label: { type: String, default: null },
        showsNoItem: { type: Boolean, default: false },
    },
};
</script>

<style></style>
