var render = function render(){
  var _vm$form, _vm$form$work, _vm$form2, _vm$form2$work, _vm$form2$work$author, _vm$form2$work$author2, _vm$form3, _vm$form3$work, _vm$form4, _vm$form4$work, _vm$form6, _vm$form6$meta, _vm$form7, _vm$form7$meta, _vm$form8, _vm$form8$meta, _vm$form9, _vm$form9$meta, _vm$form10, _vm$form10$meta, _vm$form11, _vm$form11$meta, _ref2, _vm$form12, _vm$form13, _vm$form14, _vm$form14$meta, _vm$form15, _vm$form15$meta, _vm$form16, _vm$form16$meta, _vm$form17, _vm$form17$meta, _vm$form18, _vm$form18$meta, _vm$form19, _vm$form19$meta, _vm$form20, _vm$form20$meta, _vm$form21, _vm$form21$meta, _vm$form$meta$price_b, _vm$form$meta$price_b2, _vm$form22, _vm$form22$meta, _vm$form23, _vm$form23$meta, _vm$form$meta$price_a, _vm$form$meta$price_a2, _vm$form24, _vm$form24$meta, _vm$form25, _vm$form25$meta;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.ready ? _c('v-layout', {
    attrs: {
      "justify-center": ""
    }
  }, [_c('v-responsive', {
    staticClass: "px-3",
    attrs: {
      "max-width": "800",
      "width": "100%",
      "height": "100%"
    }
  }, [_c('div', {
    staticClass: "headline text-start mt-8"
  }, [_vm._v(_vm._s(_vm.headline))]), _vm.isCode(['private-sale']) ? [_c('v-card', {
    staticClass: "mt-6",
    staticStyle: {
      "overflow": "hidden"
    }
  }, [_vm.$route.params._form ? _c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-card-title', {
    staticClass: "subtitle-1"
  }, [_vm._v("문의 작품")]), _c('v-divider'), _c('v-card-text', [_c('v-text-field', {
    attrs: {
      "value": (_vm$form = _vm.form) === null || _vm$form === void 0 ? void 0 : (_vm$form$work = _vm$form.work) === null || _vm$form$work === void 0 ? void 0 : _vm$form$work.lot,
      "label": "LOT",
      "persistent-placeholder": "",
      "readonly": ""
    }
  }), _c('v-text-field', {
    attrs: {
      "value": (_vm$form2 = _vm.form) === null || _vm$form2 === void 0 ? void 0 : (_vm$form2$work = _vm$form2.work) === null || _vm$form2$work === void 0 ? void 0 : (_vm$form2$work$author = _vm$form2$work.author) === null || _vm$form2$work$author === void 0 ? void 0 : (_vm$form2$work$author2 = _vm$form2$work$author.name) === null || _vm$form2$work$author2 === void 0 ? void 0 : _vm$form2$work$author2.ko,
      "label": "작가명",
      "persistent-placeholder": "",
      "readonly": ""
    }
  }), _c('v-text-field', {
    attrs: {
      "value": (_vm$form3 = _vm.form) === null || _vm$form3 === void 0 ? void 0 : (_vm$form3$work = _vm$form3.work) === null || _vm$form3$work === void 0 ? void 0 : _vm$form3$work.subject,
      "label": "작품명",
      "persistent-placeholder": "",
      "hide-details": "",
      "readonly": ""
    }
  })], 1)], 1), _c('v-col', {
    staticClass: "d-none d-md-flex",
    staticStyle: {
      "margin-right": "-1px"
    },
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-divider', {
    attrs: {
      "vertical": ""
    }
  })], 1), _c('v-col', {
    staticClass: "d-flex d-md-none",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-divider')], 1), _c('v-col', {
    staticClass: "d-flex align-center",
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('work-imgs-overlay', {
    attrs: {
      "imgs": ((_vm$form4 = _vm.form) === null || _vm$form4 === void 0 ? void 0 : (_vm$form4$work = _vm$form4.work) === null || _vm$form4$work === void 0 ? void 0 : _vm$form4$work.imgs) || []
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var _vm$form5, _vm$form5$work, _vm$form5$work$imgs, _vm$form5$work$imgs$;
        var attrs = _ref.attrs,
          on = _ref.on;
        return [_c('v-img', _vm._g(_vm._b({
          staticClass: "grey lighten-4",
          staticStyle: {
            "cursor": "pointer"
          },
          attrs: {
            "src": (_vm$form5 = _vm.form) === null || _vm$form5 === void 0 ? void 0 : (_vm$form5$work = _vm$form5.work) === null || _vm$form5$work === void 0 ? void 0 : (_vm$form5$work$imgs = _vm$form5$work.imgs) === null || _vm$form5$work$imgs === void 0 ? void 0 : (_vm$form5$work$imgs$ = _vm$form5$work$imgs[0]) === null || _vm$form5$work$imgs$ === void 0 ? void 0 : _vm$form5$work$imgs$.url,
            "height": "100%"
          },
          scopedSlots: _vm._u([{
            key: "placeholder",
            fn: function () {
              return [_c('v-overlay', {
                staticClass: "black--text",
                attrs: {
                  "absolute": "",
                  "light": "",
                  "color": "white"
                }
              }, [_vm._v(" 이미지가 없습니다 ")])];
            },
            proxy: true
          }], null, true)
        }, 'v-img', attrs, false), on))];
      }
    }], null, false, 2144428123)
  })], 1)], 1) : _vm._e()], 1)] : _vm._e(), !_vm.isCode(['consignment-sale', 'private-sale']) ? _c('v-card', {
    staticClass: "mt-6"
  }, [_c('v-card-title', {
    staticClass: "subtitle-1"
  }, [_vm._v("기본정보")]), _c('v-divider'), _c('v-card-text', [_vm.$route.params._form ? _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "6",
      "lg": "3"
    }
  }, [_c('v-text-field', {
    attrs: {
      "value": (_vm.form.writer || {}).name,
      "label": "작성자",
      "persistent-placeholder": "",
      "dense": "",
      "hide-details": ""
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "lg": "3"
    }
  }, [_c('v-text-field', {
    attrs: {
      "value": _vm.form.createdAt ? _vm.form.createdAt.toDateTime() : undefined,
      "label": "작성일",
      "persistent-placeholder": "",
      "dense": "",
      "hide-details": "",
      "readonly": "",
      "disabled": ""
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "lg": "3"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "연락처",
      "persistent-placeholder": "",
      "dense": "",
      "hide-details": ""
    },
    model: {
      value: (_vm.form.writer || {}).phone,
      callback: function ($$v) {
        _vm.$set(_vm.form.writer || {}, "phone", $$v);
      },
      expression: "(form.writer || {}).phone"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "lg": "3"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "이메일",
      "persistent-placeholder": "",
      "dense": "",
      "hide-details": ""
    },
    model: {
      value: (_vm.form.writer || {}).email,
      callback: function ($$v) {
        _vm.$set(_vm.form.writer || {}, "email", $$v);
      },
      expression: "(form.writer || {}).email"
    }
  })], 1)], 1) : _vm._e()], 1)], 1) : _vm._e(), _vm.isCode(['private-sale']) ? [_c('v-card', {
    staticClass: "mt-6"
  }, [_c('v-card-title', {
    staticClass: "subtitle-1"
  }, [_vm._v("문의자 정보")]), _c('v-divider'), _c('v-card-text', [_vm.$route.params._form ? _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "6",
      "lg": "3"
    }
  }, [_c('v-text-field', {
    attrs: {
      "value": (_vm$form6 = _vm.form) === null || _vm$form6 === void 0 ? void 0 : (_vm$form6$meta = _vm$form6.meta) === null || _vm$form6$meta === void 0 ? void 0 : _vm$form6$meta.name,
      "label": "작성자",
      "persistent-placeholder": "",
      "dense": "",
      "hide-details": "",
      "readonly": ""
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "lg": "3"
    }
  }, [_c('v-text-field', {
    attrs: {
      "value": _vm.form.createdAt ? _vm.form.createdAt.toDateTime() : undefined,
      "label": "작성일",
      "persistent-placeholder": "",
      "dense": "",
      "hide-details": "",
      "readonly": ""
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6",
      "lg": "3"
    }
  }, [_c('v-text-field', {
    attrs: {
      "value": (_vm$form7 = _vm.form) === null || _vm$form7 === void 0 ? void 0 : (_vm$form7$meta = _vm$form7.meta) === null || _vm$form7$meta === void 0 ? void 0 : _vm$form7$meta.phone,
      "label": "연락처",
      "persistent-placeholder": "",
      "dense": "",
      "hide-details": "",
      "readonly": ""
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6",
      "lg": "3"
    }
  }, [_c('v-text-field', {
    attrs: {
      "value": (_vm$form8 = _vm.form) === null || _vm$form8 === void 0 ? void 0 : (_vm$form8$meta = _vm$form8.meta) === null || _vm$form8$meta === void 0 ? void 0 : _vm$form8$meta.email,
      "label": "이메일",
      "persistent-placeholder": "",
      "dense": "",
      "hide-details": "",
      "readonly": ""
    }
  })], 1)], 1) : _vm._e()], 1)], 1)] : _vm._e(), !_vm.isCode(['consignment-sale', 'private-sale']) ? _c('v-card', {
    staticClass: "mt-6"
  }, [_c('v-card-text', [_c('v-text-field', {
    attrs: {
      "label": "유형",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.form.meta.type,
      callback: function ($$v) {
        _vm.$set(_vm.form.meta, "type", $$v);
      },
      expression: "form.meta.type"
    }
  })], 1)], 1) : _vm._e(), _vm.isCode(['private-sale']) ? _c('v-card', {
    staticClass: "mt-6"
  }, [_c('v-card-title', {
    staticClass: "subtitle-1"
  }, [_vm._v("문의 상세")]), _c('v-divider'), _c('v-card-text', [_c('v-text-field', {
    attrs: {
      "label": "제목",
      "persistent-placeholder": "",
      "readonly": ""
    },
    model: {
      value: _vm.form.subject,
      callback: function ($$v) {
        _vm.$set(_vm.form, "subject", $$v);
      },
      expression: "form.subject"
    }
  }), _c('v-textarea', {
    attrs: {
      "label": "내용",
      "hide-details": "",
      "readonly": ""
    },
    model: {
      value: _vm.form.content,
      callback: function ($$v) {
        _vm.$set(_vm.form, "content", $$v);
      },
      expression: "form.content"
    }
  })], 1)], 1) : _vm._e(), !_vm.isCode(['consignment-sale', 'private-sale']) ? _c('v-card', {
    staticClass: "mt-6"
  }, [_c('v-card-title', {
    staticClass: "subtitle-1"
  }, [_vm._v("내용")]), _c('v-divider'), _c('v-card-text', [_c('v-textarea', {
    attrs: {
      "hide-details": "",
      "dense": "",
      "auto-grow": ""
    },
    model: {
      value: _vm.form.content,
      callback: function ($$v) {
        _vm.$set(_vm.form, "content", $$v);
      },
      expression: "form.content"
    }
  })], 1)], 1) : _vm._e(), _vm.isCode(['consignment-sale']) ? [_c('v-card', {
    staticClass: "mt-6"
  }, [_c('v-card-title', {
    staticClass: "subtitle-1"
  }, [_vm._v("위탁 신청인 정보")]), _c('v-divider'), _c('v-card-text', [_vm.$route.params._form ? _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "6",
      "lg": "3"
    }
  }, [_c('v-text-field', {
    attrs: {
      "value": (_vm$form9 = _vm.form) === null || _vm$form9 === void 0 ? void 0 : (_vm$form9$meta = _vm$form9.meta) === null || _vm$form9$meta === void 0 ? void 0 : _vm$form9$meta.name,
      "label": "작성자",
      "persistent-placeholder": "",
      "dense": "",
      "hide-details": "",
      "readonly": ""
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "lg": "3"
    }
  }, [_c('v-text-field', {
    attrs: {
      "value": _vm.form.createdAt ? _vm.form.createdAt.toDateTime() : undefined,
      "label": "작성일",
      "persistent-placeholder": "",
      "dense": "",
      "hide-details": "",
      "readonly": ""
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6",
      "lg": "3"
    }
  }, [_c('v-text-field', {
    attrs: {
      "value": (_vm$form10 = _vm.form) === null || _vm$form10 === void 0 ? void 0 : (_vm$form10$meta = _vm$form10.meta) === null || _vm$form10$meta === void 0 ? void 0 : _vm$form10$meta.phone,
      "label": "연락처",
      "persistent-placeholder": "",
      "dense": "",
      "hide-details": "",
      "readonly": ""
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6",
      "lg": "3"
    }
  }, [_c('v-text-field', {
    attrs: {
      "value": (_vm$form11 = _vm.form) === null || _vm$form11 === void 0 ? void 0 : (_vm$form11$meta = _vm$form11.meta) === null || _vm$form11$meta === void 0 ? void 0 : _vm$form11$meta.email,
      "label": "이메일",
      "persistent-placeholder": "",
      "dense": "",
      "hide-details": "",
      "readonly": ""
    }
  })], 1)], 1) : _vm._e()], 1)], 1), _c('v-card', {
    staticClass: "mt-6"
  }, [_c('v-card-title', {
    staticClass: "subtitle-1"
  }, [_vm._v("위탁 작품 정보")]), _c('v-divider'), _c('v-card-text', [_vm.$route.params._form ? _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('span', {
    staticClass: "subtitle-2"
  }, [_vm._v("이미지")]), _c('v-row', [((_ref2 = ((_vm$form12 = _vm.form) === null || _vm$form12 === void 0 ? void 0 : _vm$form12.files) || []) === null || _ref2 === void 0 ? void 0 : _ref2.length) < 1 ? _c('v-col', [_vm._v(" 이미지가 없습니다. ")]) : _vm._e(), _vm._l(((_vm$form13 = _vm.form) === null || _vm$form13 === void 0 ? void 0 : _vm$form13.files) || [], function (file) {
    return _c('v-col', {
      key: file._id,
      attrs: {
        "cols": "2"
      }
    }, [_c('image-popup', {
      attrs: {
        "src": file.url,
        "aspect-ratio": 1 / 1,
        "width": "100%"
      }
    })], 1);
  })], 2)], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "value": (_vm$form14 = _vm.form) === null || _vm$form14 === void 0 ? void 0 : (_vm$form14$meta = _vm$form14.meta) === null || _vm$form14$meta === void 0 ? void 0 : _vm$form14$meta.author,
      "label": "작가명",
      "persistent-placeholder": "",
      "dense": "",
      "hide-details": "",
      "readonly": ""
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "value": (_vm$form15 = _vm.form) === null || _vm$form15 === void 0 ? void 0 : (_vm$form15$meta = _vm$form15.meta) === null || _vm$form15$meta === void 0 ? void 0 : _vm$form15$meta.subject,
      "label": "작품명",
      "persistent-placeholder": "",
      "dense": "",
      "hide-details": "",
      "readonly": ""
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "value": (_vm$form16 = _vm.form) === null || _vm$form16 === void 0 ? void 0 : (_vm$form16$meta = _vm$form16.meta) === null || _vm$form16$meta === void 0 ? void 0 : _vm$form16$meta.material,
      "label": "작품의 재료",
      "persistent-placeholder": "",
      "dense": "",
      "hide-details": "",
      "readonly": ""
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "value": (_vm$form17 = _vm.form) === null || _vm$form17 === void 0 ? void 0 : (_vm$form17$meta = _vm$form17.meta) === null || _vm$form17$meta === void 0 ? void 0 : _vm$form17$meta.dimension,
      "label": "작품의 크기",
      "persistent-placeholder": "",
      "dense": "",
      "hide-details": "",
      "readonly": ""
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "value": (_vm$form18 = _vm.form) === null || _vm$form18 === void 0 ? void 0 : (_vm$form18$meta = _vm$form18.meta) === null || _vm$form18$meta === void 0 ? void 0 : _vm$form18$meta.edition,
      "label": "Edition",
      "persistent-placeholder": "",
      "dense": "",
      "hide-details": "",
      "readonly": ""
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "value": (_vm$form19 = _vm.form) === null || _vm$form19 === void 0 ? void 0 : (_vm$form19$meta = _vm$form19.meta) === null || _vm$form19$meta === void 0 ? void 0 : _vm$form19$meta.year,
      "label": "제작연도(추정)",
      "persistent-placeholder": "",
      "dense": "",
      "hide-details": "",
      "readonly": ""
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('v-textarea', {
    attrs: {
      "value": (_vm$form20 = _vm.form) === null || _vm$form20 === void 0 ? void 0 : (_vm$form20$meta = _vm$form20.meta) === null || _vm$form20$meta === void 0 ? void 0 : _vm$form20$meta.content,
      "label": "작품설명",
      "persistent-placeholder": "",
      "dense": "",
      "hide-details": "",
      "readonly": ""
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('v-textarea', {
    attrs: {
      "value": (_vm$form21 = _vm.form) === null || _vm$form21 === void 0 ? void 0 : (_vm$form21$meta = _vm$form21.meta) === null || _vm$form21$meta === void 0 ? void 0 : _vm$form21$meta.etc,
      "label": "기타",
      "persistent-placeholder": "",
      "dense": "",
      "hide-details": "",
      "readonly": ""
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "value": ((_vm$form$meta$price_b = +((_vm$form22 = _vm.form) === null || _vm$form22 === void 0 ? void 0 : (_vm$form22$meta = _vm$form22.meta) === null || _vm$form22$meta === void 0 ? void 0 : _vm$form22$meta.price_bought)) === null || _vm$form$meta$price_b === void 0 ? void 0 : (_vm$form$meta$price_b2 = _vm$form$meta$price_b.format) === null || _vm$form$meta$price_b2 === void 0 ? void 0 : _vm$form$meta$price_b2.call(_vm$form$meta$price_b)) || ((_vm$form23 = _vm.form) === null || _vm$form23 === void 0 ? void 0 : (_vm$form23$meta = _vm$form23.meta) === null || _vm$form23$meta === void 0 ? void 0 : _vm$form23$meta.price_bought),
      "label": "구입가(KRW)",
      "persistent-placeholder": "",
      "dense": "",
      "hide-details": "",
      "readonly": ""
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "value": ((_vm$form$meta$price_a = +((_vm$form24 = _vm.form) === null || _vm$form24 === void 0 ? void 0 : (_vm$form24$meta = _vm$form24.meta) === null || _vm$form24$meta === void 0 ? void 0 : _vm$form24$meta.price_asking)) === null || _vm$form$meta$price_a === void 0 ? void 0 : (_vm$form$meta$price_a2 = _vm$form$meta$price_a.format) === null || _vm$form$meta$price_a2 === void 0 ? void 0 : _vm$form$meta$price_a2.call(_vm$form$meta$price_a)) || ((_vm$form25 = _vm.form) === null || _vm$form25 === void 0 ? void 0 : (_vm$form25$meta = _vm$form25.meta) === null || _vm$form25$meta === void 0 ? void 0 : _vm$form25$meta.price_asking),
      "label": "희망가(KRW)",
      "persistent-placeholder": "",
      "dense": "",
      "hide-details": "",
      "readonly": ""
    }
  })], 1)], 1) : _vm._e()], 1)], 1)] : _vm._e(), _vm.isCode(['interior-inquire', 'consignment-sale', 'private-sale']) ? _c('v-card', {
    staticClass: "mt-6"
  }, [_c('v-card-title', {
    staticClass: "subtitle-1"
  }, [_vm._v("답변")]), _c('v-divider'), _c('v-card-text', [_c('naver-smarteditor', {
    attrs: {
      "id": "reply",
      "rows": "10"
    },
    model: {
      value: _vm.form.reply,
      callback: function ($$v) {
        _vm.$set(_vm.form, "reply", $$v);
      },
      expression: "form.reply"
    }
  })], 1), _c('v-divider'), _c('v-card-actions', [_c('v-btn', {
    attrs: {
      "outlined": "",
      "color": "grey"
    },
    on: {
      "click": function ($event) {
        _vm.form.reply = null;
      }
    }
  }, [_vm._v("초기화")]), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "color": "primary"
    },
    on: {
      "click": _vm.save
    }
  }, [_vm._v("저장")])], 1)], 1) : _vm._e(), !_vm.isCode(['interior-inquire', 'consignment-sale', 'private-sale']) ? _c('v-row', {
    attrs: {
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "md": "auto",
      "sm": "auto"
    }
  }, [_c('v-btn', {
    attrs: {
      "color": "primary"
    },
    on: {
      "click": _vm.save
    }
  }, [_vm._v("저장")])], 1)], 1) : _vm._e()], 2)], 1) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }