<template>
    <v-layout justify-center>
        <v-responsive max-width="1440" width="100%" height="100%" class="mx-n3 px-3">
            <list-heading v-model="showsSearch" title="프로그램 이용현황" showsFilterButton>
                <template #buttons>
                    <v-btn rounded color="primary" @click="generateExcel"> <v-icon>mdi-microsoft-excel</v-icon> 엑셀 다운로드 </v-btn>
                </template>
            </list-heading>
            <program-user-filter v-bind="{ showsSearch }" />
            <program-user-chips v-model="selected" v-bind="{ showsSearch, programs }" class="mt-4" />
            <v-row no-gutters class="mx-n2 my-2" v-if="items.length">
                <v-col cols="6" class="pa-2">
                    <program-user-attendees v-bind="{ items }" />
                    <program-user-attendances v-bind="{ items }" class="mt-4" />
                </v-col>
                <v-col cols="6" class="pa-2">
                    <program-user-staying v-bind="{ items }" />
                    <program-user-genders v-bind="{ items }" class="mt-4" />
                    <program-user-decades v-bind="{ items }" class="mt-4" />
                </v-col>
            </v-row>
            <v-card v-else outlined class="mt-4">
                <v-card-text> 선택하신 프로그램이 없습니다 </v-card-text>
            </v-card>
        </v-responsive>
    </v-layout>
</template>

<script>
import api from "@/api";
import XLSX from "xlsx";

import ListHeading from "@/components/console/dumb/list-heading.vue";
import ProgramUserFilter from "@/components/console/statistics/program-user-filter.vue";
import ProgramUserChips from "@/components/console/statistics/program-user-chips.vue";
import StatisticsGraphCard from "@/components/console/statistics/statistics-graph-card.vue";
import ProgramUserAttendees from "@/components/console/statistics/program-user-attendees.vue";
import ProgramUserAttendances from "@/components/console/statistics/program-user-attendances.vue";
import ProgramUserStaying from "@/components/console/statistics/program-user-staying.vue";
import ProgramUserGenders from "@/components/console/statistics/program-user-genders.vue";
import ProgramUserDecades from "@/components/console/statistics/program-user-decades.vue";
import dayjs from "dayjs";

export default {
    components: {
        ListHeading,
        ProgramUserFilter,
        ProgramUserChips,
        StatisticsGraphCard,
        ProgramUserAttendees,
        ProgramUserAttendances,
        ProgramUserStaying,
        ProgramUserGenders,
        ProgramUserDecades,
    },
    data() {
        return {
            showsSearch: true,
            programs: [],
            selected: [],

            limit: 10,
            summaries: [],
        };
    },
    computed: {
        items() {
            return this.programs.filter(({ _id }) => this.selected.includes(_id)).map((program) => ({ ...program, summary: this.summaries.find(({ _id }) => _id == program._id) }));
        },
        params() {
            let { query } = this.$route;
            return { ...query };
        },
    },
    mounted() {
        this.search();
    },
    watch: {
        params() {
            this.search();
        },
    },
    methods: {
        async search() {
            try {
                let { params } = this;
                var { summaries, programs } = await api.console.statistics.programUser.get({
                    params,
                });

                this.summaries = summaries;
                this.programs = programs;
                this.selected = programs.map(({ _id }) => _id);
            } catch (error) {
                console.error(error);
                if (error.response) alert(error.response.data.email);
                else alert(error.email.toString());
            }
        },
        async generateExcel() {
            try {
                var rows = this.items.map((program = {}) => {
                    let { summary = {} } = program;

                    let attendancesPercentage = (() => {
                        let { attendancesMax, attendancesVal } = summary;
                        return Math.round((attendancesVal / attendancesMax) * 100);
                    })();
                    let attendeesStayingPercentage = (() => {
                        let { attendeesStaying, attendeesLength } = summary;
                        return Math.round((attendeesStaying / attendeesLength) * 100);
                    })();

                    return {
                        "": program?.name,
                        총참여자: summary?.attendeesLength || 0,
                        남성: summary?.attendeesMale || 0,
                        여성: summary?.attendeesFemale || 0,
                        기타: summary?.attendeesElse || 0,
                        출석률: `${attendancesPercentage || 0}%`,
                        잔존비: `${attendeesStayingPercentage || 0}%`,
                        "10대": summary?.attendeesAge10 || 0,
                        "20대": summary?.attendeesAge20 || 0,
                        "30대": summary?.attendeesAge30 || 0,
                        "40대": summary?.attendeesAge40 || 0,
                        "50대": summary?.attendeesAge50 || 0,
                        "60대": summary?.attendeesAge60 || 0,
                        "70대": summary?.attendeesAge70 || 0,
                    };
                });

                var workbook = new XLSX.utils.book_new();
                var worksheet = XLSX.utils.json_to_sheet(rows);

                XLSX.utils.book_append_sheet(workbook, worksheet, "이용현황-프로그램");
                XLSX.writeFile(workbook, `이용현황-프로그램-${dayjs().format("YYMMDD-HHmmss")}.xlsx`);
            } catch (error) {
                console.error(error);
                alert(error.response ? error.response.data.email : error.email);
            }
        },
    },
};
</script>
