var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-layout', {
    attrs: {
      "justify-center": ""
    }
  }, [_c('v-responsive', {
    staticClass: "px-3",
    attrs: {
      "max-width": _vm.$vuetify.breakpoint.mdAndUp ? 1080 : undefined
    }
  }, [_c('list-heading', {
    attrs: {
      "title": "메인배너 목록"
    }
  }), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('banners-card', {
    attrs: {
      "title": "데스크탑",
      "code": "main-banners-pc",
      "name": "메인 데스크탑"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('banners-card', {
    attrs: {
      "title": "모바일",
      "code": "main-banners-mobile",
      "name": "메인 모바일"
    }
  })], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }