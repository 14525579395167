var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-select', _vm._b({
    on: {
      "input": function (shows) {
        return _vm.$router.push({
          query: Object.assign({}, _vm.$route.query, {
            page: undefined,
            shows: shows == null ? undefined : shows
          })
        });
      }
    }
  }, 'v-select', Object.assign({}, _vm.$attrs, {
    value: _vm.value,
    items: _vm.items
  }), false));

}
var staticRenderFns = []

export { render, staticRenderFns }