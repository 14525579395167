<template>
    <v-autocomplete v-model="$data._user" :items="users" :item-text="itemText" item-value="_id" v-bind="$attrs" @click="alert" />
</template>

<script>
import api from "@/api";
import { USER_TYPES } from "@/assets/variables";

export default {
    props: {
        value: { type: String, default: null },
    },
    data: () => ({
        _user: null,
        users: [],
    }),
    mounted() {
        this.sync();
        this.init();
    },
    watch: {
        value() {
            this.sync();
        },
        "$data._user"(_user) {
            this.$emit("input", _user);
        },
    },
    methods: {
        sync() {
            this.$data._user = this.value;
        },
        async init() {
            this.users = (await api.console.users.gets({ params: { type: USER_TYPES.PERSON.value } }))?.users || [];
        },
        itemText({ number, gender, name, phone }) {
            return `${number} / ${gender || "-"} / ${name} / ${phone} `;
        },
        alert() {
            if (this.$attrs.readonly && this.$attrs.readonlyMessage) alert(this.$attrs.readonlyMessage);
        },
    },
};
</script>

<style></style>
