<template>
    <v-dialog v-model="shows" max-width="800">
        <template #activator="{ attrs, on }">
            <slot name="activator" v-bind="{ attrs, on }" />
        </template>
        <v-card>
            <v-card-title>
                출석상세 <v-spacer /> <v-btn text icon tile @click="shows = false"><v-icon>mdi-close</v-icon></v-btn>
            </v-card-title>
            <v-divider />
            <v-data-table v-bind="{ items, headers }" hide-default-footer disable-filtering disable-sort disable-pagination :items-per-page="-1">
                <template v-for="header in headers.filter((header) => header.hasOwnProperty('formatter'))" v-slot:[`item.${header.value}`]="{ value }"> {{ header.formatter(value) }} </template>
                <template #[`item.attendance`]="{ item }">
                    <v-row no-gutters justify="center">
                        <v-col cols="auto">
                            <v-switch v-model="item.hasUserAttended" v-bind="switchAttrs" class="ml-1 mr-n1" style="width: 48px" @change="toggleAttendance(item)" />
                        </v-col>
                    </v-row>
                </template>
            </v-data-table>
        </v-card>
    </v-dialog>
</template>

<script>
import { switchAttrs } from "@/assets/variables";

let headers = [
    { align: "center", width: 100, text: "회원번호", value: "number", divider: true },
    { align: "center", width: 140, text: "아이디", value: "username", divider: true },
    { align: "center", width: 140, text: "이름", value: "name", divider: true },
    { align: "center", width: +80, text: "성별", value: "gender", divider: true },
    { align: "center", width: +80, text: "나이", value: "age", divider: true },
    { align: "center", width: 140, text: "전화번호", value: "phone", divider: true },
    { align: "center", width: 120, text: "출석", value: "attendance" },
];

export default {
    props: {
        unit: { type: Object, default: () => ({}) },
        users: { type: Array, default: () => [] },
        attendances: { type: Array, default: () => [] },
    },
    data() {
        return {
            shows: false,
            headers,
            switchAttrs,
        };
    },
    computed: {
        items() {
            return this.users.map((user) => {
                let attendance = (this.attendances || []).find(({ _user }) => _user == user._id);
                return { ...user, attendance, hasUserAttended: !!attendance };
            });
        },
    },
    methods: {
        async toggleAttendance(item) {
            if (item?.attendance) this.$emit("removeAttendance", item?.attendance);
            else {
                let { _program, _id: _unit } = this.unit;
                let { _id: _user } = item;
                this.$emit("postAttendance", { _program, _unit, _user });
            }
        },
    },
};
</script>
