var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-btn', _vm._b({
    staticClass: "pa-0",
    attrs: {
      "small": "",
      "text": ""
    },
    on: {
      "click": _vm.toggleSchedule
    }
  }, 'v-btn', {
    loading: _vm.loading
  }, false), [_c('v-simple-checkbox', _vm._b({
    staticStyle: {
      "pointer-events": "none"
    },
    attrs: {
      "value": _vm.isChecked,
      "dense": "",
      "hide-details": "",
      "color": "primary"
    }
  }, 'v-simple-checkbox', {
    disabled: _vm.disabled
  }, false)), _c('span', {
    class: {
      'grey--text': _vm.disabled
    }
  }, [_vm._v(" " + _vm._s(_vm.time) + "시")])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }