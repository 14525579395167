<template>
    <v-dialog v-model="shows" max-width="720" persistent>
        <template #activator="{ attrs , on }">
            <slot name="activator" v-bind="{ attrs, on }" />
        </template>
        <user-view-info v-bind="{ _user }" @close="shows = false" @input="(user) => $emit('input', user)" />
    </v-dialog>
</template>

<script>
import UserViewInfo from "./user-view-info.vue";
export default {
    components: {
        UserViewInfo,
    },
    props: {
        _user: { type: String, defatul: undefined },
    },
    data: () => ({
        shows: false,
    }),
};
</script>

<style></style>
