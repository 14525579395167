var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-dialog', {
    attrs: {
      "max-width": "720",
      "persistent": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var attrs = _ref.attrs,
          on = _ref.on;
        return [_vm._t("activator", null, null, {
          attrs,
          on
        })];
      }
    }], null, true),
    model: {
      value: _vm.shows,
      callback: function ($$v) {
        _vm.shows = $$v;
      },
      expression: "shows"
    }
  }, [_c('user-view-info', _vm._b({
    on: {
      "close": function ($event) {
        _vm.shows = false;
      },
      "input": function (user) {
        return _vm.$emit('input', user);
      }
    }
  }, 'user-view-info', {
    _user: _vm._user
  }, false))], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }