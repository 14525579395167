<template>
    <v-card flat v-if="user" outlined>
        <v-card-title v-if="isCreate">
            회원 생성
            <v-spacer />
            <v-btn v-if="isCreate" absolute right text icon @click="$emit('close')">
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </v-card-title>
        <v-divider v-if="isCreate" />
        <v-row no-gutters>
            <v-col cols="12" :md="isCreate ? '' : '8'" :lg="isCreate ? '' : '9'">
                <v-card-subtitle v-if="!isCreate" class="font-weight-bold grey lighten-5">계정정보</v-card-subtitle>
                <v-divider v-if="!isCreate" />

                <form-table-row v-if="!isCreate" label="회원번호"> <v-text-field v-model="user.number" dense hide-details readonly /> </form-table-row>
                <form-table-row label="아이디"> <v-text-field v-model="user.username" dense hide-details :readonly="!isCreate" /> </form-table-row>
                <form-table-row label="비밀번호"> <v-text-field v-model="user.password" dense hide-details type="password" /> </form-table-row>

                <form-table-row label="이름"> <v-text-field v-model="user.name" dense hide-details /> </form-table-row>
                <form-table-row label="성별"> <v-select v-model="user.gender" persistent-placeholder hide-details dense :items="genders" /> </form-table-row>
                <form-table-row label="생년월일"> <v-text-field v-model="user.birthday" dense hide-details type="date" /> </form-table-row>

                <form-table-row label="연락처"> <v-text-field v-model="user.phone" dense hide-details /> </form-table-row>
                <form-table-row label="이메일"> <v-text-field v-model="user.email" dense hide-details /> </form-table-row>
                <form-table-row label="주소">
                    <v-text-field v-model="user.postcode" label="우편번호" persistent-placeholder hide-details readonly @click="$refs.DaumPostcode.open()" class="mt-3" />
                    <v-text-field v-model="user.address1" label="기본주소" persistent-placeholder hide-details readonly @click="$refs.DaumPostcode.open()" />
                    <v-text-field v-model="user.address2" label="상세주소" persistent-placeholder hide-details class="mb-2" />
                </form-table-row>
            </v-col>
            <v-col cols="12" md="auto">
                <v-divider class="d-none d-md-block" vertical />
                <v-divider class="d-block d-md-none" />
            </v-col>
            <v-col v-if="!isCreate" cols="12" md="">
                <v-card-subtitle class="font-weight-bold grey lighten-5">QR코드</v-card-subtitle>
                <v-divider />
                <qrcode-vue :value="user?.number" :size="103" class="d-flex justify-center pa-4" />

                <v-divider />
                <v-card-subtitle class="font-weight-bold grey lighten-5">비고1</v-card-subtitle>
                <v-divider />
                <v-text-field v-model="user.note1" persistent-placeholder hide-details dense class="px-3 py-2" />
                <v-divider />
                <v-card-subtitle class="font-weight-bold grey lighten-5">비고2</v-card-subtitle>
                <v-divider />
                <v-text-field v-model="user.note2" persistent-placeholder hide-details dense class="px-3 py-2" />
                <v-divider />
                <v-card-subtitle class="font-weight-bold grey lighten-5">비고3</v-card-subtitle>
                <v-divider />
                <v-text-field v-model="user.note3" persistent-placeholder hide-details dense class="px-3 py-2" />
                <v-divider />
            </v-col>
        </v-row>
        <v-divider />
        <daum-postcode ref="DaumPostcode" @input="setAddress" />

        <v-card-actions class="align-center grey lighten-5">
            <!-- <v-switch v-if="!isCreate" v-model="user.isWarning" color="red lighten-1" dense inset hide-details class="my-0 mx-4 pa-0">
                <span slot="label" class="subtitle-2 font-weight-bold"> 주의회원 </span>
            </v-switch> -->

            <v-spacer />
            <v-btn color="primary" @click="save">저장</v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
import api from "@/api";
import CryptoAES from "@/plugins/crypto-aes";
import { USER_TYPES } from "@/assets/variables";
import QrcodeVue from "qrcode.vue";
import DaumPostcode from "@/components/plugins/daum/daum-postcode.vue";
import FormTableRow from "../dumb/form-table-row.vue";
const initUser = () => ({
    username: null,
    password: null,

    name: null,
    gender: null,
    birthday: null,
    phone: null,
    email: null,
    postcode: null,
    address1: null,
    address2: null,

    note1: null,
    note2: null,
    note3: null,

    type: USER_TYPES.PERSON.value,
});
const genders = [
    { text: "여성", value: "여성" },
    { text: "남성", value: "남성" },
    // { text: "선택하지않음", value: null },
];
export default {
    components: {
        QrcodeVue,
        DaumPostcode,
        FormTableRow,
    },
    props: {
        _user: { type: String, defatul: undefined },
    },
    data() {
        return {
            user: null,

            genders,
            levels: [],
        };
    },
    computed: {
        isCreate() {
            return !this._user;
        },
    },
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            if (this.isCreate) {
                this.user = initUser();
            } else {
                var { user } = await api.console.users.get({ _id: this._user });
                if (user?.birthday) user.birthday = user.birthday.toDate();
                if (user?.foundAt) user.foundAt = user.foundAt.toDate();
                this.user = user;
            }

            var { levels } = await api.console.levels.gets();
            this.levels = levels;
        },

        validates() {
            try {
                // if(!this.user.name) throw new Error("담당자 이름을 입력해주세요");
                return true;
            } catch (error) {
                alert(error.message);
            }
            return false;
        },

        async save() {
            if (this.validates()) {
                const { put, post } = api.console.users;
                const password = !!this.user.password ? CryptoAES.encrypt(this.user.password) : undefined;
                const { user } = await (this.isCreate ? post : put)({ ...this.user, password });
                alert("저장되었습니다");

                if (this.isCreate) this.$emit("input", user);
                else this.$router.go(-1);
            }
        },

        setAddress({ postcode, address }) {
            this.user.postcode = postcode;
            this.user.address1 = address;
        },
    },
};
</script>

<style lang="scss" scoped>
::v-deep {
    .v-input__slot::before {
        content: none !important;
    }
    .v-input--is-readonly .v-input__slot::after {
        content: none !important;
    }
}
</style>
