var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', _vm._b({
    attrs: {
      "outlined": ""
    }
  }, 'v-card', _vm.$attrs, false), [_c('v-card-title', {
    staticClass: "subtitle-2 font-weight-bold"
  }, [_vm._v(_vm._s(_vm.label))]), _c('v-card-text', [_vm.showsNoItem ? [_vm._v(" 선택된 프로그램이 없습니다 ")] : _vm._t("default")], 2)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }