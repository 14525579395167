var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-layout', {
    attrs: {
      "justify-center": ""
    }
  }, [_c('v-responsive', {
    staticClass: "mx-n3 px-3 pb-3",
    attrs: {
      "max-width": "1080",
      "width": "100%",
      "height": "100%"
    }
  }, [_c('list-heading', {
    attrs: {
      "title": "대시보드"
    }
  }), _c('dashboard-counts'), _c('dashboard-calendar'), _c('dashboard-units', {
    staticClass: "mt-3"
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }