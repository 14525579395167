<template>
    <v-select v-model="$data._category" :items="categories" v-bind="$attrs" item-text="name" item-value="_id" />
</template>

<script>
import api from "@/api";
export default {
    props: {
        value: { type: String, default: null },
    },
    data: () => ({
        _category: null,
        categories: [],
    }),
    mounted() {
        this.init().then(this.sync);
    },
    watch: {
        value() {
            this.sync();
        },
        "$data._category"(_category) {
            this.$emit("input", _category);
        },
    },
    methods: {
        sync() {
            this.$data._category = this.value;
        },
        async init() {
            this.categories = (await api.console.programCategories.gets())?.categories;
        },
    },
};
</script>

<style></style>
