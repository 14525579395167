<template>
    <v-autocomplete v-model="$data._keywords" :items="keywords" :item-text="itemText" item-value="_id" v-bind="$attrs" multiple />
</template>

<script>
import api from "@/api";

export default {
    props: {
        value: { type: Array, default: () => [] },
    },
    data: () => ({
        _keywords: [],
        keywords: [],
    }),
    mounted() {
        this.sync();
        this.init();
    },
    watch: {
        value() {
            this.sync();
        },
        "$data._keywords"(_keywords) {
            this.$emit("input", _keywords);
        },
    },
    methods: {
        sync() {
            this.$data._keywords = this.value;
        },
        async init() {
            this.keywords = (await api.v1.programKeywords.gets())?.keywords || [];
        },
        itemText({ name }) {
            return `＃${name}`;
        },
    },
};
</script>

<style></style>
