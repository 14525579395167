<template>
    <v-btn small text class="pa-0" v-bind="{ loading }" @click="toggleSchedule">
        <v-simple-checkbox :value="isChecked" dense hide-details color="primary" style="pointer-events: none" v-bind="{ disabled }" />
        <span :class="{ 'grey--text': disabled }"> {{ time }}시</span>
    </v-btn>
</template>

<script>
import dayjs from "dayjs";
export default {
    props: {
        loading: { type: Boolean, default: false },

        schedules: { type: Array, default: () => [] },

        item: { type: Object, default: () => ({}) },
        date: { type: String },
        time: { type: Number },
        _user: { type: String },

        schedulesOfUser: { type: Array, default: () => [] },

        limitationMet: { type: Boolean, default: false },
    },
    computed: {
        datetime() {
            return dayjs(this.date).set("hour", this.time).toISOString();
        },
        schedule() {
            let { date, time, datetime, _user } = this;
            let { _id: _space, branch } = this.item;
            return { _user, _space, branch, date, time, datetime };
        },
        isClosed() {
            let hasTimePassed = dayjs().isAfter(this.datetime);
            if (hasTimePassed) return true;

            let hasSchedule = this.item.schedulesOnCloud.some(({ datetime }) => dayjs(datetime).isSame(this.datetime, "hour"));
            if (hasSchedule) return true;

            return false;
        },
        isChecked() {
            let { _space, date, time } = this.schedule;
            return this.schedules.some((item) => item._space == _space && item.date == date && item.time == time);
        },
        hasSchedule() {
            return this.schedules.some(({ datetime }) => dayjs(datetime).isSame(this.datetime, "hour"));
        },
        hasUserSchedule() {
            return this.schedulesOfUser.some(({ datetime }) => dayjs(datetime).isSame(this.datetime, "hour"));
        },
        limitationMetOnBranch() {
            return this.item?.limitationMetOnBranch;
        },
        disabled() {
            return !this.isChecked && (this.limitationMet || this.limitationMetOnBranch || this.isClosed || this.hasSchedule || this.hasUserSchedule);
        },
    },
    methods: {
        toggleSchedule() {
            if (this.isChecked) this.$emit("del", this.schedule);
            else if (this.limitationMet) alert("한 주 동안 3시간을 초과해 대여할 수 없습니다");
            else if (this.limitationMetOnBranch) alert("한 주 동안 지점당 3시간을 초과해 대여할 수 없습니다");
            else if (this.isClosed) alert("마감된 스케줄입니다");
            else if (this.hasSchedule) alert("이미 동일한 일시에 스케줄을 추가하셨습니다");
            else if (this.hasUserSchedule) alert("이미 동일한 일시에 스케줄을 예약하셨습니다");
            else this.$emit("add", this.schedule);
        },
    },
};
</script>

<style scoped></style>
