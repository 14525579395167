var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('qrcode-stream', _vm._g(_vm._b({}, 'qrcode-stream', {
    camera: _vm.camera,
    track: _vm.track
  }, false), {
    init: _vm.init,
    decode: _vm.decode
  }), [_c('v-fade-transition', [_c('v-overlay', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.errorMessage,
      expression: "errorMessage"
    }],
    attrs: {
      "absolute": "",
      "z-index": "0"
    }
  }, [_vm._v(" " + _vm._s(_vm.errorMessage) + " ")])], 1), _c('v-layout', {
    staticStyle: {
      "position": "absolute",
      "top": "12px",
      "right": "12px"
    },
    attrs: {
      "column": ""
    }
  }, [_vm._t("button"), _c('v-btn', {
    attrs: {
      "fab": "",
      "elevation": "4"
    },
    on: {
      "click": _vm.nextCamera
    }
  }, [_c('v-icon', {
    attrs: {
      "large": ""
    }
  }, [_vm._v("mdi-camera-flip")])], 1)], 2), _vm._t("default")], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }