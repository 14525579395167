<template>
    <v-card style="position: relative; overflow: hidden" outlined>
        <v-row justify="center" align="center">
            <v-col cols="auto">
                <v-btn x-small text fab color="grey" @click="goPreviousMonth"> <v-icon>mdi-chevron-left</v-icon> </v-btn>
            </v-col>
            <v-col cols="auto">{{ month }}월 </v-col>
            <v-col cols="auto">
                <v-btn x-small text fab color="grey" @click="goNextMonth"> <v-icon>mdi-chevron-right</v-icon> </v-btn>
            </v-col>
        </v-row>
        <v-sheet height="600" style="margin: -1px">
            <v-calendar v-model="date" v-bind="{ events, dayFormat }" category-show-all :show-month-on-first="false" color="primary" @click:event="({ event: { to } }) => (to ? $router.push(to) : null)" @click:more="showMore" @click:day="showMore" @click:date="showMore" />
        </v-sheet>
        <v-fade-transition>
            <v-overlay v-show="loading" absolute color="white" light>
                <v-progress-circular indeterminate width="5" size="100" color="black" />
            </v-overlay>
        </v-fade-transition>
        <v-slide-y-transition>
            <v-overlay v-show="showsDayCalendar" absolute>
                <dashboard-calendar-daily v-bind="{ date, events }" width="1056" max-width="calc(100vw - 12px - 12px - 17px)" height="656" light @close="showsDayCalendar = false" />
            </v-overlay>
        </v-slide-y-transition>
    </v-card>
</template>

<script>
import api from "@/api";
import dayjs from "dayjs";
import { RENTAL_SCHEDULE_STATES } from "@/assets/variables";
import DashboardCalendarDaily from "./dashboard-calendar-daily.vue";

let dayFormat = ({ date }) => dayjs(date).get("date");

export default {
    components: {
        DashboardCalendarDaily,
    },
    data: () => ({
        loading: false,

        date: Date.now().toDate(),
        units: [],
        schedules: [],
        showsDayCalendar: false,
    }),
    computed: {
        month() {
            return dayjs(this.date).month() + 1;
        },
        dates() {
            let start = dayjs(this.date).startOf("month");
            let end = dayjs(this.date).endOf("month");
            return [start, end].map((date) => date.toISOString());
        },
        events() {
            if (this.loading) return [];
            return [
                ...this.units.map((unit) => ({
                    name: unit?.program?.name,
                    start: new Date(unit?.startsAt),
                    end: new Date(unit?.endsAt),
                    color: "primary darken-2",
                    to: `/console/programs/${unit._program}/units`,
                })),
                ...this.schedules.map((schedule) => ({
                    name: schedule?.space?.name,
                    start: new Date(schedule?.datetime),
                    end: dayjs(schedule?.datetime).add(1, "hour").toDate(),
                    color: (() => {
                        switch (schedule?.branch) {
                            case "시청점":
                                return "red lighten-2";
                            case "신장점":
                                return "blue lighten-2";
                            case "덕풍점":
                                return "green lighten-2";
                            default:
                                return "secondary";
                        }
                    })(),
                })),
            ]
                .filter(({ name }) => name)
                .map((event) => ({ ...event, timed: true }));
        },
    },
    mounted() {
        this.search();
    },
    watch: {
        month() {
            this.search();
        },
    },
    methods: {
        dayFormat,
        goPreviousMonth() {
            this.date = dayjs(this.date).startOf("month").subtract(1, "month").toISOString().toDate();
        },
        goNextMonth() {
            this.date = dayjs(this.date).startOf("month").add(1, "month").toISOString().toDate();
        },
        showMore({ date }) {
            this.date = date;
            this.showsDayCalendar = true;
        },
        async search() {
            this.loading = true;
            try {
                let { dates } = this;
                this.units = (await api.console.programs.units.gets({ _program: undefined, params: { dates } }))?.units;
                this.schedules = (await api.console.rentalSchedules.gets({ params: { dates, state: RENTAL_SCHEDULE_STATES.APPROVED.value } }))?.schedules;
            } catch (error) {
            } finally {
                this.loading = false;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
::v-deep {
    .v-calendar-weekly__head-weekday {
        padding: 0.5rem;
        font-size: 0.75rem;
        border-bottom: 1px solid #e0e0e0;
    }
    .v-calendar-weekly__day,
    .v-calendar-weekly__head-weekday {
        margin-right: 0px !important;
    }
}
</style>
