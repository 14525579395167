<template>
    <v-expand-transition>
        <v-card v-show="showsSearch" outlined>
            <v-card-title class="subtitle-2 font-weight-bold">프로그램 선택</v-card-title>
            <v-card-text>
                <v-chip-group v-if="programs.length" v-model="selected" multiple column color="primary" @change="emit">
                    <v-chip v-for="program in programs" :key="program._id" :value="program._id" outlined label> [{{ program?.category?.name }}] {{ program?.name }} </v-chip>
                </v-chip-group>
                <template v-else> 프로그램이 없습니다 </template>
            </v-card-text>
        </v-card>
    </v-expand-transition>
</template>

<script>
export default {
    props: {
        showsSearch: { type: Boolean, default: false },

        value: { type: Array, default: () => [] },
        programs: { type: Array, default: () => [] },
    },
    data: () => ({
        selected: [],
    }),
    mounted() {
        this.sync();
    },
    watch: {
        value() {
            this.sync();
        },
    },
    methods: {
        sync() {
            this.selected = [...this.value];
        },
        emit() {
            this.$emit("input", this.selected);
        },
    },
};
</script>

<style></style>
