<template>
    <list-search showsSearch @search="setQuery">
        <v-row align="center">
            <v-col cols="12" sm="6" lg="4">
                <v-select label="카테고리" v-model="query._category" :items="categories" item-text="name" item-value="_id" placeholder="전체" clearable v-bind="inputAttrs" />
            </v-col>
            <v-col cols="12" sm="6" lg="4">
                <v-select label="키워드" v-model="query._keywords" :items="keywords" item-text="name" item-value="_id" placeholder="전체" clearable v-bind="inputAttrs" />
            </v-col>
            <v-col cols="12" sm="6" lg="4">
                <v-select label="상태" v-model="query.status" :items="statuses" placeholder="전체" clearable v-bind="inputAttrs" />
            </v-col>
            <v-col cols="12" sm="6" lg="4">
                <v-text-field label="기간(시작)" v-model="query.searchDateFrom" type="date" v-bind="inputAttrs" />
            </v-col>
            <v-col cols="12" sm="6" lg="4">
                <v-text-field label="기간(종료)" v-model="query.searchDateTo" type="date" v-bind="inputAttrs" />
            </v-col>
            <v-col cols="12" sm="6" lg="4">
                <v-text-field label="제목" v-model="query.searchValue" v-bind="inputAttrs" @keydown.enter="setQuery" />
            </v-col>
        </v-row>
    </list-search>
</template>

<script>
import api from "@/api";
import { inputAttrs } from "@/assets/variables";

import ListSearch from "../dumb/list-search.vue";

let initQuery = () => ({
    _category: null,
    _keywords: null,
    status: null,
    searchDateFrom: null,
    searchDateTo: null,
    searchValue: null,
});
let statuses = ["접수중", "접수중", "접수종료", "모집마감", "진행중", "진행종료"];

export default {
    components: {
        ListSearch,
    },
    data: () => ({
        query: initQuery(),

        statuses,
        keywords: [],
        categories: [],

        inputAttrs,
    }),
    mounted() {
        this.sync();
        this.init();
    },

    methods: {
        sync() {
            this.query = { ...initQuery(), ...this.$route.query };
        },
        async init() {
            let { categories } = await api.console.programCategories.gets();
            this.categories = categories;

            let { keywords } = await api.console.programKeywords.gets();
            this.keywords = keywords;
        },
        setQuery() {
            let { query = {} } = this;
            query.page = 1;
            this.$router.push({ query });
        },
    },
};
</script>

<style></style>
