<template>
    <v-layout justify-center>
        <v-responsive max-width="1660" width="100%" height="100%" class="mx-n3 px-3">
            <list-heading title="회원 목록">
                <template #add-button>
                    <user-modify-dialog @input="$router.go()">
                        <template #activator="{ attrs, on }">
                            <v-btn text icon tile color="primary" title="회원 생성" class="my-n1" v-bind="attrs" v-on="on">
                                <v-icon>mdi-plus</v-icon>
                            </v-btn>
                        </template>
                    </user-modify-dialog>
                </template>
            </list-heading>
            <list-search showsSearch @search="search({ page: 1 })">
                <v-row align="center">
                    <v-col cols="12" sm="6" lg="auto"> <v-text-field v-model="filter.number" label="회원번호" v-bind="inputAttrs" @keydown.enter="search" /> </v-col>
                    <!-- <v-col cols="12" sm="6" lg="auto"> <v-text-field v-model="filter.name" label="이름" v-bind="inputAttrs" @keydown.enter="search" /> </v-col> -->
                    <v-col cols="12" sm="6" lg="auto"> <v-text-field v-model="filter.phone" label="연락처" v-bind="inputAttrs" @keydown.enter="search" /> </v-col>
                    <v-col cols="12" sm="6" lg="auto"> <v-select v-model="filter.gender" label="성별" placeholder="전체" :items="['남성', '여성']" v-bind="inputAttrs" clearable @keydown.enter="search" /> </v-col>
                    <v-col cols="12" sm="6" lg="auto"> <v-text-field v-model="filter.keyword" label="키워드" v-bind="inputAttrs" @keydown.enter="search" /> </v-col>
                    <v-col cols="12" sm="6" lg="auto"> <v-select v-model="filter.hasNote" label="비고유무" placeholder="전체" :items="hasNoteItems" clearable v-bind="inputAttrs" @keydown.enter="search" /> </v-col>
                </v-row>
            </list-search>

            <v-row>
                <v-spacer></v-spacer>
                <v-col cols="auto">
                    <v-card outlined>
                        <v-btn color="white" class="green--text px-2" @click="excel"> <v-icon>mdi-file-excel</v-icon> 엑셀다운로드 </v-btn>
                    </v-card>
                </v-col>
            </v-row>
            <v-card outlined style="overflow: hidden">
                <v-data-table v-bind="{ headers, items }" disable-sort disable-pagination hide-default-footer :items-per-page="-1">
                    <template v-for="header in headers.filter((header) => header.hasOwnProperty('formatter'))" v-slot:[`item.${header.value}`]="{ value }"> {{ header.formatter(value) }} </template>
                    <template v-slot:[`item.isWarning`]="{ item }">
                        <v-row justify="center" align="center">
                            <v-col cols="auto" class="pa-0"> <v-switch v-model="item.isWarning" color="red lighten-1" hide-details dens class="mt-0" @change="updateUser({ _id: item._id, isWarning: item.isWarning })"></v-switch> </v-col>
                        </v-row>
                    </template>
                    <template v-slot:[`item.actions`]="{ item }"> <v-icon small @click="edit(item)"> mdi-magnify </v-icon> </template>
                </v-data-table>
            </v-card>
            <v-pagination v-model="page" :length="pageCount" :total-visible="11" class="mt-4 mx-4" @input="search()"></v-pagination>
        </v-responsive>
    </v-layout>
</template>

<script>
import api from "@/api";
import XLSX from "xlsx";
import { mdiMicrosoftExcel } from "@mdi/js";
import { inputAttrs, USER_TYPES } from "@/assets/variables";
import ListSearch from "@/components/console/dumb/list-search.vue";
import ListHeading from "@/components/console/dumb/list-heading.vue";
import UserModifyDialog from "@/components/console/user/user-modify-dialog.vue";
import dayjs from 'dayjs';
const defaultFormatter = (value) => value || "-";
const headers = [
    { width: 100, align: "right", text: "회원번호", value: "number" },
    { width: 120, align: "center", text: "아이디", value: "username" },
    { width: 120, align: "center", text: "이름", value: "name" },
    { width: +80, align: "center", text: "성별", value: "gender" },
    { width: 140, align: "center", text: "연락처", value: "phone", formatter: (value) => value?.phoneNumberFormat?.() || value || "-" },
    { width: 140, align: "center", text: "이메일", value: "email" },
    { width: 170, align: "center", text: "가입일시", value: "createdAt", formatter: (value) => value?.toDateTime?.() || value || "-" },
    { width: 110, align: "center", text: "상세보기/수정", value: "actions" },
    { width: 140, align: "center", text: "비고1", value: "note1", cellClass: "text-truncate" },
    { width: 140, align: "center", text: "비고2", value: "note2", cellClass: "text-truncate" },
    { width: 140, align: "center", text: "비고3", value: "note3", cellClass: "text-truncate" },
].map((item) => ({ ...item, formatter: item?.formatter || defaultFormatter }));
const hasNoteItems = [
    { text: "비고있음", value: true },
    { text: "비고없음", value: false },
];
export default {
    components: {
        ListSearch,
        ListHeading,
        UserModifyDialog,
    },
    data() {
        return {
            inputAttrs,
            mdiMicrosoftExcel,

            hasNoteItems,

            filter: {
                type: USER_TYPES.PERSON.value,
                name: this.$route.query.name || undefined,
                phone: this.$route.query.phone || undefined,
                number: this.$route.query.number || undefined,
                gender: this.$route.query.gender || undefined,
                keyword: this.$route.query.number || undefined,
                hasNote: this.$route.query.hasNote || undefined,
            },

            page: +this.$route.query.page || 1,
            limit: 10,

            tabIndex: 0,

            headers,
            users: [],
            summary: { totalCount: 0 },
        };
    },
    computed: {
        items() {
            return this.users;
        },
        pageCount() {
            return Math.ceil(this.summary.totalCount / this.limit) || 1;
        },
    },
    created() {
        this.init();
    },
    methods: {
        updateRoute({ page }) {
            const { name, phone, number, hasNote } = this.filter;
            const pageNotSame = page != this.page;
            const nameNotSame = name != this.$route.query?.name;
            const phoneNotSame = phone != this.$route.query?.phone;
            const NumberNotSame = number != this.$route.query?.number;
            const HasNoteNotSame = hasNote != this.$route.query?.hasNote;

            if (pageNotSame || nameNotSame || phoneNotSame || NumberNotSame || HasNoteNotSame) this.$router.push({ query: Object.assignDefined({}, this.$route.query, { page, name, phone, number, hasNote }) });
        },

        async init() {
            try {
                var { levels } = await api.console.levels.gets();
                this.levels = levels;

                this.search();
            } catch (error) {
                console.error(error);
            }
        },

        async search({ page = this.page } = {}) {
            try {
                this.updateRoute({ page });

                var { summary, users } = await api.console.users.gets({
                    headers: {
                        skip: (page - 1) * this.limit,
                        limit: this.limit,
                    },
                    params: this.filter,
                });

                this.users = users;
                this.summary = summary;
            } catch (error) {
                console.error(error);
                if (error.response) alert(error.response.data.email);
                else alert(error.email.toString());
            }
        },

        create() {
            this.$router.push(`/console/users/create`);
        },

        edit(user) {
            this.$router.push({
                path: `/console/users/${user._id}`,
            });
        },

        async updateUser(user) {
            try {
                await api.console.users.put(user);
            } catch (error) {
                console.error(error);
                alert(error.response ? error.response.data.email : error.email);
            }
        },

        async excel() {
            try {
                var { users } = await api.console.users.gets({
                    headers: {
                        limit: 0,
                    },
                    params: this.filter,
                });

                var rows = users.map((user) => ({
                    아이디: user?.username,
                    이름: user?.name,
                    성별: user?.gender,
                    연락처: user?.phone,
                    주소: `[${user.postcode || "00000"}] ${user.address1 || ""} ${user.address2 || ""}`,
                }));

                var workbook = new XLSX.utils.book_new();
                var worksheet = XLSX.utils.json_to_sheet(rows);

                XLSX.utils.book_append_sheet(workbook, worksheet, "new");
                XLSX.writeFile(workbook, `회원목록-${dayjs().format("YYYYMMDDHHmmss")}.xlsx`);
            } catch (error) {
                console.error(error);
                alert(error.response ? error.response.data.email : error.email);
            }
        },

        // async sendemail() {
        //     try {
        //         var users;
        //         switch (this.messageTarget) {
        //             case "search": {
        //                 users = (
        //                     await api.console.users.gets({
        //                         params: this.filter,
        //                     })
        //                 ).users;
        //                 break;
        //             }
        //             case "selected": {
        //                 if (!this.selected.length) throw new Error("사용자를 선택해주세요");
        //                 users = this.selected;
        //                 break;
        //             }
        //         }

        //         var { success } = await api.v1.message.post({
        //             emails: users.map((user) => user.email),
        //             subject: this.messageSubject,
        //             message: this.message,
        //         });

        //         if (success) alert("발송되었습니다");
        //     } catch (error) {
        //         alert(error.message);
        //     }
        // },

        // upload(file, type) {
        //     if (!file) return;

        //     let reader = new FileReader();
        //     reader.onload = () => {
        //         var workSheets = [];
        //         var workBook = XLSX.read(reader.result, { type: "binary" });
        //         workBook.SheetNames.forEach((sheetName) => {
        //             let sheet = XLSX.utils.sheet_to_json(workBook.Sheets[sheetName]);
        //             workSheets.push(sheet);
        //         });
        //         this.$refs.uploader.excelSheet = workSheets[0]; // 첫번째 시트
        //         this.$refs.uploader.type = type;
        //         this.$refs.uploader.open();
        //     };
        //     reader.readAsBinaryString(file);
        // },
    },
};
</script>

<style lang="scss" scoped>
::v-deep .text-truncate {
    max-width: 0;
}
</style>
