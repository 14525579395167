var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    attrs: {
      "outlined": ""
    }
  }, [_c('v-data-table', _vm._b({
    attrs: {
      "disable-filtering": "",
      "disable-pagination": "",
      "disable-sort": "",
      "hide-default-footer": "",
      "items-per-page": -1,
      "sort-by": "startsAt"
    },
    scopedSlots: _vm._u([_vm._l(_vm.headers.filter(function (header) {
      return header.hasOwnProperty('formatter');
    }), function (header) {
      return {
        key: `item.${header.value}`,
        fn: function (_ref) {
          var value = _ref.value;
          return [_vm._v(" " + _vm._s(header.formatter(value)) + " ")];
        }
      };
    }), {
      key: `item.program.thumb`,
      fn: function (_ref2) {
        var value = _ref2.value;
        return [value ? _c('v-img', _vm._b({
          staticClass: "elevation-24 rounded-lg mx-auto",
          attrs: {
            "aspect-ratio": 1 / 1,
            "width": "40"
          }
        }, 'v-img', value, false)) : [_vm._v(" - ")]];
      }
    }, {
      key: `item.attendances`,
      fn: function (_ref3) {
        var _item$attendees;
        var item = _ref3.item,
          value = _ref3.value;
        return [_vm._v(" " + _vm._s(value === null || value === void 0 ? void 0 : value.length) + " / " + _vm._s(item === null || item === void 0 ? void 0 : (_item$attendees = item.attendees) === null || _item$attendees === void 0 ? void 0 : _item$attendees.length) + " ")];
      }
    }, {
      key: `item.qrscan`,
      fn: function (_ref4) {
        var item = _ref4.item;
        return [_c('program-unit-qrscan', _vm._g(_vm._b({
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function (_ref5) {
              var attrs = _ref5.attrs,
                on = _ref5.on;
              return [_c('v-btn', _vm._g(_vm._b({
                attrs: {
                  "text": "",
                  "icon": "",
                  "tile": ""
                }
              }, 'v-btn', attrs, false), on), [_c('v-icon', [_vm._v("mdi-qrcode-scan")])], 1)];
            }
          }], null, true)
        }, 'program-unit-qrscan', Object.assign({}, item, {
          unit: item,
          program: item.program,
          users: item.attendees
        }), false), {
          postAttendance: _vm.postAttendance
        }))];
      }
    }], null, true)
  }, 'v-data-table', {
    items: _vm.items,
    headers: _vm.headers
  }, false))], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }