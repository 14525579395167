<template>
    <v-layout justify-center>
        <v-responsive max-width="800" width="100%" height="100%" class="mx-n3 px-3">
            <list-heading title="관리자 목록">
                <template #add-button>
                    <admin-view @input="update">
                        <template #activator="{ attrs, on }">
                            <v-btn text icon tile color="primary" title="관리자 생성" class="my-n1" v-bind="attrs" v-on="on">
                                <v-icon>mdi-plus</v-icon>
                            </v-btn>
                        </template>
                    </admin-view>
                </template>
            </list-heading>

            <v-card outlined style="overflow:hidden">
                <v-data-table v-bind="{ headers, items }" disable-sort disable-pagination hide-default-footer :items-per-page="-1">
                    <template v-for="header in headers.filter((header) => header.hasOwnProperty('formatter'))" #[`item.${header.value}`]="{ value }"> {{ header.formatter(value) }} </template>
                    <template #[`item.actions`]="{ item }">
                        <admin-view :value="item" @input="update">
                            <template #activator="{ attrs, on }">
                                <v-icon small v-bind="attrs" v-on="on"> mdi-pencil </v-icon>
                            </template>
                        </admin-view>
                        <v-icon small @click="remove(item)"> mdi-delete </v-icon>
                    </template>
                </v-data-table>
            </v-card>

            <v-pagination :value="page" :length="pageCount" :total-visible="11" class="mt-2" @input="(page) => $router.push({ query: { ...$route.query, page } })" />
        </v-responsive>
    </v-layout>
</template>

<script>
import api from "@/api";
import { USER_TYPES } from "@/assets/variables";
import AdminView from "@/components/console/user/admin-view.vue";
import ListHeading from "@/components/console/dumb/list-heading.vue";

const headers = [
    { text: "#", value: "index", align: "right", width: 50 },
    { text: "생성일시", value: "createdAt", formatter: (value) => value?.toDateTime?.() || value || "-", width: 170 },
    { text: "아이디", value: "username", width: 170 },
    { text: "이름", value: "name" },
    { text: "", value: "actions", align: "right", width: 70 },
];

export default {
    components: {
        AdminView,
        ListHeading,
    },
    data() {
        return {
            limit: 10,

            headers,
            users: [],
            summary: { totalCount: 0 },
        };
    },
    computed: {
        page() {
            return +this.$route.query.page || 1;
        },
        pageCount() {
            return Math.ceil(this.summary.totalCount / this.limit) || 1;
        },
        skip() {
            return (this.page - 1) * this.limit;
        },
        filter() {
            return {
                type: USER_TYPES.ADMIN.value,
            };
        },
        items() {
            return this.users.map((user, index) => ({ ...user, index: this.summary.totalCount - (this.page - 1) * this.limit - index }));
        },
    },
    mounted() {
        this.init();
    },
    watch: {
        page() {
            this.search();
        },
    },
    methods: {
        async init() {
            this.search();
        },
        async search() {
            try {
                const { skip, limit, filter: params } = this;
                var { summary, users } = await api.console.users.gets({ headers: { skip, limit }, params });

                this.users = users;
                this.summary = summary;
            } catch (error) {
                console.error(error);
                if (error.response) alert(error.response.data.email);
                else alert(error.email.toString());
            }
        },
        async update(item) {
            const index = this.users.findIndex(({ _id }) => _id == item._id);
            if (index > -1) this.users.splice(index, 1, item);
            else {
                this.users = [item, ...this.users];
                this.summary.totalCount += 1;
            }
        },
        async remove(item) {
            if (!confirm(`관리자를 삭제하시겠습니까?\n\n아이디: "${item?.username || ""}"\n이름: ${item?.name}`)) return;

            await api.console.users.delete(item);
            this.users = this.users.filter((user) => user._id !== item._id);
            this.summary.totalCount -= 1;
            alert("삭제되었습니다.");
        },
    },
};
</script>
