<template>
    <list-search showsSearch @search="setQuery">
        <v-row align="center">
            <v-col cols="12" md="4" lg="">
                <v-text-field label="기간(시작)" v-model="query.dates[0]" type="date" v-bind="inputAttrs" clearable />
            </v-col>
            <v-col cols="12" md="4" lg="">
                <v-text-field label="기간(종료)" v-model="query.dates[1]" type="date" v-bind="inputAttrs" clearable />
            </v-col>
            <v-col cols="12" md="4" lg="">
                <v-select label="상태" v-model="query.state" :items="states" placeholder="전체" clearable v-bind="inputAttrs" />
            </v-col>
            <v-col cols="12" md="4" lg="">
                <v-select label="지점별" v-model="query.branch" :items="branches" placeholder="전체" clearable v-bind="inputAttrs" />
            </v-col>
            <v-col cols="12" md="4" lg="">
                <v-select label="공간별" v-model="query._space" :items="spaces" item-text="name" item-value="_id" placeholder="전체" clearable v-bind="inputAttrs" />
            </v-col>
            <v-col cols="12" md="4" lg="">
                <!-- <v-text-field label="제목" v-model="query.searchValue" v-bind="inputAttrs" @keydown.enter="setQuery" /> -->
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="6" md="12" lg="">
                <v-select label="회원" v-model="user" :items="users" item-text="text" item-value="value" placeholder="전체" clearable v-bind="inputAttrs" />
            </v-col>
            <v-col v-if="user" cols="6" md="6" lg="">
                <v-text-field label="회원" placeholder="휴대폰 번호를 입력하실때는 010-0000-0000 형식을 맞춰주세요" v-model="userValue"  clearable v-bind="inputAttrs" />
            </v-col>
            
        </v-row>
    </list-search>
</template>

<script>
import api from "@/api";
import { branches, inputAttrs, RENTAL_SCHEDULE_STATES, RENTAL_SCHEDULE_USERS } from "@/assets/variables";

import ListSearch from "../dumb/list-search.vue";

let initQuery = () => ({
    dates: [],
    branch: null,
    _space: null,
    state: null,
    user: null
    // searchValue: null,
});

let states = Object.values(RENTAL_SCHEDULE_STATES);
let users = Object.values(RENTAL_SCHEDULE_USERS);

export default {
    components: {
        ListSearch,
    },
    data: () => ({
        query: initQuery(),

        branches,
        spaces: [],
        states,
        user: null,
        userValue: null,
        users,

        inputAttrs,
    }),
    mounted() {
        this.sync();
        this.init();
    },

    methods: {
        sync() {
            this.query = { ...initQuery(), ...this.$route.query };
        },
        async init() {
            let { spaces } = await api.console.rentalSpaces.gets();
            this.spaces = spaces;
        },
        setQuery() {
            let { query: { ...query } = {} } = this;
            if(!!this.user && !!this.userValue) query[this.user] = this.userValue;

            let hasStartDate = !!query?.dates?.[0];
            let hasEndDate = !!query?.dates?.[1];
            if (!hasStartDate && hasEndDate) throw new Error("시작 날짜를 설정해주세요");
            if (hasStartDate && !hasEndDate) throw new Error("종료 날짜를 설정해주세요");
            if (!hasStartDate && !hasEndDate) delete query.dates;

            query.page = 1;

            this.$router.push({ query });
        },
    },
};
</script>

<style></style>
