<template>
    <v-layout v-if="ready" justify-center>
        <v-responsive max-width="800" width="100%" height="100%" class="px-3">
            <div class="headline text-start mt-8">{{ headline }}</div>
            <template v-if="isCode(['private-sale'])">
                <v-card class="mt-6" style="overflow:hidden;">
                    <v-row v-if="$route.params._form" no-gutters>
                        <v-col cols="12" md="6">
                            <v-card-title class="subtitle-1">문의 작품</v-card-title>
                            <v-divider />
                            <v-card-text>
                                <v-text-field :value="form?.work?.lot" label="LOT" persistent-placeholder readonly />
                                <v-text-field :value="form?.work?.author?.name?.ko" label="작가명" persistent-placeholder readonly />
                                <v-text-field :value="form?.work?.subject" label="작품명" persistent-placeholder hide-details readonly />
                            </v-card-text>
                        </v-col>
                        <v-col cols="auto" class="d-none d-md-flex" style="margin-right:-1px;">
                            <v-divider vertical />
                        </v-col>
                        <v-col cols="12" class="d-flex d-md-none">
                            <v-divider />
                        </v-col>
                        <v-col cols="12" md="6" class="d-flex align-center">
                            <work-imgs-overlay :imgs="form?.work?.imgs || []">
                                <template #activator="{attrs,on}">
                                    <v-img :src="form?.work?.imgs?.[0]?.url" height="100%" class="grey lighten-4" style="cursor:pointer" v-bind="attrs" v-on="on">
                                        <template #placeholder>
                                            <v-overlay absolute light color="white" class="black--text"> 이미지가 없습니다 </v-overlay>
                                        </template>
                                    </v-img>
                                </template>
                            </work-imgs-overlay>
                        </v-col>
                    </v-row>
                </v-card>
            </template>

            <v-card class="mt-6" v-if="!isCode(['consignment-sale', 'private-sale'])">
                <v-card-title class="subtitle-1">기본정보</v-card-title>
                <v-divider />
                <v-card-text>
                    <v-row v-if="$route.params._form">
                        <v-col cols="6" lg="3">
                            <v-text-field :value="(form.writer || {}).name" label="작성자" persistent-placeholder dense hide-details />
                        </v-col>
                        <v-col cols="6" lg="3">
                            <v-text-field :value="form.createdAt ? form.createdAt.toDateTime() : undefined" label="작성일" persistent-placeholder dense hide-details readonly disabled />
                        </v-col>
                        <v-col cols="6" lg="3">
                            <v-text-field v-model="(form.writer || {}).phone" label="연락처" persistent-placeholder dense hide-details />
                        </v-col>
                        <v-col cols="6" lg="3">
                            <v-text-field v-model="(form.writer || {}).email" label="이메일" persistent-placeholder dense hide-details />
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>

            <template v-if="isCode(['private-sale'])">
                <v-card class="mt-6">
                    <v-card-title class="subtitle-1">문의자 정보</v-card-title>
                    <v-divider />
                    <v-card-text>
                        <v-row v-if="$route.params._form">
                            <v-col cols="6" lg="3">
                                <v-text-field :value="form?.meta?.name" label="작성자" persistent-placeholder dense hide-details readonly />
                            </v-col>
                            <v-col cols="6" lg="3">
                                <v-text-field :value="form.createdAt ? form.createdAt.toDateTime() : undefined" label="작성일" persistent-placeholder dense hide-details readonly />
                            </v-col>
                            <v-col cols="12" sm="6" lg="3">
                                <v-text-field :value="form?.meta?.phone" label="연락처" persistent-placeholder dense hide-details readonly />
                            </v-col>
                            <v-col cols="12" sm="6" lg="3">
                                <v-text-field :value="form?.meta?.email" label="이메일" persistent-placeholder dense hide-details readonly />
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </template>

            <v-card class="mt-6" v-if="!isCode(['consignment-sale', 'private-sale'])">
                <v-card-text>
                    <v-text-field v-model="form.meta.type" label="유형" persistent-placeholder hide-details />
                </v-card-text>
            </v-card>

            <v-card class="mt-6" v-if="isCode(['private-sale'])">
                <v-card-title class="subtitle-1">문의 상세</v-card-title>
                <v-divider />
                <v-card-text>
                    <v-text-field v-model="form.subject" label="제목" persistent-placeholder readonly />
                    <v-textarea v-model="form.content" label="내용" hide-details readonly />
                </v-card-text>
            </v-card>

            <v-card class="mt-6" v-if="!isCode(['consignment-sale', 'private-sale'])">
                <v-card-title class="subtitle-1">내용</v-card-title>
                <v-divider />
                <v-card-text>
                    <v-textarea v-model="form.content" hide-details dense auto-grow />
                </v-card-text>
            </v-card>

            <template v-if="isCode(['consignment-sale'])">
                <v-card class="mt-6">
                    <v-card-title class="subtitle-1">위탁 신청인 정보</v-card-title>
                    <v-divider />
                    <v-card-text>
                        <v-row v-if="$route.params._form">
                            <v-col cols="6" lg="3">
                                <v-text-field :value="form?.meta?.name" label="작성자" persistent-placeholder dense hide-details readonly />
                            </v-col>
                            <v-col cols="6" lg="3">
                                <v-text-field :value="form.createdAt ? form.createdAt.toDateTime() : undefined" label="작성일" persistent-placeholder dense hide-details readonly />
                            </v-col>
                            <v-col cols="12" sm="6" lg="3">
                                <v-text-field :value="form?.meta?.phone" label="연락처" persistent-placeholder dense hide-details readonly />
                            </v-col>
                            <v-col cols="12" sm="6" lg="3">
                                <v-text-field :value="form?.meta?.email" label="이메일" persistent-placeholder dense hide-details readonly />
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>

                <v-card class="mt-6">
                    <v-card-title class="subtitle-1">위탁 작품 정보</v-card-title>
                    <v-divider />
                    <v-card-text>
                        <v-row v-if="$route.params._form">
                            <v-col cols="12">
                                <span class="subtitle-2">이미지</span>
                                <v-row>
                                    <v-col v-if="(form?.files || [])?.length < 1">
                                        이미지가 없습니다.
                                    </v-col>
                                    <v-col cols="2" v-for="file in form?.files || []" :key="file._id">
                                        <image-popup :src="file.url" :aspect-ratio="1 / 1" width="100%" />
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col cols="6">
                                <v-text-field :value="form?.meta?.author" label="작가명" persistent-placeholder dense hide-details readonly />
                            </v-col>
                            <v-col cols="6">
                                <v-text-field :value="form?.meta?.subject" label="작품명" persistent-placeholder dense hide-details readonly />
                            </v-col>
                            <v-col cols="6">
                                <v-text-field :value="form?.meta?.material" label="작품의 재료" persistent-placeholder dense hide-details readonly />
                            </v-col>
                            <v-col cols="6">
                                <v-text-field :value="form?.meta?.dimension" label="작품의 크기" persistent-placeholder dense hide-details readonly />
                            </v-col>
                            <v-col cols="6">
                                <v-text-field :value="form?.meta?.edition" label="Edition" persistent-placeholder dense hide-details readonly />
                            </v-col>
                            <v-col cols="6">
                                <v-text-field :value="form?.meta?.year" label="제작연도(추정)" persistent-placeholder dense hide-details readonly />
                            </v-col>
                            <v-col cols="12" sm="6">
                                <v-textarea :value="form?.meta?.content" label="작품설명" persistent-placeholder dense hide-details readonly />
                            </v-col>
                            <v-col cols="12" sm="6">
                                <v-textarea :value="form?.meta?.etc" label="기타" persistent-placeholder dense hide-details readonly />
                            </v-col>
                            <v-col cols="6">
                                <v-text-field :value="(+form?.meta?.price_bought)?.format?.() || form?.meta?.price_bought" label="구입가(KRW)" persistent-placeholder dense hide-details readonly />
                            </v-col>
                            <v-col cols="6">
                                <v-text-field :value="(+form?.meta?.price_asking)?.format?.() || form?.meta?.price_asking" label="희망가(KRW)" persistent-placeholder dense hide-details readonly />
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </template>

            <v-card v-if="isCode(['interior-inquire', 'consignment-sale', 'private-sale'])" class="mt-6">
                <v-card-title class="subtitle-1">답변</v-card-title>
                <v-divider />
                <v-card-text>
                    <naver-smarteditor v-model="form.reply" id="reply" rows="10"></naver-smarteditor>
                </v-card-text>
                <v-divider />
                <v-card-actions>
                    <v-btn outlined color="grey" @click="form.reply = null">초기화</v-btn>
                    <v-spacer />
                    <v-btn color="primary" @click="save">저장</v-btn>
                </v-card-actions>
            </v-card>

            <v-row justify="center" v-if="!isCode(['interior-inquire', 'consignment-sale', 'private-sale'])">
                <v-col md="auto" sm="auto">
                    <v-btn color="primary" @click="save">저장</v-btn>
                </v-col>
            </v-row>
        </v-responsive>
    </v-layout>
</template>

<script>
import ImagePopup from "@/components/console/dumb/image-popup.vue";
// import WorkImgsOverlay from "@/components/console/auction/work-imgs-overlay.vue";
import NaverSmarteditor from "@/components/plugins/naver/naver-smarteditor.vue";

import api from "@/api";
import { mdiTagPlus } from "@mdi/js";

export default {
    components: {
        ImagePopup,
        // WorkImgsOverlay,
        NaverSmarteditor,
    },
    data() {
        return {
            mdiTagPlus,
            ready: false,

            ///////////////////////////////////////////////
            // 게시글 정보
            ///////////////////////////////////////////////
            form: {
                code: this.$route.query.code,
                category: undefined,

                subject: undefined,
                content: undefined,
                summary: undefined,
                period: undefined,
                createdAt: undefined,

                sequence: 1,

                storeName: undefined,
                storePhone: undefined,
                storeEmail: undefined,

                sido: undefined,
                gugun: undefined,
                area: undefined,
                postcode: undefined,
                address1: undefined,
                address2: undefined,
                lat: undefined,
                lng: undefined,

                writer: {
                    name: undefined,
                    phone: undefined,
                    email: undefined,
                },

                collections: [],
                relativeProducts: [],
                portfolios: [],

                thumb: undefined,
            },
        };
    },
    computed: {
        headline() {
            if (this.form.code == "consignment-sale") return "위탁신청 상세";
            return "폼메일 상세";
        },
    },
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            try {
                if (this.$route.params._form) {
                    var { form } = await api.console.forms.get({ _id: this.$route.params._form });
                    this.form = form;

                    if (form.thumb) this.form.thumb = await api.getResource(form.thumb);
                }

                this.ready = true;
            } catch (error) {
                console.error(error);
                if (error.response) alert(error.response.data.message);
            }
        },
        async save() {
            try {
                ////////////////////////////////////////////////////////////////
                // 1. 게시글 저장
                ////////////////////////////////////////////////////////////////
                var { form } = this.form._id ? await api.console.forms.put(this.form) : await api.console.forms.post(this.form);

                ////////////////////////////////////////////////////////////////
                // 2. 리소스 저장
                ////////////////////////////////////////////////////////////////
                if (this.form.thumb) await api.console.forms.postThumb(form, this.form.thumb);

                alert(this.form._id ? "수정되었습니다" : "저장되었습니다");

                this.$router.go(-1);
            } catch (error) {
                console.error(error);
                alert(error.response ? error.response.data.message : error.message);
            }
        },

        createObjectURL(file) {
            try {
                return URL.createObjectURL(file);
            } catch (error) {
                return;
            }
        },

        isCode(codes = []) {
            return codes.includes(this.form.code);
        },
    },
};
</script>
