<template>
    <v-dialog v-model="shows" max-width="480">
        <template #activator="{ attrs, on }">
            <slot name="activator" v-bind="{ attrs, on }" />
        </template>
        <v-card>
            <v-card-title>
                출석상세 <v-spacer /> <v-btn text icon tile @click="shows = false"><v-icon>mdi-close</v-icon></v-btn>
            </v-card-title>
            <v-divider />
            <v-data-table v-bind="{ items, headers }" hide-default-footer disable-filtering disable-sort disable-pagination :items-per-page="-1">
                <template v-for="header in headers.filter((header) => header.hasOwnProperty('formatter'))" v-slot:[`item.${header.value}`]="{ value }"> {{ header.formatter(value) }} </template>
                <template #[`item.hasUserAttended`]="{ value }">
                    <span v-if="value == undefined"> - </span>
                    <span v-else-if="value == true" class="primary--text"> 출석 </span>
                    <span v-else-if="value == false" class="pink--text text--lighten-1"> 결석 </span>
                </template>
            </v-data-table>
        </v-card>
    </v-dialog>
</template>

<script>
import dayjs from "dayjs";

let headers = [
    { align: "center", width: +80, text: "회차", value: "number", divider: true },
    { align: "center", width: 110, text: "날짜", value: "date", divider: true, formatter: (value) => dayjs(value).format("YYYY-MM-DD") },
    { align: "center", width: 130, text: "시간", value: "time", divider: true },
    { align: "center", text: "출석", value: "hasUserAttended" },
];
export default {
    props: {
        units: { type: Array, default: () => [] },
        attendances: { type: Array, default: () => [] },
    },
    data() {
        return {
            shows: false,
            headers,
        };
    },
    computed: {
        items() {
            return this.units.map((item, index) => {
                let hasUserAttended = (this.attendances || []).some(({ _unit }) => _unit == item._id);

                if (dayjs().isBefore(item.endsAt) && hasUserAttended == false) {
                    hasUserAttended = undefined;
                }

                return {
                    ...item,
                    number: index + 1,
                    hasUserAttended,
                };
            });
        },
    },
};
</script>
