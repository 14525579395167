<template>
    <div style="display:inline-block">
        <v-chart v-bind="{ initOptions, options }" />
    </div>
</template>

<script>
import StatisticsGraphCard from "./statistics-graph-card.vue";
export default {
    components: {
        StatisticsGraphCard,
    },
    props: {
        summary: { type: Object, default: () => ({}) },
        program: { type: Object, default: () => ({}) },
    },
    computed: {
        initOptions() {
            return {
                width: 200,
                height: 250,
            };
        },
        options() {
            let hasAttendee = !!this.summary.attendeesLength;

            let dataWithAttendee = [
                { value: this.summary?.attendeesStaying || 0, name: "잔류" },
                { value: this.summary?.attendeesLeaving || 0, name: "이탈" },
            ];
            let dataWithNoAttendee = [{ value: 0, name: "참여자가 없습니다" }];
            let data = [...(hasAttendee ? dataWithAttendee : dataWithNoAttendee)];

            let color = hasAttendee ? ["#88ddbb", "#ffaaaa"] : ["#bbbbbb"];
            // [남성 , 여성 , 기타] , [참여자가 없습니다]

            return {
                title: {
                    text: this?.program?.name,
                    left: "center",
                    bottom: "5%",
                },
                legend: {
                    top: "5%",
                    left: "center",
                },
                series: [
                    {
                        data,
                        type: "pie",
                        color,
                        radius: ["30%", "70%"],
                        label: { show: false, position: "center" },
                        labelLine: { show: false },
                        ...(hasAttendee ? { emphasis: { label: { show: true, fontSize: "18", fontWeight: "bold" } } } : {}),
                    },
                ],
            };
        },
    },
};
</script>

<style lang="scss" scoped>
::v-deep {
    .echarts {
        width: 100%;
        height: 100%;
    }
}
</style>
