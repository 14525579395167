var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-autocomplete', _vm._b({
    attrs: {
      "items": _vm.spaces,
      "item-text": _vm.itemText,
      "item-value": "_id"
    },
    model: {
      value: _vm.$data._space,
      callback: function ($$v) {
        _vm.$set(_vm.$data, "_space", $$v);
      },
      expression: "$data._space"
    }
  }, 'v-autocomplete', _vm.$attrs, false));

}
var staticRenderFns = []

export { render, staticRenderFns }