<template>
    <v-layout justify-center>
        <v-responsive :max-width="$vuetify.breakpoint.mdAndUp ? 1080 : undefined" class="px-3">
            <list-heading title="메인배너 목록" />
            <v-row>
                <v-col cols="12" md="6">
                    <banners-card title="데스크탑" code="main-banners-pc" name="메인 데스크탑" />
                </v-col>
                <v-col cols="12" md="6">
                    <banners-card title="모바일" code="main-banners-mobile" name="메인 모바일" />
                </v-col>
            </v-row>
        </v-responsive>
    </v-layout>
</template>

<script>
import detect from "@/plugins/detect";
import ListHeading from "@/components/console/dumb/list-heading.vue";
import BannersCard from "@/components/console/banners/banners-card.vue";

export default {
    components: {
        ListHeading,
        BannersCard,
    },
    data: () => ({
        isMobile: detect == "mobile",
    }),
};
</script>
