<template>
    <statistics-graph-card label="잔존비">
        <div class="d-flex mb-n2" style="width: 100%; height: 268px; overflow-x:auto;">
            <program-user-staying-item v-for="program in items" :key="program._id" v-bind="{ program, summary: program.summary }" />
        </div>
    </statistics-graph-card>
</template>

<script>
import ProgramUserStayingItem from "./program-user-staying-item.vue";
import StatisticsGraphCard from "./statistics-graph-card.vue";
export default {
    components: {
        StatisticsGraphCard,
        ProgramUserStayingItem,
    },
    props: {
        items: { type: Array, default: () => [] },
    },
};
</script>

<style lang="scss" scoped>
::v-deep {
    .echarts {
        width: 100%;
        height: 100%;
    }
}
</style>
