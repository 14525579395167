var render = function render(){
  var _vm$program, _vm$program$thumb, _vm$program2, _vm$program3, _vm$program4, _vm$program5, _vm$program6, _vm$program7, _vm$program8, _vm$program8$charge, _vm$program8$charge$f, _vm$program9, _vm$program10, _vm$program11, _vm$program11$thumb, _vm$program12;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-sheet', [_c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "lg": "9"
    }
  }, [_c('form-table-row', {
    staticClass: "d-flex d-lg-none",
    attrs: {
      "label": "썸네일"
    }
  }, [_c('v-img', {
    staticClass: "rounded-xl my-3",
    attrs: {
      "aspect-ratio": 2 / 3,
      "src": (_vm$program = _vm.program) === null || _vm$program === void 0 ? void 0 : (_vm$program$thumb = _vm$program.thumb) === null || _vm$program$thumb === void 0 ? void 0 : _vm$program$thumb.url
    },
    scopedSlots: _vm._u([{
      key: "placeholder",
      fn: function () {
        return [_c('v-overlay', {
          attrs: {
            "absolute": "",
            "z-index": "0"
          }
        }, [_c('v-icon', [_vm._v("mdi-image")])], 1)];
      },
      proxy: true
    }])
  })], 1), _c('form-table-row', {
    attrs: {
      "label": "키워드"
    }
  }, [_c('v-chip-group', {
    staticClass: "my-n1"
  }, _vm._l((_vm$program2 = _vm.program) === null || _vm$program2 === void 0 ? void 0 : _vm$program2.keywords, function (keyword, index) {
    return _c('v-chip', {
      key: index,
      attrs: {
        "label": ""
      }
    }, [_vm._v("#" + _vm._s(keyword.name))]);
  }), 1)], 1), _c('form-table-row', {
    attrs: {
      "label": "접수기간"
    }
  }, [_vm._v(" " + _vm._s((_vm$program3 = _vm.program) === null || _vm$program3 === void 0 ? void 0 : _vm$program3.applicationPeriod) + " ")]), _c('form-table-row', {
    attrs: {
      "label": "진행기간"
    }
  }, [_vm._v(" " + _vm._s((_vm$program4 = _vm.program) === null || _vm$program4 === void 0 ? void 0 : _vm$program4.period) + " ")]), _c('form-table-row', {
    attrs: {
      "label": "모집인원"
    }
  }, [_vm._v(" " + _vm._s((_vm$program5 = _vm.program) === null || _vm$program5 === void 0 ? void 0 : _vm$program5.capacity) + "명 ")]), _c('form-table-row', {
    attrs: {
      "label": "대상"
    }
  }, [_vm._v(" " + _vm._s((_vm$program6 = _vm.program) === null || _vm$program6 === void 0 ? void 0 : _vm$program6.target) + " ")]), _c('form-table-row', {
    attrs: {
      "label": "참가비"
    }
  }, [_vm._v(" " + _vm._s((_vm$program7 = _vm.program) !== null && _vm$program7 !== void 0 && _vm$program7.isFree ? "무료" : ((_vm$program8 = _vm.program) === null || _vm$program8 === void 0 ? void 0 : (_vm$program8$charge = _vm$program8.charge) === null || _vm$program8$charge === void 0 ? void 0 : (_vm$program8$charge$f = _vm$program8$charge.format) === null || _vm$program8$charge$f === void 0 ? void 0 : _vm$program8$charge$f.call(_vm$program8$charge)) + "원") + " ")]), _c('form-table-row', {
    attrs: {
      "label": "접수방법"
    }
  }, [_vm._v(" " + _vm._s((_vm$program9 = _vm.program) === null || _vm$program9 === void 0 ? void 0 : _vm$program9.applicationMethod) + " ")]), _c('form-table-row', {
    attrs: {
      "label": "담당자"
    }
  }, [_vm._v(" " + _vm._s((_vm$program10 = _vm.program) === null || _vm$program10 === void 0 ? void 0 : _vm$program10.worker) + " ")]), _c('form-table-row', {
    attrs: {
      "label": "회차목록",
      "contentClass": "px-0"
    }
  }, [_c('v-data-table', _vm._b({
    attrs: {
      "hide-default-footer": "",
      "disable-filtering": "",
      "disable-sort": "",
      "disable-pagination": "",
      "items-per-page": -1,
      "mobile-breakpoint": "800"
    },
    scopedSlots: _vm._u([_vm._l(_vm.headers.filter(function (header) {
      return header.hasOwnProperty('formatter');
    }), function (header) {
      return {
        key: `item.${header.value}`,
        fn: function (_ref) {
          var value = _ref.value;
          return [_vm._v(" " + _vm._s(header.formatter(value)) + " ")];
        }
      };
    })], null, true)
  }, 'v-data-table', {
    items: _vm.items,
    headers: _vm.headers
  }, false))], 1)], 1), _c('v-col', {
    staticClass: "d-none d-lg-block",
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-divider', {
    attrs: {
      "vertical": ""
    }
  })], 1), _c('v-col', {
    staticClass: "d-none d-lg-block"
  }, [_c('div', {
    staticClass: "pa-3 subtitle-2 font-weight-bold grey lighten-5"
  }, [_vm._v("썸네일")]), _c('v-divider'), _c('div', {
    staticClass: "pa-3"
  }, [_c('v-img', {
    staticClass: "rounded-xl",
    attrs: {
      "aspect-ratio": 2 / 3,
      "src": (_vm$program11 = _vm.program) === null || _vm$program11 === void 0 ? void 0 : (_vm$program11$thumb = _vm$program11.thumb) === null || _vm$program11$thumb === void 0 ? void 0 : _vm$program11$thumb.url
    },
    scopedSlots: _vm._u([{
      key: "placeholder",
      fn: function () {
        return [_c('v-overlay', {
          attrs: {
            "absolute": "",
            "z-index": "0"
          }
        }, [_c('v-icon', [_vm._v("mdi-image")])], 1)];
      },
      proxy: true
    }])
  })], 1)], 1)], 1), _c('v-divider'), _c('div', {
    staticClass: "pa-3 subtitle-2 font-weight-bold grey lighten-5"
  }, [_vm._v("상세 설명")]), _c('v-divider'), _c('v-card-text', {
    domProps: {
      "innerHTML": _vm._s((_vm$program12 = _vm.program) === null || _vm$program12 === void 0 ? void 0 : _vm$program12.detail)
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }