var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-dialog', {
    attrs: {
      "max-width": "640"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var attrs = _ref.attrs,
          on = _ref.on;
        return [_vm._t("activator", null, null, {
          attrs,
          on
        })];
      }
    }], null, true),
    model: {
      value: _vm.shows,
      callback: function ($$v) {
        _vm.shows = $$v;
      },
      expression: "shows"
    }
  }, [_c('v-card', [_c('v-card-title', [_vm._v(" " + _vm._s(_vm.title) + " "), _c('v-spacer'), _vm._v(" "), _c('v-btn', {
    attrs: {
      "text": "",
      "icon": "",
      "tile": ""
    },
    on: {
      "click": function ($event) {
        _vm.shows = false;
      }
    }
  }, [_c('v-icon', [_vm._v("mdi-close")])], 1)], 1), _c('v-divider'), _c('form-table-row', {
    attrs: {
      "label": "프로그램"
    }
  }, [_c('autocomplete-program', _vm._b({
    attrs: {
      "outlined": false,
      "disabled": !!_vm._program
    },
    model: {
      value: _vm.application._program,
      callback: function ($$v) {
        _vm.$set(_vm.application, "_program", $$v);
      },
      expression: "application._program"
    }
  }, 'autocomplete-program', _vm.inputAttrs, false))], 1), _c('form-table-row', {
    attrs: {
      "label": "신청자"
    }
  }, [_c('autocomplete-user', _vm._b({
    attrs: {
      "outlined": false,
      "disabled": !_vm.isCreate
    },
    model: {
      value: _vm.application._user,
      callback: function ($$v) {
        _vm.$set(_vm.application, "_user", $$v);
      },
      expression: "application._user"
    }
  }, 'autocomplete-user', _vm.inputAttrs, false))], 1), _c('form-table-row', {
    attrs: {
      "label": "상태"
    }
  }, [_c('v-select', _vm._b({
    attrs: {
      "items": _vm.stateItems,
      "outlined": false
    },
    model: {
      value: _vm.application.state,
      callback: function ($$v) {
        _vm.$set(_vm.application, "state", $$v);
      },
      expression: "application.state"
    }
  }, 'v-select', _vm.inputAttrs, false))], 1), _c('v-card-actions', [_c('v-btn', {
    attrs: {
      "outlined": ""
    },
    on: {
      "click": function ($event) {
        _vm.shows = false;
      }
    }
  }, [_vm._v("취소하기")]), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "color": "primary"
    },
    on: {
      "click": _vm.save
    }
  }, [_vm._v("저장하기")])], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }