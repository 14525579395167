<template>
    <v-data-table v-bind="{ items, headers }" hide-default-footer disable-filtering disable-sort disable-pagination :items-per-page="-1">
        <template v-for="header in headers.filter((header) => header.hasOwnProperty('formatter'))" #[`item.${header.value}`]="{ value }"> {{ header.formatter(value) }} </template>

        <template #[`item.attendances`]="{ value }"> {{ value.length }} / {{ users.length }} </template>

        <template #[`item.actions`]="{ item }">
            <program-unit-attendances v-bind="{ ...item, users }" v-on="{ postAttendance, removeAttendance }">
                <template #activator="{ attrs, on }">
                    <v-btn text icon tile v-bind="attrs" v-on="on"> <v-icon>mdi-pencil</v-icon> </v-btn>
                </template>
            </program-unit-attendances>
            <program-unit-qrscan v-bind="{ ...item, program, users }" v-on="{ postAttendance }">
                <template #activator="{ attrs, on }">
                    <v-btn text icon tile v-bind="attrs" v-on="on"> <v-icon>mdi-qrcode-scan</v-icon> </v-btn>
                </template>
            </program-unit-qrscan>
        </template>
    </v-data-table>
</template>

<script>
import api from "@/api";

import ProgramUnitQrscan from "./program-unit-qrscan.vue";
import ProgramUnitAttendances from "./program-unit-attendances.vue";

let days = ["일", "월", "화", "수", "목", "금", "토"];
let headers = [
    { width: +90, text: "회차", value: "number", formatter: (value) => `${value}회차`, align: "right" },
    { width: 140, text: "날짜", value: "unit.date", formatter: (value) => value.toDate(), align: "center" },
    { width: 140, text: "요일", value: "unit.day", align: "center", formatter: (value) => days[value] },
    { width: 140, text: "시간", value: "unit.time", align: "center" },
    { width: 140, text: "출석률", value: "attendances", align: "center" },
    { width: 110, text: "", value: "actions", align: "right" },
];

export default {
    components: {
        ProgramUnitQrscan,
        ProgramUnitAttendances,
    },
    props: {
        program: { type: Object, default: () => ({}) },
    },
    data: () => ({
        users: [],
        attendances: [],

        headers,
    }),
    computed: {
        items() {
            return this.units.map((unit, index) => ({ unit, attendances: this.attendances.filter(({ _unit }) => _unit == unit?._id) || [], number: index + 1 }));
        },
        units() {
            return this.program?.units || [];
        },
    },
    mounted() {
        this.init();
    },
    watch: {
        "program._attendees"() {
            this.init();
        },
    },
    methods: {
        async init() {
            let { _attendees: _user, _id: _program } = this.program || {};
            if (_user) {
                let { users } = await api.console.users.gets({ params: { _user } });
                this.users = users;
            }
            if (_program) {
                let { attendances } = await api.console.programs.attendances.gets({ _program });
                this.attendances = attendances;
            }
        },
        async postAttendance(item) {
            console.log({ item });
            this.attendances = [(await api.console.programs.attendances.post(item))?.attendance, ...this.attendances];
        },
        async removeAttendance({ _id, _program }) {
            await api.console.programs.attendances.delete({ _id, _program });
            let index = this.attendances.findIndex((item) => item._id == _id);
            this.attendances.splice(index, 1);
        },
    },
};
</script>
