<template>
    <v-sheet>
        <span v-if="!space" class="caption"> 회원 / 공간을 설정해주세요. </span>
        <span v-else-if="item.isOnHoliday"> 운영하지 않는 날짜입니다 </span>
        <span v-else-if="!item.isOnBusiness"> 운영하지 않는 요일입니다 </span>
        <v-row v-else no-gutters class="py-2">
            <v-col lg="4" v-for="time in businessHours" :key="time">
                <rental-schedule-checkbox v-bind="{ loading, schedules, item, date, time, _user, schedulesOfUser, limitationMet }" v-on="{ add, del: (schedule) => $emit('del', schedule) }" />
            </v-col>
        </v-row>
    </v-sheet>
</template>

<script>
import dayjs from "dayjs";
import RentalScheduleCheckbox from "../console/rental/rental-schedule-checkbox.vue";
const businessHours = [10, 11, 12, 13, 14, 15, 16, 17, 18];

export default {
    components: {
        RentalScheduleCheckbox,
    },
    props: {
        loading: { type: Boolean, default: false },

        value: { type: Array, default: () => [] },

        date: { type: String, default: Date.now().toDate() },
        space: { type: Object, default: null },
        _user: { type: String, default: null },

        schedulesOfUser: { type: Array, default: () => [] },
        schedulesOnCloud: { type: Array, default: () => [] },

        limitationMet: { type: Boolean, default: false },
        limitationMetOnBranches: { type: Array, default: () => [] },
    },
    data: () => ({
        schedules: [],
        businessHours,
    }),
    computed: {
        dayOfDate() {
            return dayjs(this.date).day();
        },
        item() {
            return {
                ...(this.space || {}),
                schedulesOnCloud: this.schedulesOnCloud.filter(({ _space }) => _space == this.space?._id),
                limitationMetOnBranch: this.limitationMetOnBranches.find(({ branch }) => branch == this.space.branch)?.limitationMet || false,
                isOnBusiness: (this.space?.businessDays || []).includes(this.dayOfDate),
                isOnHoliday: (this.space?.holidays || []).some((date) => dayjs(this.date).isSame(date, "date")),
            };
        },
    },
    mounted() {
        this.sync();
    },
    watch: {
        value() {
            this.sync();
        },
        schedules() {
            this.$emit("input", this.schedules);
        },
    },
    methods: {
        sync() {
            this.schedules = this.value;
        },
        add(schedule) {
            this.schedules = this.schedules.concat([schedule]);
        },
    },
};
</script>
