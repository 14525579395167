<template>
    <v-dialog v-model="shows" max-width="640">
        <template #activator="{ attrs, on }">
            <slot name="activator" v-bind="{ attrs, on }" />
        </template>
        <v-card>
            <v-card-title>
                {{ title }} <v-spacer /> <v-btn text icon tile @click="shows = false"><v-icon>mdi-close</v-icon></v-btn>
            </v-card-title>
            <v-divider />
            <form-table-row label="프로그램">
                <autocomplete-program v-model="application._program" v-bind="inputAttrs" :outlined="false" :disabled="!!_program" />
            </form-table-row>
            <form-table-row label="신청자">
                <autocomplete-user v-model="application._user" v-bind="inputAttrs" :outlined="false" :disabled="!isCreate" />
            </form-table-row>
            <form-table-row label="상태">
                <v-select v-model="application.state" :items="stateItems" :outlined="false" v-bind="inputAttrs" />
            </form-table-row>
            <v-card-actions>
                <v-btn outlined @click="shows = false">취소하기</v-btn>
                <v-spacer />
                <v-btn color="primary" @click="save">저장하기</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import api from "@/api";
import { inputAttrs, PROGRAM_APPLICATION_STATES } from "@/assets/variables";

import FormTableRow from "../dumb/form-table-row.vue";
import AutocompleteUser from "../../dumb/autocomplete-user.vue";
import AutocompleteProgram from "../../dumb/autocomplete-program.vue";

let initApplication = ({ _program = null } = {}) => ({
    _user: null,
    _program,
    state: PROGRAM_APPLICATION_STATES.APPLIED.value,
});

export default {
    components: {
        FormTableRow,
        AutocompleteUser,
        AutocompleteProgram,
    },
    props: {
        value: { type: Object, default: () => ({}) },
    },
    data: () => ({
        shows: false,

        program: null,
        application: initApplication(),

        inputAttrs,
        stateItems: Object.values(PROGRAM_APPLICATION_STATES),
    }),
    computed: {
        isCreate() {
            return !this.value?._id;
        },
        _program() {
            return this.value?._program || this.$route.params?._program;
        },
        title() {
            return this.isCreate ? "신청서 생성" : "신청서 상세";
        },
        isApprovedLabel() {
            switch (this.application.isApproved) {
                case true:
                    return "승인";
                case false:
                    return "반려";
                default:
                    return "미처리";
            }
        },
    },
    mounted() {
        this.init();
    },
    watch: {
        shows() {
            if (this.shows) this.init();
        },
    },
    methods: {
        async init() {
            let { _program } = this;
            if (this.isCreate) {
                this.application = initApplication({ _program });
            } else {
                let { _id } = this.value;
                this.application = (await api.console.programs.applications.get({ _program, _id }))?.application;
            }
        },
        async save() {
            let { post, put } = api.console.programs.applications;
            let { application } = await (this.isCreate ? post : put)(this.application);
            alert("저장되었습니다");
            this.shows = false;
            this.$emit("input", application);
        },
    },
};
</script>
